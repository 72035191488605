
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import { DrawerType } from '@/models';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import Vue from 'vue';

export default Vue.extend({
    name: 'ClinicalSummaryButton',
    components: {
        SideBarButton,
    },
    props: {
        expanded: Boolean,
    },
    data: () => ({
        visitDrawerStore: useVisitDrawerStore(),
    }),
    methods: {
        openDrawer() {
            this.visitDrawerStore.openDrawer({ drawer: DrawerType.CLINICAL_SUMMARY, drawerProps: { dark: true } });
        },
    },
});
