
import Loadable from '@/components/shared/Loadable.vue';
import { getAdmitStatusConfidenceColor, getRoundedPercentage } from '@/components/shared/utils';
import { getCurrentAdmitStatusPrediction } from '@/shared/queries';
import { AdmitStatusPrediction, Maybe, Query } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'ConfidenceLevelsCard',
    components: {
        Loadable,
    },
    props: {
        visitId: { type: String, required: true },
        admitStatusPredictions: { type: Object as PropType<Maybe<AdmitStatusPrediction>>, required: false },
        isIpo: { type: Boolean, required: false, default: false },
    },
    data: () => ({
        loading: true,
        currentAdmitStatusPredictions: undefined as Maybe<AdmitStatusPrediction> | undefined,
    }),
    computed: {
        admitStatusConfidenceLevels(): Record<string, number> {
            const predictions = this.admitStatusPredictions ?? this.currentAdmitStatusPredictions;
            if (predictions) {
                predictions.inpatientConfidence = predictions.inpatientConfidence ?? 0;
                predictions.observationConfidence = predictions.observationConfidence ?? 0;
                predictions.admitConfidence = predictions.admitConfidence ?? 0;
                if (predictions.admitConfidence > 0) {
                    return { admit: predictions.admitConfidence };
                }
                if (predictions.inpatientConfidence > predictions.observationConfidence) {
                    return { inpatient: predictions.inpatientConfidence };
                }
                return { observation: predictions.observationConfidence };
            }
            return {};
        },
    },
    created() {
        if (!this.admitStatusPredictions && !this.currentAdmitStatusPredictions) {
            this.getPredictions();
        } else {
            this.loading = false;
        }
    },
    methods: {
        getAdmitStatusConfidenceColor,
        getRoundedPercentage,
        async getPredictions() {
            const response = await this.$apollo.query<Query>({
                query: getCurrentAdmitStatusPrediction,
                variables: {
                    id: +this.visitId,
                },
                fetchPolicy: 'no-cache',
            });
            this.currentAdmitStatusPredictions = response.data.visit?.currentAdmitStatusPrediction;
            this.$emit('currentAdmitStatusPredictions', this.currentAdmitStatusPredictions);
            this.loading = false;
        },
    },
});
