
import LocationScopingDialog from '@/components/admin/scoping/LocationScopingDialog.vue';
import { coerceToType, propertyExists } from '@/components/shared/utils';
import { Scopable, ScopingItem } from '@/models';
import { Facility } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';
export default defineComponent({
    name: 'ScopingTable',
    components: {
        LocationScopingDialog,
    },
    props: {
        itemType: { type: String as PropType<ScopingItem>, required: true },
        items: { type: Array<Scopable>, required: true },
    },
    data: () => ({ resetKeys: {} as Record<string, number> }),
    created() {
        this.resetKeys = this.items.reduce((acc, curr) => {
            acc[curr.code] = 0;
            return acc;
        }, {} as Record<string, number>);
    },
    methods: {
        propertyExists,
        coerceToType,
        updateLocations(index: number, inScope: boolean[]) {
            // TODO add in division
            const facility = {
                ...this.items[index],
            } as Facility;
            facility.locations = facility.locations?.map((loc, inScopeIndex) => ({ ...loc, inScope: inScope[inScopeIndex] }));
            this.items[index] = facility;
            this.resetKeys[facility.code] += 1;
        },
    },
});
