
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import Vue from 'vue';

export default Vue.extend({
    name: 'SaveClinicalDialog',
    data: () => ({
        isDialogOpen: false,
        readyToSend: true,
        visitDrawerStore: useVisitDrawerStore(),
    }),
    methods: {
        close(): void {
            this.isDialogOpen = false;
            this.$emit('close', false);
        },
        saveClinical(): void {
            this.isDialogOpen = false;
            this.$emit('save', this.readyToSend);
            this.visitDrawerStore.closeDrawer();
        },
    },
});
