
import { ClinicalDocumentType, ClinicalDocumentTypeDisplay } from '@/models/clinical-document/ClinicalDocument.model';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'DeleteDialog',
    props: {
        documentType: { type: String as PropType<ClinicalDocumentType>, required: true },
    },
    data: () => ({
        isDialogOpen: false,
        ClinicalDocumentTypeDisplay,
    }),
    methods: {
        cancel(): void {
            this.isDialogOpen = false;
        },
        confirmDelete(): void {
            this.isDialogOpen = false;
            this.$emit('delete', true);
        },
    },
});
