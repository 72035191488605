
import { formatDateTime } from '@/components/shared/utils';
import DocumentDetailViewBody from '@/components/visit/documentation-and-services/DocumentDetailViewBody.vue';
import VisitTimelineDetailView from '@/components/visit/timeline/VisitTimelineDetailView.vue';
import { ClinicalDocumentItemType, VisitTimelineEventDetails } from '@/models';
import { getTimelineDocument } from '@/shared/queries';
import { Maybe, Query } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'VisitTimelineDocumentDetailView',
    components: {
        VisitTimelineDetailView,
        DocumentDetailViewBody,
    },
    props: {
        selectedEvent: {
            type: Object as PropType<VisitTimelineEventDetails>,
            required: true,
        },
        searchText: String,
    },
    data: () => ({
        ClinicalSummaryItemType: ClinicalDocumentItemType,
        body: undefined as Maybe<string> | undefined,
        isLoading: false,
    }),
    computed: {
        header(): string {
            return this.selectedEvent.header;
        },
        subheader(): string {
            return `${this.selectedEvent.subHeader ? `by ${this.selectedEvent.subHeader} ` : ''}at ${formatDateTime(
                this.selectedEvent.timestamp
            )}`;
        },
    },
    watch: {
        $props: {
            immediate: true,
            deep: true,
            handler() {
                this.getDocument(this.selectedEvent);
            },
        },
    },
    methods: {
        getDocument(event: VisitTimelineEventDetails): Promise<void> {
            this.isLoading = true;
            return this.$apollo
                .query<Query>({
                    query: getTimelineDocument,
                    variables: {
                        id: parseInt(event.id ?? ''),
                        visitId: parseInt(this.$route.params.id),
                        searchText: this.searchText,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    this.body = response.data.document?.body;
                    this.isLoading = false;
                });
        },
    },
});
