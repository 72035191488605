import { DescribedItem, DescribedItemType } from '@/models';
import { VisitSummaryItem } from 'generated/graphql/graphql';
import { NULLABLE } from '../types';
import {
    checkCastDescribedItem,
    checkCastDocumentItem,
    checkCastLabResultItem,
    checkCastMedicationOrderItem,
    checkCastMicrobiologyItem,
} from './VisitSummaryItemDescription';

export const validVisitSummaryItemDescriptionJson = (vsi: VisitSummaryItem): boolean => {
    // if description is falsey it's invalid
    if (!vsi.descriptionJson) return false;
    let checkCast: (item: any) => NULLABLE<DescribedItem>;
    let hasItems = false;
    for (let i = 0; i < vsi.descriptionJson.items?.length; i++) {
        hasItems = true;
        const item = vsi.descriptionJson.items[i];
        switch (item.type) {
            case DescribedItemType.DOCUMENT:
                checkCast = checkCastDocumentItem;
                break;
            case DescribedItemType.LAB_RESULT:
                checkCast = checkCastLabResultItem;
                break;
            case DescribedItemType.MEDICATION_ORDER:
                checkCast = checkCastMedicationOrderItem;
                break;
            case DescribedItemType.MICROBIOLOGY:
                checkCast = checkCastMicrobiologyItem;
                break;
            default:
                checkCast = checkCastDescribedItem;
        }
        // if any item is invalid, return false
        if (!checkCast(item)) return false;
    }
    return hasItems;
};
