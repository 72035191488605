
import AdditiveSelector from '@/components/shared/AdditiveSelector.vue';
import ExpandableSection from '@/components/shared/ExpandableSection.vue';
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import { phoneNumberValidator, uniqueCurriedValidator, withDefault } from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import ClinicalSummaryDocumentationTable from '@/components/visit/clinical-summary/ClinicalSummaryDocumentationTable.vue';
import ClinicalSummaryMicrobiologyTable from '@/components/visit/clinical-summary/ClinicalSummaryMicrobiologyTable.vue';
import DeleteClinicalSummaryDialog from '@/components/visit/clinical-summary/DeleteClinicalSummaryDialog.vue';
import DrawerHeader from '@/components/visit/drawer/DrawerHeader.vue';
import VisitInfoBar from '@/components/visit/info-bar/VisitInfoBar.vue';
import LabsTable from '@/components/visit/labs/LabsTable.vue';
import MedicationAdministrationsTable from '@/components/visit/medications/MedicationAdministrationsTable.vue';
import MedicationOrdersTable from '@/components/visit/medications/MedicationOrdersTable.vue';
import { ClinicalDocumentItemType, ClinicalDocumentType, DrawerType, VisitInfoBarField } from '@/models';

import { assignFaxNumber, saveClinicalDocumentNote } from '@/shared/mutations';
import {
    getClinicalDocumentDocuments,
    getClinicalDocumentDraft,
    getClinicalDocumentLabVitalsResults,
    getClinicalDocumentMedicationAdministrations,
    getClinicalDocumentMedicationOrders,
    getClinicalDocumentMicrobiologyResults,
} from '@/shared/queries';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import {
    ClinicalDocument,
    DocumentUnion,
    FaxNumber,
    LabVitalsResultsTable,
    MedicationAdministration,
    MedicationOrder,
    MicrobiologyResult,
    Mutation,
    Query,
    Visit,
} from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ClinicalSummaryBuilderView',
    components: {
        FeatureToggle,
        ExpandableSection,
        LabsTable,
        MedicationOrdersTable,
        MedicationAdministrationsTable,
        ClinicalSummaryDocumentationTable,
        VisitInfoBar,
        DrawerHeader,
        ClinicalSummaryMicrobiologyTable,
        DeleteClinicalSummaryDialog,
        VerticalField,
        AdditiveSelector,
    },
    provide: {
        showAddToClinicalSummaryButton: false,
        showDeleteFromClinicalSummaryButton: true,
        enableLabSelection: false,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            default: () => ({} as Visit),
            required: true,
        },
    },
    data: () => ({
        DrawerType,
        ClinicalSummaryItemType: ClinicalDocumentItemType,
        clinicalSummary: {} as ClinicalDocument,
        clinicalSummaryMicrobiologyResults: undefined as MicrobiologyResult[] | undefined,
        clinicalSummaryMedicationOrders: undefined as MedicationOrder[] | undefined,
        clinicalSummaryMedicationAdministrations: undefined as MedicationAdministration[] | undefined,
        clinicalSummaryLabVitalsResults: undefined as LabVitalsResultsTable | undefined,
        clinicalSummaryDocuments: undefined as DocumentUnion[] | undefined,
        faxNumbers: [] as FaxNumber[],
        selectedFaxNumber: {} as FaxNumber | undefined,
        visitDrawerStore: useVisitDrawerStore(),
        visitStore: useVisitStore(),
        visitInfoBarFields: new Set<VisitInfoBarField>([
            VisitInfoBarField.PATIENT_INFO,
            VisitInfoBarField.ADMIT_STATUS,
            VisitInfoBarField.ADMIT_DATE,
            VisitInfoBarField.ADMIT_TIME,
            VisitInfoBarField.FACILITY,
            VisitInfoBarField.LOCATION,
            VisitInfoBarField.LOS,
            VisitInfoBarField.INLINE_DOB,
        ]),
        supportedTypesToDelete: new Set([
            ClinicalDocumentItemType.MEDICATION_ADMINISTRATION,
            ClinicalDocumentItemType.MEDICATION_ORDER,
            ClinicalDocumentItemType.DOCUMENT,
            ClinicalDocumentItemType.DOCUMENT_QUOTE,
            ClinicalDocumentItemType.LAB_RESULT,
            ClinicalDocumentItemType.MICROBIOLOGY,
        ]),
        pendingMutations: [] as Promise<void>[],
    }),
    computed: {
        clinicalSummaryLabVitalsResultsExist(): boolean {
            if (this.clinicalSummaryLabVitalsResults?.columns) {
                return this.clinicalSummaryLabVitalsResults.columns.length > 0;
            }
            return false;
        },
        faxNumbersDisplay(): { value: FaxNumber; text: string }[] {
            return this.faxNumbers.map((fn) => ({ value: fn, text: fn.faxNumber }));
        },
        noFaxNumbers(): boolean {
            return this.faxNumbers.length === 0;
        },
    },
    async created() {
        await this.getClinicalSummaryDraft();
        await Promise.allSettled([
            this.getFaxNumbers(),
            this.getMicrobiologyResults(),
            this.getMedicationOrders(),
            this.getMedicationAdministrations(),
            this.getLabVitalsResults(),
            this.getDocuments(),
        ]);
    },
    methods: {
        withDefault,
        phoneNumberValidator,
        uniqueCurriedValidator,
        handlePendingMutation(mutation: Promise<any>) {
            this.pendingMutations.push(mutation);
        },
        async removeItem(type: ClinicalDocumentItemType, id: string) {
            if (this.supportedTypesToDelete.has(type)) {
                switch (type) {
                    case ClinicalDocumentItemType.MEDICATION_ORDER:
                        this.clinicalSummaryMedicationOrders = this.clinicalSummaryMedicationOrders?.filter((item) => item.id != id);
                        break;
                    case ClinicalDocumentItemType.MEDICATION_ADMINISTRATION:
                        this.clinicalSummaryMedicationAdministrations = this.clinicalSummaryMedicationAdministrations?.filter(
                            (item) => item.id != id
                        );
                        break;
                    case ClinicalDocumentItemType.MICROBIOLOGY:
                        this.clinicalSummaryMicrobiologyResults = this.clinicalSummaryMicrobiologyResults?.filter((item) => item.id != id);
                        break;
                }
            }
        },
        removeLabResultItem(type: ClinicalDocumentItemType, id: string) {
            let colIndex = -1;
            let rowIndex = -1;
            if (this.clinicalSummaryLabVitalsResults?.rows) {
                for (let i = 0; i < this.clinicalSummaryLabVitalsResults?.rows.length; i++) {
                    let currentRow = this.clinicalSummaryLabVitalsResults.rows[i];
                    let currentColIndex = currentRow.results.findIndex((result) => result?.id === id);
                    if (currentColIndex !== -1) {
                        colIndex = currentColIndex;
                        rowIndex = i;
                        break;
                    }
                }
            }

            let resultExistsInCol = false;
            let resultExistsInRow = false;
            //check if other lab result exists in column
            if (colIndex !== -1 && this.clinicalSummaryLabVitalsResults?.rows) {
                for (let i = 0; i < this.clinicalSummaryLabVitalsResults?.rows.length; i++) {
                    let currentResult = this.clinicalSummaryLabVitalsResults.rows.at(i)?.results.at(colIndex);
                    if (currentResult && currentResult.id !== id) {
                        resultExistsInCol = true;
                        break;
                    }
                }
            }
            //check if other lab result exists in row
            if (rowIndex !== -1 && this.clinicalSummaryLabVitalsResults?.rows) {
                resultExistsInRow =
                    this.clinicalSummaryLabVitalsResults?.rows[rowIndex].results.filter((result) => result !== null).length > 1;
            }

            //if no other results in column
            if (!resultExistsInCol && this.clinicalSummaryLabVitalsResults?.columns) {
                this.clinicalSummaryLabVitalsResults.rows.forEach((row) => {
                    //delete column from rows
                    this.$delete(row.results, colIndex);
                });
                this.$delete(this.clinicalSummaryLabVitalsResults.columns, colIndex); //delete column from columns
                //if no other results in row as well
                if (!resultExistsInRow && this.clinicalSummaryLabVitalsResults?.rows) {
                    //delete whole row
                    this.$delete(this.clinicalSummaryLabVitalsResults.rows, rowIndex);
                }
                return;
            }

            //if other results are in column and no results in row
            if (resultExistsInCol && !resultExistsInRow && this.clinicalSummaryLabVitalsResults?.rows) {
                //delete whole row
                this.$delete(this.clinicalSummaryLabVitalsResults.rows, rowIndex);
                return;
            }

            //if other results exist in row and in column
            if (resultExistsInCol && resultExistsInRow && this.clinicalSummaryLabVitalsResults?.rows) {
                // set that entry to null, do not delete
                this.$set(this.clinicalSummaryLabVitalsResults.rows[rowIndex].results, colIndex, null);
                return;
            }
        },
        removeDocumentUnionItem(type: ClinicalDocumentItemType, id: string, quote: string) {
            if (this.clinicalSummaryDocuments) {
                const removeIndex = this.clinicalSummaryDocuments.findIndex(function (item) {
                    if (
                        item.__typename === 'Document' &&
                        type === ClinicalDocumentItemType.DOCUMENT &&
                        item.id === id &&
                        quote === undefined
                    ) {
                        return item;
                    } else if (
                        item.__typename === 'DocumentQuote' &&
                        type === ClinicalDocumentItemType.DOCUMENT_QUOTE &&
                        item.documentId.toString() === id &&
                        item.quote === quote
                    ) {
                        return item;
                    }
                });
                if (removeIndex !== -1) {
                    this.$delete(this.clinicalSummaryDocuments, removeIndex);
                }
            }
        },
        async saveNote(event: Event) {
            const target = event.target as HTMLInputElement | null;
            if (this.clinicalSummary.note === target?.value) {
                return;
            } // early exit if note is unchanged
            this.clinicalSummary.note = target?.value ?? this.clinicalSummary.note;
            const mutation = this.$apollo.mutate<Mutation>({
                mutation: saveClinicalDocumentNote,
                variables: {
                    input: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: parseInt(this.clinicalSummary.id),
                        note: this.clinicalSummary.note,
                    },
                },
            });
            this.handlePendingMutation(mutation);
        },
        async addFaxNumber(faxNumberString: string) {
            const mutation = this.$apollo.mutate<Mutation>({
                mutation: assignFaxNumber,
                variables: {
                    input: {
                        division: this.visit.division,
                        faxNumbers: [faxNumberString],
                        payerCodes: [this.visit.primaryPayer?.code],
                    },
                },
            });
            this.handlePendingMutation(mutation);
            const faxNumber = (await mutation).data?.assignFaxNumber?.at(0);
            if (faxNumber === undefined) throw new Error('Unable to add fax number');
            this.faxNumbers.push(faxNumber);
            this.selectedFaxNumber = this.faxNumbers.at(-1);
        },
        async completeSummary(): Promise<void> {
            await Promise.allSettled(this.pendingMutations);
            this.visitDrawerStore.openDrawer({
                drawer: DrawerType.CLINICAL_DOCUMENT_CONFIRMATION,
                params: {
                    faxNumber: this.selectedFaxNumber,
                    payerName: withDefault(this.visit.primaryPayer && this.visit.primaryPayer.name),
                    visitId: this.visit.id,
                    clinicalDocumentId: this.clinicalSummary.id,
                },
                drawerProps: { dark: true },
                breadcrumbs: [
                    {
                        drawer: DrawerType.CLINICAL_SUMMARY,
                        params: this.visitDrawerStore.params,
                        drawerProps: { dark: true },
                    },
                ],
            });
        },
        async getClinicalSummaryDraft() {
            if (!this.visitStore.clinicalSummary) {
                const response = await this.$apollo.query<Query>({
                    query: getClinicalDocumentDraft,
                    variables: {
                        filter: {
                            visitId: parseInt(this.visit.id),
                            startDate: this.visitStore.startDate,
                            endDate: this.visitStore.endDate,
                            type: ClinicalDocumentType.CLINICAL_SUMMARY,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                this.visitStore.clinicalSummary = response.data.clinicalDocumentDraft;
            }
            this.clinicalSummary = this.visitStore.clinicalSummary;
        },
        getFaxNumbers(): void {
            this.faxNumbers = this.visit.primaryPayer?.faxNumbers ?? [];
            this.selectedFaxNumber =
                this.visitDrawerStore.params?.faxNumber ?? this.faxNumbers.find((fn) => fn.order === 1) ?? this.faxNumbers[0];
        },
        async getMicrobiologyResults() {
            const response = await this.$apollo.query<Query>({
                query: getClinicalDocumentMicrobiologyResults,
                variables: {
                    filter: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: parseInt(this.clinicalSummary.id),
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.clinicalSummaryMicrobiologyResults = response.data.clinicalDocumentMicrobiologyResults;
        },
        async getMedicationOrders() {
            const response = await this.$apollo.query<Query>({
                query: getClinicalDocumentMedicationOrders,
                variables: {
                    filter: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: parseInt(this.clinicalSummary.id),
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.clinicalSummaryMedicationOrders = response.data.clinicalDocumentMedicationOrders.items;
        },
        async getMedicationAdministrations() {
            const response = await this.$apollo.query<Query>({
                query: getClinicalDocumentMedicationAdministrations,
                variables: {
                    filter: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: parseInt(this.clinicalSummary.id),
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.clinicalSummaryMedicationAdministrations = response.data.clinicalDocumentMedicationAdministrations.items;
        },
        async getLabVitalsResults() {
            const response = await this.$apollo.query<Query>({
                query: getClinicalDocumentLabVitalsResults,
                variables: {
                    filter: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: parseInt(this.clinicalSummary.id),
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.clinicalSummaryLabVitalsResults = response.data.clinicalDocumentLabVitalsResultsTable;
        },
        async getDocuments() {
            const response = await this.$apollo.query<Query>({
                query: getClinicalDocumentDocuments,
                variables: {
                    filter: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: parseInt(this.clinicalSummary.id),
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.clinicalSummaryDocuments = response.data.clinicalDocumentDocuments;
        },
    },
});
