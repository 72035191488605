
import _ from 'underscore';
import Vue from 'vue';

export default Vue.extend({
    name: 'MultiSelectFilter',
    props: {
        name: String,
        value: Array,
        items: { type: Array<Record<string, any>> },
        itemText: [String, Array, Function],
        itemValue: [String, Array, Function],
        groupBy: { type: String, required: false, default: '' },
    },
    data: () => ({
        searchInput: null,
        displayItems: [] as Array<Record<string, any>>,
    }),
    computed: {
        selectedItems: {
            get(): Array<unknown> {
                return this.value;
            },
            set(value: Array<unknown>) {
                this.$emit('input', value);
            },
        },
    },
    watch: {
        items() {
            this.displayItems = this.getDisplayItems();
            this.reAddressSelectedItems();
        },
    },
    created() {
        this.displayItems = this.getDisplayItems();
        this.reAddressSelectedItems();
    },
    methods: {
        getDisplayItems() {
            if (this.groupBy) {
                if (typeof this.itemValue !== 'string') {
                    throw new Error('groupBy property cannot be used when itemValue is not a string');
                }
                // @ts-expect-error TS is having trouble recognizing we're already checking that this is a string
                const valueOf = (item: any) => item[this.itemValue];
                return (
                    _.chain(this.items)
                        .groupBy(this.groupBy)
                        // change the value of the grouped items to be the unique list of values across items in the group
                        .map((itemGroup) => {
                            const valueMap = _.chain(itemGroup).uniq(valueOf).map(valueOf).value();
                            return {
                                ...itemGroup[0],
                                // @ts-expect-error TS is having trouble recognizing we're already checking that this is a string
                                [this.itemValue]: valueMap,
                            };
                        })
                        .value()
                );
            }
            return this.items;
        },
        reAddressSelectedItems() {
            /**
             * This handles a bug wherein the filters are not visible when the user refreshes the page / logs back in, which is caused by the
             * v-autocomplete accepting an array as a value but doing a comparison of the array (memory address) rather than it's contents
             *
             * This is setting the selected items to be the same array(s) as used by the display items, allowing v-autocomplete to recognize them
             */
            if (this.groupBy && this.selectedItems?.length && typeof this.itemValue === 'string') {
                try {
                    this.selectedItems = this.selectedItems.map((selectedItem) => {
                        for (let i = 0; i < this.displayItems?.length; i++) {
                            const targetItem = this.displayItems[i];
                            // @ts-expect-error TS is having trouble recognizing we're already checking that this is a string
                            if (targetItem[this.itemValue].includes(selectedItem)) return targetItem[this.itemValue];
                        }
                        throw new Error('not found!');
                    });
                } catch (err) {
                    console.warn('unable to re-address existing selected items, potentially waiting on display items to load');
                    console.warn(err);
                }
            }
        },
    },
});
