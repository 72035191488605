import { NULLABLE } from './types';

export const getRoundedPercentage = (value: NULLABLE<number>): number => {
    const numberValue = Number(value);
    if (isNaN(numberValue)) return 0;
    return Math.round(numberValue * 100);
};

export const getFlooredPercentage = (value: NULLABLE<number>): number => {
    const numberValue = Number(value);
    if (isNaN(numberValue)) return 0;
    return Math.floor(numberValue * 100);
};
