import _ from 'underscore';

import { NULLABLE } from './types';

export const optionalChain = <U>(obj: NULLABLE<U>, property: keyof U): NULLABLE<U[keyof U]> => {
    return obj?.[property];
};

export const nullishCoalesce = <U, V = U>(nullable: NULLABLE<U>, coalescee: V) => nullable ?? coalescee;

export const coerceToId = (obj: { id: number }) => Number(obj.id);

export const convertToCompoundDivisionCode = ({ division, code }: { division: string; code: string }) => `${division}:${code}`;

export const convertFromCompoundDivisionCode = (compound: string): { division: string; code: string } => {
    const [division, code] = compound.split(':');
    return { division, code };
};

export const groupMapOnCompoundDivisionCode = <T>(compoundCodes: string[], callback: (division: string, codes: string[]) => T) => {
    return _.chain(compoundCodes)
        .map(convertFromCompoundDivisionCode)
        .groupBy('division')
        .map((compoundCodeObjectArray, division) => {
            const codes = compoundCodeObjectArray.map(({ code }) => code);
            return callback(division, codes);
        })
        .value();
};

export const propertyExists = <U extends Record<string, unknown>>(obj: U, property: string) => {
    return Object.keys(obj).includes(property);
};

export const coerceToType = <U, V>(obj: U): V => {
    return obj as unknown as V;
};

/**
 *
 * @param searchText - The text that item properties will be compared against
 * @param properties - The properties that will be used to compare against the search text
 * @returns a function of the form (item: U) => Boolean which can be used as the callback of the filter method of an array U[]
 */
export const filterOnProperties =
    <U extends Record<V, string>, V extends string | number | symbol = keyof U>(searchText: string, properties: V[]) =>
    (item: U): boolean => {
        const finalSearchText = searchText.toLowerCase();
        return properties.some((prop) => item[prop].toLowerCase().includes(finalSearchText));
    };
