/* reference colors */
const referenceColors = {
    /* primary */
    primary100: '#FFFFFF',
    primary99: '#FFFBFF',
    primary98: '#FCF8FF',
    primary96: '#F6F2FF',
    primary95: '#F2EEFF',
    primary94: '#EFEBFF',
    primary92: '#E9E5FF',
    primary90: '#E3DFFF',
    primary87: '#D9D6FF',
    primary80: '#C4C0FF',
    primary70: '#A5A1FF',
    primary60: '#8781FE',
    primary50: '#6D67E1',
    primary40: '#544DC7',
    primary35: '#483FBA',
    primary30: '#3C32AE',
    primary25: '#2F23A2',
    primary24: '#2D1FA0',
    primary22: '#28189C',
    primary20: '#231098',
    primary17: '#1C0091',
    primary12: '#140074',
    primary10: '#110068',
    primary6: '#0B0051',
    primary5: '#09004A',
    primary4: '#070042',
    primary0: '#000000',

    /* secondary */
    secondary100: '#FFFFFF',
    secondary99: '#F7FDFF',
    secondary98: '#EEFCFF',
    secondary96: '#DDF9FF',
    secondary95: '#D3F7FF',
    secondary94: '#CAF5FF',
    secondary92: '#B6F2FF',
    secondary90: '#A0EFFF',
    secondary87: '#79EAFF',
    secondary80: '#51D7EE',
    secondary70: '#26BBD2',
    secondary60: '#009FB3',
    secondary50: '#008394',
    secondary40: '#006876',
    secondary35: '#005B67',
    secondary30: '#004E59',
    secondary25: '#00424B',
    secondary24: '#004049',
    secondary22: '#003B43',
    secondary20: '#00363E',
    secondary17: '#002F36',
    secondary12: '#002429',
    secondary10: '#001F25',
    secondary6: '#00161B',
    secondary5: '#001418',
    secondary4: '#001114',
    secondary0: '#000000',

    /* error */
    error100: '#FFFFFF',
    error99: '#FFFBFC',
    error98: '#FFF7F7',
    error96: '#FFF0EE',
    error95: '#FFEDEA',
    error94: '#FFE9E6',
    error92: '#FFE2DE',
    error90: '#FFDAD6',
    error87: '#FFCFC9',
    error80: '#FFB4AB',
    error70: '#FF897D',
    error60: '#FF5449',
    error50: '#DE3730',
    error40: '#BA1A1A',
    error35: '#A80710',
    error30: '#93000A',
    error25: '#7E0007',
    error24: '#790006',
    error22: '#710005',
    error20: '#690005',
    error17: '#5C0004',
    error12: '#490002',
    error10: '#410002',
    error6: '#310001',
    error5: '#2D0001',
    error4: '#280001',
    error0: '#000000',

    /* warning */
    warning100: '#FFFFFF',
    warning99: '#FFFBED',
    warning98: '#FFF9E5',
    warning96: '#FFF4D4',
    warning95: '#FFF1C6',
    warning94: '#FFEDB8',
    warning92: '#FFE9A8',
    warning90: '#FFE699',
    warning87: '#FFE187',
    warning80: '#FFDA65',
    warning70: '#FFCA28',
    warning60: '#FFB300',
    warning50: '#F3A108',
    warning40: '#E08E00',
    warning35: '#D68800',
    warning30: '#B57200',
    warning25: '#B27000',
    warning24: '#AB6B00',
    warning22: '#9C6200',
    warning20: '#845200',
    warning17: '#734800',
    warning12: '#613D00',
    warning10: '#4D3103',
    warning6: '#3B2400',
    warning5: '#2E1C00',
    warning4: '#211500',
    warning0: '#000000',

    /* success */
    success100: '#FFFFFF',
    success99: '#F5FFF8',
    success98: '#EBFFF1',
    success96: '#D9FFE4',
    success95: '#C9FFD9',
    success94: '#B5FFCB',
    success92: '#9EF7C3',
    success90: '#91EEB7',
    success87: '#78EBA7',
    success80: '#68DB9B',
    success70: '#34C27A',
    success60: '#00A662',
    success50: '#008950',
    success40: '#006D3F',
    success35: '#005C33',
    success30: '#00522E',
    success25: '#004A29',
    success24: '#004526',
    success22: '#003D20',
    success20: '#00391E',
    success17: '#00301A',
    success12: '#002611',
    success10: '#00210F',
    success6: '#001A0C',
    success5: '#00170B',
    success4: '#001209',
    success0: '#000000',

    /* informational */
    informational100: '#FFFFFF',
    informational99: '#FCFCFF',
    informational98: '#F8F9FF',
    informational96: '#F0F5FF',
    informational95: '#EAF1FF',
    informational94: '#E4EFFF',
    informational92: '#DBEAFF',
    informational90: '#D1E4FF',
    informational87: '#C2DDFF',
    informational80: '#9FCAFF',
    informational70: '#64AFFF',
    informational60: '#2B95EF',
    informational50: '#007ACD',
    informational40: '#0061A4',
    informational35: '#00538F',
    informational30: '#00497D',
    informational25: '#004270',
    informational24: '#003D69',
    informational22: '#003761',
    informational20: '#003258',
    informational17: '#002B4D',
    informational12: '#002240',
    informational10: '#001D36',
    informational6: '#00172B',
    informational5: '#001426',
    informational4: '#00101F',
    informational0: '#000000',

    /* neutral */
    neutral100: '#FFFFFF',
    neutral99: '#FAFCFC',
    neutral95: '#EFF1F1',
    neutral90: '#E1E3E3',
    neutral80: '#C4C7C7',
    neutral70: '#A9ACAC',
    neutral60: '#8E9192',
    neutral50: '#747878',
    neutral40: '#5C5F5F',
    neutral30: '#3D4345',
    neutral20: '#202324',
    neutral10: '#111414',
    neutral0: '#000000',
    neutral4: '#060808',
    neutral5: '#0B0D0D',
    neutral6: '#0D0F0F',
    neutral12: '#16181A',
    neutral17: '#1B1E1F',
    neutral22: '#242829',
    neutral24: '#2D3233',
    neutral25: '#343A3B',
    neutral35: '#494E4F',
    neutral87: '#D7D9D9',
    neutral92: '#E6E8E8',
    neutral94: '#EBEDED',
    neutral96: '#F5F7F7',
    neutral98: '#F8FAFA',

    /* neutral-variant */
    neutralVariant100: '#FFFFFF',
    neutralVariant99: '#F9FBFC',
    neutralVariant98: '#F5F9FC',
    neutralVariant96: '#F2F7FA',
    neutralVariant95: '#F0F6FA',
    neutralVariant94: '#E9F0F5',
    neutralVariant92: '#E4EBF0',
    neutralVariant90: '#DCE4E9',
    neutralVariant87: '#D2DBE0',
    neutralVariant80: '#C0C8CD',
    neutralVariant70: '#A4ACB1',
    neutralVariant60: '#8A9297',
    neutralVariant50: '#70787D',
    neutralVariant40: '#586064',
    neutralVariant35: '#4D575C',
    neutralVariant30: '#40484C',
    neutralVariant25: '#363C40',
    neutralVariant24: '#30373B',
    neutralVariant22: '#272D30',
    neutralVariant20: '#22282B',
    neutralVariant17: '#1E2426',
    neutralVariant12: '#141B1F',
    neutralVariant10: '#11171A',
    neutralVariant6: '#0C1012',
    neutralVariant5: '#090B0D',
    neutralVariant4: '#050708',
    neutralVariant0: '#000000',

    /* chart */
    categorical1: '#4D67FF',
    categorical2: '#00BCD4',
    categorical3: '#FF8A7B',
    categorical4: '#FFCA28',
    categorical5: '#FFA000',
    categorical6: '#673AB7',
    categorical7: '#FF5C00',
    categorical8: '#05B1FF',
    categorical9: '#41A447',
    categorical10: ' #D74034',

    sequential1: '#C7FFDA',
    sequential2: '#7DFFC9',
    sequential3: '#06F0D4',
    sequential4: '#00BCD4',
    sequential5: '#069DC7',
    sequential6: '#05B1FF',
    sequential7: '#0085FF',
    sequential8: '#4D67FF',
    sequential9: '#2848EE',
    sequential10: '#001D93',

    polarized1: '#D74034',
    polarized2: '#FF5C00',
    polarized3: '#FFA000',
    polarized4: '#FFCA28',
    polarized5: '#78DC77',
    polarized6: '#5DC05E',
    polarized7: '#00BCD4',
    polarized8: '#0085FF',
    polarized9: '#2848EE',
    polarized10: '#001D93',
};

export default referenceColors;
