
import { deleteAvoidableDay } from '@/shared/mutations';
import { AvoidableDay, Mutation } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'DeleteAvoidableDayCard',
    props: {
        avoidableDay: {
            type: Object as PropType<AvoidableDay>,
            required: true,
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        goBack() {
            this.$emit('goBack');
        },
        async deleteAvoidableDay() {
            const response = await this.$apollo.mutate<Mutation>({
                mutation: deleteAvoidableDay,
                variables: {
                    input: {
                        id: +this.avoidableDay.id,
                        visitId: +this.$route.params.id,
                    },
                },
            });
            this.$toast.success('Avoidable Day Deleted');
            this.$emit('deletedDay', response.data?.deleteAvoidableDay);
            this.goBack();
        },
    },
});
