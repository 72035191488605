
import CreateDenialDialog from '@/components/visit/denials/CreateDenialDialog.vue';
import DenialsListItem from '@/components/visit/denials/DenialsListItem.vue';
import { DenialsTabNames, DenialStatus } from '@/models/visit/denials/Denials.model';
import { appealDenial, setAppealLetterId } from '@/shared/mutations';
import { getVisitDenials } from '@/shared/queries';
import { useVisitStore } from '@/stores/VisitStore';
import { Denial, Mutation, Query } from 'generated/graphql/graphql';
import { mapState } from 'pinia';
import Vue from 'vue';

export default Vue.extend({
    name: 'DenialsListView',
    components: {
        CreateDenialDialog,
        DenialsListItem,
    },
    data: () => ({
        selectedTabIndex: 0,
        unresolvedDenials: [] as Denial[],
        resolvedDenials: [] as Denial[],
        DenialsTabNames,
        isCreateDenialDialogOpen: false,
        visitStore: useVisitStore(),
    }),
    computed: {
        ...mapState(useVisitStore, ['appealLetterSummary', 'appealedDenialId']),
    },
    watch: {
        async appealLetterSummary() {
            if (this.visitStore.selectedDenialId && this.visitStore.appealLetterSummary) {
                await this.$apollo.mutate<Mutation>({
                    mutation: setAppealLetterId,
                    variables: {
                        input: {
                            denialId: this.visitStore.selectedDenialId,
                            visitId: +this.$route.params.id,
                            appealLetterId: +this.visitStore.appealLetterSummary?.id,
                        },
                    },
                });
            }
        },
        async appealedDenialId() {
            if (this.visitStore.appealedDenialId) {
                await this.markDenialAsAppealed(this.visitStore.appealedDenialId);
            }
        },
    },
    async created() {
        this.loadDenials();
    },
    methods: {
        async markDenialAsAppealed(denialId: number) {
            this.updateDenialStatus(denialId, DenialStatus.APPEAL);
            await this.$apollo.mutate<Mutation>({
                mutation: appealDenial,
                variables: {
                    input: {
                        denialId: denialId,
                        visitId: +this.$route.params.id,
                    },
                },
            });
            this.visitStore.appealedDenialId = undefined;
        },
        updateDenialStatus(denialId: number, status: DenialStatus) {
            const index = this.unresolvedDenials.findIndex((denial) => denial.id === denialId);
            this.unresolvedDenials[index].status = status;
        },
        openCreateDenialDialog() {
            this.isCreateDenialDialogOpen = true;
        },
        closeDialog() {
            this.isCreateDenialDialogOpen = false;
        },
        addDenial(newDenial: Denial) {
            this.unresolvedDenials.unshift(newDenial);
        },
        resolveDenial(denialId: number, denial: Denial) {
            const index = this.unresolvedDenials.findIndex((denial) => denial.id === denialId);
            if (index !== -1) {
                const [resolveDenial] = this.unresolvedDenials.splice(index, 1);
                resolveDenial.status = DenialStatus.RESOLVED;
                resolveDenial.resolvedDate = denial.resolvedDate;
                resolveDenial.overturnAmount = denial.overturnAmount;
                resolveDenial.outcome = denial.outcome;
                resolveDenial.appealMethod = denial.appealMethod;
                this.resolvedDenials.push(resolveDenial);
            }
        },
        async loadDenials() {
            const response = await this.$apollo.query<Query>({
                query: getVisitDenials,
                variables: {
                    filter: {
                        visitId: +this.$route.params.id,
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.resolvedDenials = [];
            this.unresolvedDenials = [];
            response.data.denials.forEach((denial) => {
                if (denial.status == DenialStatus.RESOLVED) {
                    this.resolvedDenials.push(denial);
                } else if (
                    denial.status == DenialStatus.APPEAL ||
                    denial.status == DenialStatus.ESCALATE ||
                    denial.status == DenialStatus.OPEN
                ) {
                    this.unresolvedDenials.push(denial);
                } else {
                    console.error('Unrecognized denial type encountered: ' + denial.status);
                }
            });
        },
        updateEscalationQueryId(denialId: number, queryId: string) {
            const index = this.unresolvedDenials.findIndex((denial) => denial.id === denialId);
            this.unresolvedDenials[index].escalationQueryId = queryId;
            this.unresolvedDenials = [...this.unresolvedDenials];
        },
    },
});
