export enum DenialStatus {
    OPEN = 'Open',
    APPEAL = 'Appeal',
    ESCALATE = 'Escalate',
    RESOLVED = 'Resolved',
}

export const DenialStatusDisplayName = new Map([
    ['Open', 'Open'],
    ['Appeal', 'Appeal sent'],
    ['Escalate', 'Escalate'],
    ['Resolved', 'Resolved'],
]);

export enum DenialsTabNames {
    RESOLVED = 'Resolved',
    UNRESOLVED = 'Unresolved',
}

export const DenialTypes = ['Concurrent', 'Retrospective'];

export enum AppealMethodType {
    EMAIL = 'Email',
    CALL = 'Call',
    MAIL = 'Mail',
    EFAX = 'eFax',
    NO_APPEAL = 'No appeal conducted',
}

export enum DenialOutcomeType {
    OVERTURNED = 'Denial overturned',
    PARTIALLY_OVERTURNED = 'Denial partially overturned',
    UPHELD = 'Denial upheld',
}
