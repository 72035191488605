
import CaseNote from '@/components/visit/case-notes/CaseNote.vue';
import DrawerHeader from '@/components/visit/drawer/DrawerHeader.vue';
import { readVisitComment, saveVisitComment } from '@/shared/mutations';
import { getVisitComments } from '@/shared/queries';
import { Mutation, Query, VisitComment } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'CaseNotesDetailView',
    components: {
        CaseNote,
        DrawerHeader,
    },
    data: () => ({
        loading: false,
        comment: undefined as string | undefined,
        pendingMutation: false,
        showDeleteCard: false,
        disableRefreshButton: false,
        noteToDelete: undefined as VisitComment | undefined,
        noteToDeleteIndex: undefined as number | undefined,
        caseNotes: [] as VisitComment[],
    }),
    async created() {
        this.loading = true;
        await this.refresh();
        if (this.caseNotes.length > 0) {
            this.readNote(this.caseNotes[this.caseNotes.length - 1]);
        }
    },
    methods: {
        scrollToBottom() {
            this.$nextTick(() => {
                const target = document.querySelector<HTMLElement>('#case-notes-scroll-target');
                if (target) {
                    target.scrollIntoView({ behavior: 'smooth' });
                }
            });
        },
        deleteNote(index: number) {
            this.disableRefreshButton = true;
            this.noteToDelete = this.caseNotes.at(index);
            this.noteToDeleteIndex = index;
            this.showDeleteCard = true;
        },
        async refresh() {
            const response = await this.$apollo.query<Query>({
                query: getVisitComments,
                variables: {
                    filter: {
                        visitId: +this.$route.params.id,
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.caseNotes = response.data.visitComments;
            this.loading = false;
            this.scrollToBottom();
        },
        async sendNote() {
            try {
                this.pendingMutation = true;
                const response = await this.$apollo.mutate<Mutation>({
                    mutation: saveVisitComment,
                    variables: {
                        input: {
                            visitId: +this.$route.params.id,
                            comment: this.comment,
                        },
                    },
                });
                this.comment = '';
                if (response.data?.saveVisitComment) {
                    this.caseNotes.push(response.data.saveVisitComment);
                }
            } finally {
                this.pendingMutation = false;
                this.scrollToBottom();
            }
        },
        hideDeleteCard() {
            this.disableRefreshButton = false;
            this.showDeleteCard = false;
            this.noteToDelete = undefined;
            this.noteToDeleteIndex = undefined;
        },
        async saveDeletion() {
            try {
                this.pendingMutation = true;
                if (this.noteToDelete) {
                    await this.$apollo.mutate<Mutation>({
                        mutation: saveVisitComment,
                        variables: {
                            input: {
                                id: +this.noteToDelete?.id,
                                visitId: +this.$route.params.id,
                            },
                        },
                    });
                }
                if (this.noteToDeleteIndex !== undefined) {
                    this.caseNotes.splice(this.noteToDeleteIndex, 1);
                }
            } finally {
                this.pendingMutation = false;
                this.hideDeleteCard();
            }
        },
        async readNote(note: VisitComment) {
            await this.$apollo.mutate<Mutation>({
                mutation: readVisitComment,
                variables: {
                    input: {
                        commentId: +note.id,
                        visitId: +this.$route.params.id,
                        version: note.version,
                    },
                },
            });
        },
    },
});
