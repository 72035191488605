
import DocumentDrawerButton from '@/components/shared/DocumentDrawerButton.vue';
import { formatDate, formatPersonName, formatTime, withDefault } from '@/components/shared/utils';
import DeleteFromClinicalSummaryButton from '@/components/visit/clinical-summary/DeleteFromClinicalSummaryButton.vue';
import { ClinicalDocumentItemType, DrawerType } from '@/models';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { MicrobiologyResult } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'ClinicalSummaryMicrobiologyTable',
    components: {
        DocumentDrawerButton,
        DeleteFromClinicalSummaryButton,
    },
    props: {
        microbiologyResults: {
            type: Array<MicrobiologyResult>,
            required: true,
        },
    },
    data: () => ({
        ClinicalSummaryItemType: ClinicalDocumentItemType,
        DrawerType,
        hover: false,
        visitDrawerStore: useVisitDrawerStore(),
    }),
    methods: {
        formatDate,
        formatTime,
        withDefault,
        formatPersonName,
        openDrawer(id: string): void {
            this.visitDrawerStore.openDrawer({
                drawer: DrawerType.MICROBIOLOGY,
                params: {
                    microbiologyId: id,
                },
                drawerProps: {
                    dark: true,
                    large: true,
                    header: {
                        title: 'Document Preview',
                    },
                },
                breadcrumbs: [
                    {
                        drawer: DrawerType.CLINICAL_SUMMARY,
                        params: this.visitDrawerStore.params,
                        drawerProps: { dark: true },
                    },
                ],
            });
        },
    },
});
