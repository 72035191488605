var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('Transition',{attrs:{"appear":"","name":_vm.dataDrawerName}},[(_vm.show)?_c('v-sheet',{staticClass:"data-drawer",class:{
                'enter-left': _vm.dataDrawerName === 'data-drawer-left',
                'enter-right': _vm.dataDrawerName === 'data-drawer-right',
                'one-hundred-height': _vm.height === 100,
                'ninety-height': _vm.height === 90,
                'thirty-width': _vm.width === 30,
                'sixty-width': _vm.width === 60,
                'max-width-1024': _vm.maxWidth,
            },attrs:{"elevation":"10"}},[(!_vm.hideClose)?_c('v-btn',{staticClass:"close-button",attrs:{"icon":"","color":_vm.darkMode ? 'white' : 'neutral-700'},on:{"click":function($event){return _vm.close()}}},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":"fa-regular fa-xmark"}})],1):_vm._e(),_vm._t("default")],2):_vm._e()],1),_c('Transition',{attrs:{"appear":"","name":"data-drawer-backdrop"}},[(_vm.show)?_c('div',{staticClass:"data-drawer-backdrop",class:{
                'enter-left': _vm.dataDrawerName === 'data-drawer-left',
                'enter-right': _vm.dataDrawerName === 'data-drawer-right',
            },on:{"click":function($event){return _vm.close()}}}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }