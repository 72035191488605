import { render, staticRenderFns } from "./DenialIcon.vue?vue&type=template&id=ed591442&scoped=true&"
import script from "./DenialIcon.vue?vue&type=script&lang=ts&"
export * from "./DenialIcon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed591442",
  null
  
)

export default component.exports