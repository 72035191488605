import { Role } from '@/auth/role.enum';
import { UserFunction } from '@/auth/userfunction.enum';
import { defineStore } from 'pinia';
import Vue from 'vue';

declare let pendo: any;

export const useUserStore = () => {
    const userStore = defineStore('user', {
        state: () => ({
            isInitialized: undefined as boolean | undefined,
            currentRole: undefined as Role | undefined,
            availableRoles: undefined as Role[] | undefined,
            isAdmin: false,
            isManager: false,
            canViewAnalytics: false,
            canBuildAnalytics: false,
            canViewTextGen: false,
            canViewLotCondition: false,
            canViewProminentAI: false,
            canViewTimelineDailySummaries: false,
            canViewDeepLinks: false,
            canViewAppealsLetters: false,
            firstName: '',
            lastName: '',
        }),
        actions: {
            async initialize() {
                this.$patch({
                    isInitialized: false,
                });

                // Fetch the current access token from Okta. Note it's also cached in
                // localStorage after login but that can be modified so don't trust it.
                const response = await Vue.prototype.$auth.token.getWithoutPrompt();

                let roles = new Set<Role>();
                const userFunctions = new Set<UserFunction>();
                for (const iogroup of response.tokens.accessToken?.claims.iogroups) {
                    const [key, value] = iogroup.split(':');
                    if (key === 'io~role') {
                        const role = Role.fromJwtClaim(value);
                        if (role) {
                            roles.add(role);
                        }
                    }
                    if (key === 'io~func') {
                        const userFunction = UserFunction.fromJwtClaim(value);
                        if (userFunction) {
                            userFunctions.add(userFunction);
                        }
                    }
                }
                const isAdmin = roles.has(Role.ADMIN);
                const isManager = roles.has(Role.UM_MANAGER);
                if (isAdmin || isManager) {
                    roles = new Set([Role.UM_NURSE, Role.PHYSICIAN_ADVISOR]);
                }
                const rolesIterator = roles[Symbol.iterator]();
                const user = await Vue.prototype.$auth.getUser();

                this.$patch({
                    isInitialized: true,
                    currentRole: rolesIterator.next().value,
                    availableRoles: Array.from(roles),
                    isAdmin,
                    isManager,
                    canBuildAnalytics: userFunctions.has(UserFunction.UM_ANALYTICS_BUILDER),
                    canViewAnalytics:
                        userFunctions.has(UserFunction.UM_ANALYTICS_BUILDER) || userFunctions.has(UserFunction.UM_ANALYTICS_VIEWER),
                    canViewTextGen: userFunctions.has(UserFunction.TEXT_GENERATION),
                    canViewLotCondition: userFunctions.has(UserFunction.UM_LOT_CONDITION),
                    canViewAppealsLetters: userFunctions.has(UserFunction.UM_APPEALS_LETTERS),
                    canViewDeepLinks: userFunctions.has(UserFunction.UM_DEEP_LINKS),
                    canViewProminentAI: userFunctions.has(UserFunction.UM_PROMINENT_AI),
                    canViewTimelineDailySummaries: userFunctions.has(UserFunction.UM_TIMELINE_DAILY_SUMMARIES),
                    firstName: user.given_name,
                    lastName: user.family_name,
                });

                // initialize pendo
                const isIodineAccount = user.email && user.email.includes('@iodinesoftware.com');
                const hostname = new URL(window.location.href).hostname;
                const accountId = hostname.includes('localhost') ? 'dev' : hostname.split('-', 1)[0];

                pendo.initialize({
                    visitor: {
                        id: user.sub,
                        isIodineAccount,
                    },
                    account: {
                        id: accountId,
                    },
                });
            },
            logout() {
                this.$reset();
            },
        },
    });
    const store = userStore();
    if (store.isInitialized === undefined) {
        store.initialize();
    }
    return store;
};
