
import { EventBus, Events } from '@/components/shared/event-bus';
import { Config } from '@/config';
import OktaSignIn from '@okta/okta-signin-widget';
import { Mutation } from 'generated/graphql/graphql';
import gql from 'graphql-tag';
import Vue from 'vue';

export default Vue.extend({
    name: 'Login',
    data: () => ({
        widget: OktaSignIn,
        loading: true,
    }),
    computed: {
        currentYear(): number {
            return new Date().getFullYear();
        },
    },
    async mounted() {
        const config: Config = Vue.prototype.$config;
        this.createWidget(config);

        if (await this.$auth.session.exists()) {
            this.loading = true;
            this.handleLoginRedirect();
        } else {
            this.loading = false;
            this.showLoginComponent(config.oidc.scopes);
        }
    },
    destroyed() {
        this.widget.remove();
    },
    methods: {
        createWidget(config: Config) {
            this.widget = new OktaSignIn({
                baseUrl: config.oidc.issuer.split('/oauth2')[0],
                clientId: config.oidc.clientId,
                redirectUri: config.oidc.redirectUri,
                i18n: {
                    en: {
                        'primaryauth.title': 'Login',
                        'primaryauth.username.placeholder': 'Email Address',
                        'oform.next': 'LOG IN',
                    },
                },
                authParams: {
                    issuer: config.oidc.issuer,
                    scopes: config.oidc.scopes,
                },
                useClassicEngine: true,
                features: {
                    idpDiscovery: true,
                },
                idpDiscovery: {
                    requestContext: window.location.href,
                },
            });
        },
        async showLoginComponent(scopes: string[]) {
            this.$nextTick(() => {
                this.widget.showSignInToGetTokens({
                    el: '#okta-signin-container',
                    scopes,
                });
            });
        },
        handleLoginRedirect() {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;
            this.$auth.token.getWithoutPrompt().then(async function (response: any) {
                that.$auth.tokenManager.setTokens(response.tokens);
                await that.login();
                that.$router.push({ name: 'worklist' });
            });
        },
        async login() {
            try {
                const result = await this.$apollo.mutate<Mutation>({
                    mutation: gql`
                        mutation LoginUser {
                            loginUser
                        }
                    `,
                });
                // Assuming the mutation returns a successful result, emit the logged-in event here.
                EventBus.$emit(Events.USER_LOGGED_IN);

                return result;
            } catch (error) {
                console.log('Error during login:', error);
                throw error;
            }
        },
    },
});
