
import { formatDate, formatPersonName, formatPhoneNumber, formatTime, properCase } from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import FaxRequestStatusIcon from '@/components/visit/clinical-summary/FaxRequestStatusIcon.vue';
import { ClinicalDocument, FaxRequest } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ClinicalSummarySentListItem',
    components: {
        VerticalField,
        FaxRequestStatusIcon,
    },
    props: {
        clinicalSummary: {
            type: Object as PropType<ClinicalDocument>,
            required: true,
        },
    },
    computed: {
        fax() {
            // faxes to be sorted server side, with most recent first
            // return blank object to prevent parsing error in tests when using optional chaining operator
            const fax = this.clinicalSummary?.fax;
            return fax ?? ({} as unknown as FaxRequest);
        },
    },
    methods: {
        formatDate,
        formatTime,
        formatPersonName,
        formatPhoneNumber,
        properCase,
        select() {
            this.$router.push({
                name: 'clinicalsummarylistopen',
                params: { id: this.$route.params.id, clinicalSummaryId: this.clinicalSummary.id },
            });
        },
    },
});
