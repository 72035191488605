// potential values for "type" field in parsed json from "descriptionJson" field in visit summary item table
export enum DescribedItemType {
    DOCUMENT = 'document',
    MEDICATION_ORDER = 'medication_order',
    LAB_RESULT = 'lab_result',
    MICROBIOLOGY = 'microbiology',
}

// not a fan of the name here
export interface VisitSummaryItemDescriptionCondition {
    id: string;
    name: string;
}

export interface VisitSummaryItemDescription<ItemType extends DescribedItemType = DescribedItemType> {
    items: DescribedItem<ItemType>[];
    visitConditions?: VisitSummaryItemDescriptionCondition[];
}

export interface DescribedItem<T extends DescribedItemType = DescribedItemType> {
    id: string;
    type: T;
    date: string;
}

export interface DocumentItem extends DescribedItem<DescribedItemType.DOCUMENT> {
    name: string;
    text: string[];
}

export interface LabResultItem extends DescribedItem<DescribedItemType.LAB_RESULT> {
    value: string;
}

// these are declared as types rather than interfaces because they do not extend described items
export type MedicationOrderItem = DescribedItem<DescribedItemType.MEDICATION_ORDER>;

export type MicrobiologyItem = DescribedItem<DescribedItemType.MICROBIOLOGY>;
