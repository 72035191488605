
import { formatDateTime, formatPersonName } from '@/components/shared/utils';
import { saveVisitComment } from '@/shared/mutations';
import { Mutation, VisitComment } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

type ReviewOutcome = 'COMPLETE' | 'ESCALATE' | 'AUTHORIZATION' | 'DENIAL_CREATED' | 'DENIAL_APPEALED' | 'DENIAL_RESOLVED';
export default Vue.extend({
    name: 'CaseNote',
    props: {
        note: {
            type: Object as PropType<VisitComment>,
            required: true,
        },
        deleteMode: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        hover: false,
        editing: false,
        saving: false,
        showMenu: false,
        originalCommentBeforeEdit: '',
        menuPositionX: undefined as number | undefined,
        menuPositionY: undefined as number | undefined,
    }),
    computed: {
        testId(): string {
            const ids: Record<ReviewOutcome, string> = {
                COMPLETE: 'review-complete',
                ESCALATE: 'case-escalated',
                AUTHORIZATION: 'authorization-comment',
                DENIAL_CREATED: 'denial-created-comment',
                DENIAL_APPEALED: 'denial-appealed-comment',
                DENIAL_RESOLVED: 'denial-resolved-comment',
            };
            return ids[this.note.reviewOutcome as ReviewOutcome] || '';
        },
        iconTestId(): string {
            const ids: Record<ReviewOutcome, string> = {
                COMPLETE: 'completed-icon',
                ESCALATE: 'escalated-icon',
                AUTHORIZATION: 'authorization-icon',
                DENIAL_CREATED: 'denial-created-icon',
                DENIAL_APPEALED: 'denial-appealed-icon',
                DENIAL_RESOLVED: 'denial-resolved-icon',
            };
            return ids[this.note.reviewOutcome as ReviewOutcome] || '';
        },
        reviewOutcomeText(): string {
            const texts: Record<ReviewOutcome, string> = {
                COMPLETE: 'Review Complete',
                ESCALATE: 'Case Escalated',
                AUTHORIZATION: 'Authorization',
                DENIAL_CREATED: 'Denial Created',
                DENIAL_APPEALED: 'Appeal Sent',
                DENIAL_RESOLVED: 'Denial Resolved',
            };
            return texts[this.note.reviewOutcome as ReviewOutcome] || '';
        },
        reviewOutcomeIcon(): [string, string] {
            const icons: Record<ReviewOutcome, [string, string]> = {
                COMPLETE: ['fa-regular', 'fa-circle-check'],
                ESCALATE: ['fa-regular', 'fa-comment-arrow-up-right'],
                AUTHORIZATION: ['fa-regular', 'fa-calendar-check'],
                DENIAL_CREATED: ['fa-regular', 'fa-file'],
                DENIAL_APPEALED: ['fa-regular', 'fa-file-export'],
                DENIAL_RESOLVED: ['fa-regular', 'fa-circle-check'],
            };
            return icons[this.note.reviewOutcome as ReviewOutcome] || [];
        },
        iconColor(): string {
            const colors: Record<ReviewOutcome, string> = {
                COMPLETE: 'success-500--text',
                ESCALATE: 'warning-700--text',
                AUTHORIZATION: 'primary-a400--text',
                DENIAL_CREATED: 'primary-a400--text',
                DENIAL_APPEALED: 'primary-a400--text',
                DENIAL_RESOLVED: 'success-500--text',
            };
            return colors[this.note.reviewOutcome as ReviewOutcome] || '';
        },
    },
    methods: {
        formatDateTime,
        formatPersonName,
        showOptions(event: MouseEvent) {
            this.showMenu = true;
            this.menuPositionX = event.clientX;
            this.menuPositionY = event.clientY;
        },
        async saveNote() {
            try {
                this.saving = true;
                const response = await this.$apollo.mutate<Mutation>({
                    mutation: saveVisitComment,
                    variables: {
                        input: {
                            id: +this.note.id,
                            visitId: +this.$route.params.id,
                            comment: this.note.comment,
                        },
                    },
                });
                if (response.data?.saveVisitComment) {
                    this.note.version = response.data?.saveVisitComment.version;
                    this.note.modifiedDate = response.data?.saveVisitComment.modifiedDate;
                }
            } finally {
                this.saving = false;
                this.editing = false;
                this.$emit('editing', this.editing);
            }
        },
        editNote() {
            this.editing = true;
            this.$emit('editing', this.editing);
            this.originalCommentBeforeEdit = this.note.comment;
        },
        cancelEdit() {
            this.editing = false;
            this.$emit('editing', this.editing);
            this.note.comment = this.originalCommentBeforeEdit;
            this.originalCommentBeforeEdit = '';
        },
    },
});
