import { ClinicalDocumentType, DrawerType } from '@/models';
import { DocumentBuilderTab } from '@/models/clinical-document/ClinicalDocument.model';
import { Document, FaxNumber } from 'generated/graphql/graphql';

interface AllDrawerParams {
    microbiologyId: string | undefined;
    documentId: string | undefined;
    providedDocuments: Document[] | undefined;
    mentionText: string | undefined | null;
    medicalConceptId: string | undefined;
    medicationGenericName: string | undefined;
    referenceId: string | undefined;
    categoryName: string | undefined;
    highlightEntityIds: Set<string> | undefined;
    faxNumber: FaxNumber | undefined;
    payerName: string | undefined;
    visitId: string | undefined;
    clinicalDocumentId: string | undefined;
    type: string | undefined;
    medicationName: string | undefined;
    documentType: ClinicalDocumentType | undefined;
    documentBuilderSelectedTab: DocumentBuilderTab | undefined;
}

export type DrawerParams = Partial<AllDrawerParams>;

export const ResetDrawerParams: AllDrawerParams = {
    microbiologyId: undefined,
    documentId: undefined,
    providedDocuments: undefined,
    mentionText: undefined,
    medicalConceptId: undefined,
    medicationGenericName: undefined,
    referenceId: undefined,
    categoryName: undefined,
    highlightEntityIds: undefined,
    faxNumber: undefined,
    payerName: undefined,
    visitId: undefined,
    clinicalDocumentId: undefined,
    type: undefined,
    medicationName: undefined,
    documentType: undefined,
    documentBuilderSelectedTab: undefined,
};

export interface DrawerProps {
    dark?: boolean;
    header?: { title?: string };
    large?: boolean;
    enterFrom?: Direction;
    height?: number;
    width?: number;
    maxWidth?: boolean;
}

export enum Direction {
    LEFT,
    RIGHT,
}
export interface Breadcrumb {
    drawer: DrawerType;
    params?: DrawerParams;
    drawerProps?: DrawerProps;
}

export interface OpenDrawerProps extends Breadcrumb {
    breadcrumbs?: OpenDrawerProps[];
}
