
import { pluralize } from '@/components/shared/utils';
import DocumentDetailViewBody from '@/components/visit/documentation-and-services/DocumentDetailViewBody.vue';
import DocumentDetailViewHeader from '@/components/visit/documentation-and-services/DocumentDetailViewHeader.vue';
import DrawerHeader from '@/components/visit/drawer/DrawerHeader.vue';
import { getMetaDataForDocuments, getRelatedDocumentedConcepts, getTimelineDocument } from '@/shared/queries';
import { Document, Query } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'DocumentDrawerDetailView',
    components: {
        DocumentDetailViewHeader,
        DocumentDetailViewBody,
        DrawerHeader,
    },
    props: {
        documentId: String,
        medicalConceptId: String,
        mentionText: String,
        providedDocuments: Array<Document>,
    },
    data: () => ({
        relatedDocuments: {} as Record<string, Document>,
        documentIds: [] as string[],
        selectedDocumentId: undefined as string | undefined,
        showArrows: false,
    }),
    created() {
        if (this.providedDocuments) {
            this.storeDocuments(this.providedDocuments);
            this.initializeSelectedDocument();
        } else if (this.medicalConceptId) {
            // only query to populate documents if we are able to and they are not provided
            this.populateRelatedDocuments(this.medicalConceptId).then(this.initializeSelectedDocument);
        } else if (this.documentId) {
            this.initializeSelectedDocument();
        }
    },
    methods: {
        pluralize,
        toggleShowArrows() {
            this.showArrows = !this.showArrows;
        },
        async setSelectedDocument(documentId: string) {
            if (!this.relatedDocuments[documentId]?.body) {
                const response = await this.getDocument(documentId, this.medicalConceptId);
                const doc = response?.document;
                if (doc && documentId) this.relatedDocuments[documentId] = doc;
            }
            this.selectedDocumentId = documentId;
        },
        async getDocument(documentId: string, medicalConceptId?: string) {
            try {
                const response = await this.$apollo.query<Query>({
                    query: getTimelineDocument,
                    variables: {
                        filter: {
                            id: parseInt(documentId),
                            visitId: parseInt(this.$route.params.id),
                            medicalConceptId,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                return response.data;
            } catch (e) {
                return this.getDocumentDeprecated(documentId);
            }
        },
        async getRelatedDocuments(medicalConceptId: string) {
            try {
                const response = await this.$apollo.query<Query>({
                    query: getMetaDataForDocuments,
                    variables: {
                        filter: {
                            medicalConceptId,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                return response.data.documents?.items;
            } catch (e) {
                return this.getRelatedDocumentsDeprecated(medicalConceptId);
            }
        },
        async populateRelatedDocuments(medicalConceptId: string) {
            const relatedDocuments = await this.getRelatedDocuments(medicalConceptId);
            if (relatedDocuments) {
                this.storeDocuments(relatedDocuments);
            }
        },
        async initializeSelectedDocument() {
            if (this.documentId) this.moveDocumentToFrontOfCarosel(this.documentId);
            if (this.documentIds.length) this.setSelectedDocument(this.documentIds[0]);
            else console.error('related documents were populated, but no documentId found');
        },
        storeDocuments(documents: Document[]) {
            this.relatedDocuments = {};
            documents.forEach((doc) => (this.relatedDocuments[doc.id] = doc));
            // get the documentIds as a list here, to avoid multiple Object.keys() calls in the template
            this.documentIds = Object.keys(this.relatedDocuments);
        },
        moveDocumentToFrontOfCarosel(documentId: string) {
            // documentIds must be fully populated when this is called
            this.documentIds.unshift(documentId);
            this.documentIds = Array.from(new Set(this.documentIds));
        },

        // DEPRECATED METHODS
        // @deprecated use {@link getDocument} instead
        async getDocumentDeprecated(documentId: string, documentedConceptId?: string) {
            const response = await this.$apollo.query<Query>({
                query: getTimelineDocument,
                variables: {
                    id: parseInt(documentId),
                    visitId: parseInt(this.$route.params.id),
                    documentedConceptId,
                },
                fetchPolicy: 'no-cache',
            });
            return response.data;
        },
        // @deprecated use {@link getRelatedDocuments} instead
        async getRelatedDocumentsDeprecated(medicalConceptId: string) {
            const relatedDocumentedConcepts = await this.getRelatedDocumentedConcepts(medicalConceptId);
            // if there are no related concepts exit
            if (!relatedDocumentedConcepts) return [];

            const docIds = new Set<string>();
            const relatedDocumentsQueryArray = await Promise.all(
                relatedDocumentedConcepts
                    .filter((rdc) => {
                        if (!docIds.has(rdc.documentId)) {
                            docIds.add(rdc.documentId);
                            return true;
                        }
                        return false;
                    })
                    // if using this deprecated methods, it's safe to assume that we want the deprecated document method as well
                    .map((rdc) => this.getDocumentDeprecated(rdc.documentId, rdc.id))
            );

            // if there are no related documents exit
            if (!relatedDocumentsQueryArray) return [];

            // filter failed document fetches, remove wrapping document field from objects
            return this.filterDocumentFetches(relatedDocumentsQueryArray);
        },
        // @deprecated use {@link getRelatedDocuments} instead
        async getRelatedDocumentedConcepts(medicalConceptId: string) {
            const response = await this.$apollo.query<Query>({
                query: getRelatedDocumentedConcepts,
                variables: {
                    medicalConceptId,
                },
                fetchPolicy: 'no-cache',
            });
            return response.data.relatedDocumentedConcepts?.items;
        },
        // @deprecated not needed anymore
        filterDocumentFetches(q: (Query | undefined)[]) {
            return q.filter(Boolean).map((d) => d?.document as Document);
        },
    },
});
