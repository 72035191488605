import { defineStore } from 'pinia';

export const useWorklistFiltersStore = defineStore('worklistFilters', {
    state: () => ({
        isInitialized: false,
        autoSave: false,
        selectedFromDate: undefined as string | undefined | null,
        selectedToDate: undefined as string | undefined | null,
        selectedAdmitStatusCodes: undefined as string[] | undefined | null,
        selectedPredictedAdmitStatusCodes: undefined as string[] | undefined | null,
        selectedFacilityCodes: undefined as string[] | undefined | null,
        selectedLocationCodes: undefined as string[] | undefined | null,
        selectedHospitalServiceCodes: undefined as string[] | undefined | null,
        selectedPayerCodes: undefined as string[] | undefined | null,
        selectedPayerCategoryCodes: undefined as string[] | undefined | null,
        selectedUmNurseOwnerIds: undefined as number[] | undefined | null,
        losGreaterThanGmlos: undefined as boolean | undefined | null,
        activeDenials: undefined as boolean | undefined | null,
        failedToSend: undefined as boolean | undefined | null,
        readyToSend: undefined as boolean | undefined | null,
        divisionCodes: undefined as string[] | undefined | null,
        isNotReviewed: undefined as boolean | undefined | null,
    }),
    getters: {
        count: (state) => {
            let count = 0;
            if (state.selectedFromDate) count++;
            if (state.selectedToDate) count++;
            if (state.selectedAdmitStatusCodes?.length) count++;
            if (state.selectedPredictedAdmitStatusCodes?.length) count++;
            if (state.selectedFacilityCodes?.length) count++;
            if (state.selectedLocationCodes?.length) count++;
            if (state.selectedHospitalServiceCodes?.length) count++;
            if (state.selectedPayerCodes?.length) count++;
            if (state.selectedPayerCategoryCodes?.length) count++;
            if (state.selectedUmNurseOwnerIds?.length) count++;
            if (state.losGreaterThanGmlos) count++;
            if (state.activeDenials) count++;
            if (state.failedToSend) count++;
            if (state.readyToSend) count++;
            if (state.divisionCodes?.length) count++;
            if (state.isNotReviewed) count++;
            return count;
        },
        worklistFilterObject: (state) => ({
            fromAdmitDate: state.selectedFromDate,
            toAdmitDate: state.selectedToDate,
            admitStatusCodes: state.selectedAdmitStatusCodes,
            predictedAdmitStatusCodes: state.selectedPredictedAdmitStatusCodes,
            facilityCodes: state.selectedFacilityCodes,
            locationCodes: state.selectedLocationCodes,
            hospitalServiceCodes: state.selectedHospitalServiceCodes?.flat(),
            payerCodes: state.selectedPayerCodes?.flat(),
            payerCategoryCodes: state.selectedPayerCategoryCodes?.flat(),
            umNurseOwnerIds: state.selectedUmNurseOwnerIds,
            losExceedsGmlos: state.losGreaterThanGmlos,
            activeDenials: state.activeDenials,
            failedToSend: state.failedToSend,
            readyToSend: state.readyToSend,
            divisionCodes: state.divisionCodes,
            isNotReviewed: state.isNotReviewed,
        }),
    },
    actions: {
        reset() {
            this.$reset();
            this.$patch({
                isInitialized: true,
            });
        },
    },
});
