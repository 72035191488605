import { Breadcrumb, DrawerParams, DrawerProps, DrawerType, OpenDrawerProps, ResetDrawerParams } from '@/models';
import { defineStore } from 'pinia';

export const useVisitDrawerStore = defineStore('visitDrawer', {
    state: () => ({
        params: {} as DrawerParams,
        drawer: undefined as DrawerType | undefined,
        drawerProps: undefined as DrawerProps | undefined,
        breadcrumbs: [] as Breadcrumb[],
    }),
    actions: {
        openDrawer(props: OpenDrawerProps) {
            const { drawer, breadcrumbs, drawerProps } = props;
            const params = { ...ResetDrawerParams, ...props.params };
            const newBreadcrumbs = this.breadcrumbs.concat(breadcrumbs ?? []);
            if (!newBreadcrumbs.length && drawer !== DrawerType.GEN_AI_SUMMARY) this.$reset();
            this.$patch({
                drawer,
                params,
                drawerProps,
                breadcrumbs: newBreadcrumbs,
            });
        },
        backtrack() {
            const breadcrumb = this.breadcrumbs.pop();
            if (breadcrumb?.drawer === undefined) {
                this.closeDrawer();
            } else {
                const { drawer, params, drawerProps } = breadcrumb;
                this.$patch({
                    drawer,
                    params,
                    drawerProps,
                    breadcrumbs: this.breadcrumbs,
                });
            }
        },
        closeDrawer() {
            this.$reset();
        },
    },
});
