
import { pluralize, withDefault } from '@/components/shared/utils';
import VisitConditionConfidence from '@/components/visit/conditions/VisitConditionConfidence.vue';
import { VisitLengthOfTreatmentPredictionStatus, VisitLengthOfTreatmentStatus } from '@/models';
import { VisitCondition } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'VisitLengthOfTreatmentCondition',
    components: {
        VisitConditionConfidence,
    },
    props: {
        condition: {
            type: Object as PropType<VisitCondition>,
            default: () => ({} as VisitCondition),
            required: true,
        },
    },
    data: () => ({
        showWarningChip: false,
        observedCount: 0,
        expectedCount: 0,
        hover: false,
    }),
    computed: {
        tooltipText() {
            if (this.observedCount && this.expectedCount) {
                return `${this.overPredictionCount} ${pluralize('Day', this.overPredictionCount)} Over Expected Treatment`;
            }

            return '';
        },
        overPredictionCount() {
            return this.observedCount - this.expectedCount;
        },
        expectedTreatmentDays() {
            if (!this.hasLotPrediction) {
                return 'N/A';
            }

            // special case for displaying '< 24 hours when min and max are 0'
            if (
                this.condition.lengthOfTreatment?.expectedTreatmentMin === 0 &&
                this.condition.lengthOfTreatment?.expectedTreatmentMax === 0
            ) {
                return '< 24 Hrs';
            }

            if (
                this.condition.lengthOfTreatment?.expectedTreatmentMin &&
                this.condition.lengthOfTreatment?.expectedTreatmentMax === VisitLengthOfTreatmentPredictionStatus.NO_MAX
            ) {
                return `${this.condition.lengthOfTreatment.expectedTreatmentMin}+`;
            }

            return `${this.condition.lengthOfTreatment?.expectedTreatmentMin} - ${this.condition.lengthOfTreatment?.expectedTreatmentMax}`;
        },
        observedTreatmentDays() {
            return withDefault(this.condition.lengthOfTreatment?.observedTreatmentLength);
        },
        statusChipClass() {
            return this.condition.lengthOfTreatment?.endDate ? 'success-500--text success-50' : 'primary-a400--text primary-50';
        },
        statusChipText() {
            return this.condition.lengthOfTreatment?.endDate ? VisitLengthOfTreatmentStatus.COMPLETED : VisitLengthOfTreatmentStatus.ACTIVE;
        },
        hasLotPrediction() {
            return (
                this.condition.lengthOfTreatment?.expectedTreatmentMin != null &&
                this.condition.lengthOfTreatment.expectedTreatmentMin != VisitLengthOfTreatmentPredictionStatus.NO_PREDICTION &&
                this.condition.lengthOfTreatment?.expectedTreatmentMax != null &&
                this.condition.lengthOfTreatment.expectedTreatmentMax != VisitLengthOfTreatmentPredictionStatus.NO_PREDICTION
            );
        },
    },
    created() {
        this.observedCount = this.condition.lengthOfTreatment?.observedTreatmentLength || 0;
        this.expectedCount = this.condition.lengthOfTreatment?.expectedTreatmentMax || 0;

        if (this.condition.lengthOfTreatment?.expectedTreatmentMax === 0 && this.condition.lengthOfTreatment?.expectedTreatmentMin === 0) {
            this.expectedCount++;
        }

        this.showWarningChip =
            this.hasLotPrediction && this.observedCount != null && this.expectedCount != null && this.observedCount > this.expectedCount;
    },
    methods: {
        openDetails() {
            this.$emit('openDetails', this.condition);
        },
    },
});
