
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import Loadable from '@/components/shared/Loadable.vue';
import ClinicalSummarySavedListItem from '@/components/visit/clinical-summary/ClinicalSummarySavedListItem.vue';
import ClinicalSummarySentListItem from '@/components/visit/clinical-summary/ClinicalSummarySentListItem.vue';
import { ClinicalDocumentStatusType, ClinicalDocumentType } from '@/models';
import { getClinicalDocuments, getSavedClinicalDocuments } from '@/shared/queries';
import { ClinicalDocument, Maybe, Visit } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'ClinicalSummaryListView',
    components: {
        Loadable,
        FeatureToggle,
        ClinicalSummarySentListItem,
        ClinicalSummarySavedListItem,
    },
    data: () => ({
        loading: true,
        visit: undefined as Maybe<Visit> | undefined,
        clinicalSummaries: [] as ClinicalDocument[],
        selectedTabIndex: 0,
        savedClinicalSummaries: [] as ClinicalDocument[],
        ClinicalDocumentStatusType,
    }),
    computed: {
        sentClinicalSummariesCount(): number {
            return this.clinicalSummaries.length;
        },
        savedClinicalSummariesCount(): number {
            return this.savedClinicalSummaries.length;
        },
    },
    async created() {
        this.selectedTabIndex = +this.$route.params.tabIndex;
        await this.loadClinicalSummaries();
    },
    methods: {
        async loadClinicalSummaries() {
            try {
                const visitId = +this.$route.params.id;
                const sentDocumentsQuery = this.$apollo.query({
                    query: getClinicalDocuments,
                    variables: {
                        filter: {
                            visitId,
                            status: ClinicalDocumentStatusType.SENT,
                            type: ClinicalDocumentType.CLINICAL_SUMMARY,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                const savedDocumentsQuery = this.$apollo.query({
                    query: getSavedClinicalDocuments,
                    variables: {
                        filter: {
                            visitId,
                            status: ClinicalDocumentStatusType.SAVED,
                            type: ClinicalDocumentType.CLINICAL_SUMMARY,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });

                const [sentResponse, savedResponse] = await Promise.all([sentDocumentsQuery, savedDocumentsQuery]);

                this.clinicalSummaries = sentResponse.data.clinicalDocuments;
                this.savedClinicalSummaries = savedResponse.data.clinicalDocuments;
                this.loading = false;
            } catch (error) {
                console.error('Error loading clinical summaries:', error);
            }
        },
    },
});
