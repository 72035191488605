
import { getConditionConfidenceColor, getConditionConfidenceLevel, getFlooredPercentage } from '@/components/shared/utils';
import { ConfidenceLevels } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitConditionConfidence',
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
        confidenceLevels: {
            type: Object as PropType<ConfidenceLevels>,
            required: true,
        },
    },
    computed: {
        confidenceColor(): string {
            return getConditionConfidenceColor(this.confidenceLevel);
        },
        confidenceValue(): number {
            return getFlooredPercentage(this.value);
        },
        confidenceLevel(): string {
            return getConditionConfidenceLevel(this.value, this.confidenceLevels);
        },
        highConfidenceLevel(): string {
            return getFlooredPercentage(this.confidenceLevels.high) + ' - 100';
        },
        mediumConfidenceLevel(): string {
            return getFlooredPercentage(this.confidenceLevels.medium) + ' - ' + (getFlooredPercentage(this.confidenceLevels.high) - 1);
        },
        lowConfidenceLevel(): string {
            return getFlooredPercentage(this.confidenceLevels.low) + ' - ' + (getFlooredPercentage(this.confidenceLevels.medium) - 1);
        },
    },
    methods: {
        getConditionConfidenceColor,
    },
});
