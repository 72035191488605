
import { useInteractServiceStore } from '@/stores/InteractServiceStore';
import Vue from 'vue';

export default Vue.extend({
    name: 'QueryDrawerView',
    data: () => ({
        interactStore: useInteractServiceStore(),
    }),
});
