
import { formatDateTime, withDefault } from '@/components/shared/utils';
import DrawerHeader from '@/components/visit/drawer/DrawerHeader.vue';
import MicrobiologyDetailViewHeader from '@/components/visit/microbiology/MicrobiologyDetailViewHeader.vue';
import { DrawerType } from '@/models';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { MicrobiologyResult, Query } from 'generated/graphql/graphql';
import gql from 'graphql-tag';
import Vue from 'vue';

export default Vue.extend({
    name: 'MicrobiologyDrawerDetailView',
    components: {
        MicrobiologyDetailViewHeader,
        DrawerHeader,
    },
    props: {
        microbiologyId: { type: String, required: true },
    },
    data: () => ({
        microbiologyResult: undefined as MicrobiologyResult | undefined,
        DrawerType,
        visitDrawerStore: useVisitDrawerStore(),
    }),
    created() {
        this.$apollo
            .query<Query>({
                query: gql`
                    query TimelineMicrobiologyResult($id: Int!) {
                        microbiologyResult(id: $id) {
                            name
                            date
                            placerOrderNumber
                            orderedBy
                            specimenSource
                            status
                            report
                        }
                    }
                `,
                variables: {
                    id: parseInt(this.microbiologyId),
                },
                fetchPolicy: 'no-cache',
            })
            .then((response) => {
                if (response.data.microbiologyResult) {
                    this.microbiologyResult = response.data.microbiologyResult;
                }
            });
    },
    methods: {
        formatDateTime,
        withDefault,
    },
});
