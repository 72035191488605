
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import Vue from 'vue';

export default Vue.extend({
    name: 'PatientSummaryButton',
    components: {
        SideBarButton,
    },
    props: {
        expanded: Boolean,
        selected: Boolean,
    },
    methods: {
        displayVisit() {
            this.$router.push({ name: 'patientsummary', params: { id: this.$route.params.id } });
        },
    },
});
