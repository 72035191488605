
import VerticalField from '@/components/shared/VerticalField.vue';
import { defineComponent } from 'vue';
import VClamp from 'vue-clamp';

export default defineComponent({
    name: 'VisitChiefComplaint',
    components: {
        VClamp,
        VerticalField,
    },
    props: {
        chiefComplaint: {
            type: String,
            required: false,
            default: '',
        },
    },
});
