
import { CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement } from 'chart.js';
import Vue from 'vue';
import { Line as LineChart } from 'vue-chartjs/legacy';

Chart.register(CategoryScale, LinearScale, LineController, LineElement, PointElement);

export default Vue.extend({
    name: 'VisitConditionChart',
    components: {
        LineChart,
    },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    grid: {
                        drawOnChartArea: false,
                    },
                },
                y: {
                    display: false,
                    beginAtZero: true,
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
            elements: {
                line: {
                    borderWidth: 1,
                    tension: 0.4,
                },
                point: {
                    pointRadius: 2,
                },
            },
        },
    }),
});
