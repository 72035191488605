export enum AvoidableDayType {
    HOSPITAL_DELAY = 'Hospital Delay',
    PROVIDER_DELAY = 'Provider Delay',
    PLACEMENT_DELAY = 'Placement Delay',
    PAYER_AUTH_DELAY = 'Payer/Auth Delay',
    FAMILY_DELAY = 'Family Delay',
    GUARDIANSHIP_DELAY = 'Guardianship Delay',
    SOCIAL_DELAY = 'Social Delay',
    OTHER_DELAY = 'Other Delay',
}

export function getAvoidableDayTypeValueByKey(value: string): AvoidableDayType | undefined {
    return Object.entries(AvoidableDayType).find(([key]) => key === value)?.[1];
}

export function getAvoidableDayTypeKeyByValue(value: string | undefined): string | undefined {
    return Object.entries(AvoidableDayType).find(([, val]) => val === value)?.[0];
}

export enum AvoidableDayView {
    VIEW_ALL,
    EDIT,
    DELETE,
    ADD,
}
