export enum AdmitStatusConfidenceCategory {
    HIGH = 'High',
    MEDIUM = 'Medium',
    LOW = 'Low',
    NONE = '',
}

export type AdmitStatusWithConfidence = {
    admitStatus: string;
    confidencePercentage: number;
};

export const admitStatusConfidenceLevels = {
    high: 0.75,
    medium: 0.25,
    low: 0,
};

export enum PredictedAdmitStatus {
    INPATIENT = 'Inpatient',
    OBSERVATION = 'Observation',
    DISCHARGE = 'Discharge',
    ADMIT = 'Admit',
}

export enum AdmitStatus {
    INPATIENT = 'Inpatient',
    OBSERVATION = 'Observation',
    DISCHARGED = 'Discharged',
    EMERGENCY = 'Emergency',
    ADMIT = 'Admit',
}
