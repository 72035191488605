import gql from 'graphql-tag';

export const sendClinicalDocument = gql`
    mutation SendClinicalDocument($input: ClinicalDocumentSendInput!) {
        sendClinicalDocument(input: $input) {
            id
        }
    }
`;

export const reSendClinicalDocument = gql`
    mutation reSendClinicalDocument($input: ClinicalDocumentReSendInput!) {
        reSendClinicalDocument(input: $input) {
            id
        }
    }
`;

export const deleteClinicalDocument = gql`
    mutation DeleteClinicalDocument($input: ClinicalDocumentDeleteInput!) {
        deleteClinicalDocument(input: $input) {
            id
        }
    }
`;

export const addClinicalDocumentItem = gql`
    mutation AddClinicalDocumentItem($input: ClinicalDocumentItemInput!) {
        addClinicalDocumentItem(input: $input) {
            id
        }
    }
`;

export const deleteClinicalDocumentItem = gql`
    mutation DeleteClinicalDocumentItem($input: ClinicalDocumentItemInput!) {
        deleteClinicalDocumentItem(input: $input) {
            id
        }
    }
`;

export const saveClinicalDocumentNote = gql`
    mutation saveClinicalDocumentNote($input: ClinicalDocumentNoteInput!) {
        saveClinicalDocumentNote(input: $input) {
            id
        }
    }
`;

export const createReviewFeedback = gql`
    mutation CreateReviewFeedback($input: ReviewFeedbackInput!) {
        createReviewFeedback(input: $input) {
            id
        }
    }
`;

export const setNextReviewDate = gql`
    mutation SetNextReviewDate($input: NextReviewDateInput!) {
        setNextReviewDate(input: $input) {
            nextReviewDate
        }
    }
`;

export const addAvoidableDay = gql`
    mutation AddAvoidableDay($input: AvoidableDayAddInput!) {
        addAvoidableDay(input: $input) {
            avoidableDate
            category
            id
            reason
        }
    }
`;

export const editAvoidableDay = gql`
    mutation EditAvoidableDay($input: AvoidableDayEditInput!) {
        editAvoidableDay(input: $input) {
            avoidableDate
            category
            id
            reason
        }
    }
`;

export const deleteAvoidableDay = gql`
    mutation DeleteAvoidableDay($input: AvoidableDayDeleteInput!) {
        deleteAvoidableDay(input: $input) {
            id
        }
    }
`;

export const assignPayerRule = gql`
    mutation AssignPayerRule($input: PayerRuleAssignmentInput!) {
        assignPayerRule(input: $input)
    }
`;

export const assignFaxNumber = gql`
    mutation AssignFaxNumber($input: PayerFaxNumberAssignmentInput!) {
        assignFaxNumber(input: $input) {
            id
            faxNumber
        }
    }
`;

export const removeFaxNumber = gql`
    mutation RemoveFaxNumberAssignment($input: PayerFaxNumberAssignmentRemovalInput!) {
        removeFaxNumberAssignment(input: $input)
    }
`;

export const acknowledgeFailedFax = gql`
    mutation AcknowledgeFailedFax($visitId: Int!) {
        acknowledgeFailedFax(visitId: $visitId)
    }
`;

export const resetDemoData = gql`
    mutation ResetDemoData {
        resetDemoData
    }
`;

export const updateUserPreferences = gql`
    mutation UpdateUserPreferences($input: UserPreferencesInput!) {
        updateUserPreferences(input: $input)
    }
`;

export const completeFirstLevelReview = gql`
    mutation CompleteFirstLevelReview($visitId: Int!, $visitSnapshotId: Int!, $notes: String) {
        completeFirstLevelReview(visitId: $visitId, visitSnapshotId: $visitSnapshotId, notes: $notes) {
            id
        }
    }
`;

export const completeSecondLevelReview = gql`
    mutation CompleteSecondLevelReview($notes: String, $recommendedAdmitStatus: String!, $visitId: Int!, $visitSnapshotId: Int!) {
        completeSecondLevelReview(
            notes: $notes
            recommendedAdmitStatus: $recommendedAdmitStatus
            visitId: $visitId
            visitSnapshotId: $visitSnapshotId
        ) {
            id
        }
    }
`;

export const escalateFirstLevelReview = gql`
    mutation EscalateFirstLevelReview($visitId: Int!, $notes: String, $visitSnapshotId: Int!) {
        escalateFirstLevelReview(visitId: $visitId, notes: $notes, visitSnapshotId: $visitSnapshotId) {
            id
        }
    }
`;

export const startReview = gql`
    mutation StartReview($visitId: Int!) {
        createVisitSnapshot(visitId: $visitId) {
            id
        }
    }
`;

export const readVisitComment = gql`
    mutation ReadVisitComment($input: ReadVisitCommentInput!) {
        readVisitComment(input: $input)
    }
`;

export const saveVisitComment = gql`
    mutation SaveVisitComment($input: VisitCommentInput!) {
        saveVisitComment(input: $input) {
            id
            version
            creator {
                firstName
                lastName
            }
            createdDate
            modifiedDate
            comment
            escalatedTo {
                firstName
                lastName
            }
            reviewOutcome
            reviewRecommendedAdmitStatus
            originalComment {
                createdDate
            }
        }
    }
`;

export const assignVisitOwner = gql`
    mutation AssignVisitOwner($userId: Int, $visitId: Int!, $role: String!) {
        assignVisitOwner(userId: $userId, visitId: $visitId, role: $role) {
            umNurseOwnerId
            physicianAdvisorOwnerId
        }
    }
`;

export const logoutUser = gql`
    mutation LogoutUser {
        logoutUser
    }
`;

export const setLocationScope = gql`
    mutation SetLocationScope($input: [LocationScopeInput!]!) {
        setLocationScope(input: $input)
    }
`;

export const setFacilityScope = gql`
    mutation setFacilityScope($input: [FacilityScopeInput!]!) {
        setFacilityScope(input: $input)
    }
`;

export const setPayerScope = gql`
    mutation setPayerScope($input: [PayerScopeInput!]!) {
        setPayerScope(input: $input)
    }
`;

export const setHospitalServiceScope = gql`
    mutation setHospitalServiceScope($input: [HospitalServiceScopeInput!]!) {
        setHospitalServiceScope(input: $input)
    }
`;
export const upsertPayerAuth = gql`
    mutation UpsertPayerAuth($input: PayerAuthInput!) {
        upsertPayerAuth(input: $input)
    }
`;
export const deletePayerAuth = gql`
    mutation deletePayerAuth($input: PayerAuthDeleteInput!) {
        deletePayerAuth(input: $input)
    }
`;
export const createReviewWithInteractQuery = gql`
    mutation CreateReviewWithInteractQuery($input: InteractQueryReviewInput!) {
        sendQuery(input: $input)
    }
`;
export const revokeReviewWithInteractQuery = gql`
    mutation RevokeReviewWithInteractQuery($input: InteractQueryReviewInput!) {
        revokeQuery(input: $input)
    }
`;

export const prepareVisitForInteractResponse = gql`
    mutation PrepareVisitForInteractResponse($visitId: Int!) {
        prepareVisitForInteractResponse(visitId: $visitId)
    }
`;

export const createDenial = gql`
    mutation CreateDenial($input: DenialInput!) {
        createDenial(input: $input) {
            id
            denialType
            dateReceived
            denialStartDate
            denialEndDate
            denialReasonDescription
            payerPhoneNumber
            creator {
                firstName
                lastName
            }
            status
            communicationMethod
            overturnAmount
        }
    }
`;
export const resolveDenial = gql`
    mutation ResolveDenial($input: DenialResolveInput!) {
        resolveDenial(input: $input) {
            resolvedDate
            overturnAmount
            appealMethod
            outcome
        }
    }
`;
export const setDenialEscalationQueryId = gql`
    mutation SetDenialEscalationQueryId($input: DenialActionInput!) {
        setDenialEscalationQueryId(input: $input)
    }
`;

export const appealDenial = gql`
    mutation AppealDenial($input: DenialActionInput!) {
        appealDenial(input: $input) {
            id
        }
    }
`;

export const setAppealLetterId = gql`
    mutation SetAppealLetterId($input: DenialActionInput!) {
        setAppealLetterId(input: $input) {
            id
        }
    }
`;

export const setDenialStatusToEscalated = gql`
    mutation SetEscalatedStatus($input: DenialActionInput!) {
        setEscalatedStatus(input: $input) {
            id
        }
    }
`;

export const saveClinicalDocument = gql`
    mutation saveClinicalDocument($input: ClinicalDocumentSaveInput!) {
        saveClinicalDocument(input: $input) {
            id
        }
    }
`;
