export enum InteractQueryTemplate {
    MEDICAL_NECESSITY = 'medical necessity - um',
    PEER_TO_PEER = 'peer to peer - um',
}

export enum InteractQueryStatus {
    PENDING = 'pending',
    REVOKED = 'revoked',
}

export enum InteractQueryKindValue {
    UM = 'utilization-management',
}

export interface InteractQuery {
    id: string | null;
    encounter: string | null;
    status: string | null;
    template: {
        id: string | null;
        name: string | null;
    };
    createdDate: string | null;
    sentDate: string | null;
    kind: string | null;
}
