
import ExplainableTextInput from '@/components/shared/ExplainableTextInput.vue';
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import {
    convertFromCompoundDivisionCode,
    groupMapOnCompoundDivisionCode,
    phoneNumberValidator,
    withDefault,
} from '@/components/shared/utils';
import { AdminSendMethodTab, ClinicalDocumentType } from '@/models';
import { assignFaxNumber, removeFaxNumber } from '@/shared/mutations';
import { FaxNumber, Mutation } from 'generated/graphql/graphql';
import _ from 'underscore';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AssignFaxNumberDialog',
    components: {
        ExplainableTextInput,
        FeatureToggle,
    },
    props: {
        disabled: Boolean,
        selectedPayerCodes: { type: Array<string>, required: true },
        selectedClinicalSummaryFaxNumbers: { type: Array<FaxNumber>, required: true },
        selectedAppealFaxNumbers: { type: Array<FaxNumber>, required: true },
    },
    data: () => ({
        autofocus: false,
        hover: false,
        selectedTabIndex: 0,
        isDialogOpen: false,
        payerCodes: [] as string[],
        faxNumbersClinicals: [] as FaxNumber[], // Separate array for Clinicals
        faxNumbersAppeals: [] as FaxNumber[], // Separate array for Appeals
        newFaxNumber: null as string | null,
        newFaxNumberCount: 0,
        faxNumbersToDelete: [] as string[],
        selectedPrimaryFaxNumbers: {
            [ClinicalDocumentType.CLINICAL_SUMMARY]: undefined as FaxNumber | undefined,
            [ClinicalDocumentType.APPEAL]: undefined as FaxNumber | undefined,
        },
        ClinicalDocumentType,
    }),
    computed: {
        getClinicalDocumentType(): ClinicalDocumentType {
            return AdminSendMethodTab[this.selectedTabIndex];
        },
        currentClinicalDocumentType(): ClinicalDocumentType {
            const mapping: ClinicalDocumentType[] = [ClinicalDocumentType.CLINICAL_SUMMARY, ClinicalDocumentType.APPEAL];
            const type = mapping[this.selectedTabIndex];
            return type || ClinicalDocumentType.CLINICAL_SUMMARY;
        },
        currentFaxNumbers(): FaxNumber[] {
            return this.currentClinicalDocumentType === ClinicalDocumentType.APPEAL ? this.faxNumbersAppeals : this.faxNumbersClinicals;
        },
    },
    watch: {
        isDialogOpen(newVal) {
            if (newVal) {
                this.populateFaxNumbers(this.currentClinicalDocumentType);
                this.populatePayerCodes();
            }
        },
        selectedTabIndex(newIndex) {
            this.populateFaxNumbers(this.currentClinicalDocumentType);
        },
        selectedClinicalSummaryFaxNumbers(newVal) {
            if (!this.disabled && this.selectedTabIndex === 0) {
                this.populateFaxNumbers(ClinicalDocumentType.CLINICAL_SUMMARY);
            }
        },
        selectedAppealFaxNumbers(newVal) {
            if (!this.disabled && this.selectedTabIndex === 1) {
                this.populateFaxNumbers(ClinicalDocumentType.APPEAL);
            }
        },
    },
    created() {
        // Initialize only for the default selected tab
        //this.populateFaxNumbers(this.getClinicalDocumentType);
        this.populatePayerCodes();
    },
    methods: {
        withDefault,
        phoneNumberValidator,
        convertFromCompoundDivisionCode,
        groupMapOnCompoundDivisionCode,
        isValidFaxNumber(faxNumber: string) {
            return phoneNumberValidator(faxNumber) === true && faxNumber !== this.newFaxNumber;
        },
        displayDeleteButton(faxNumberToDelete: FaxNumber): boolean {
            //cannot delete the primary selected fax number
            const currentType = this.getClinicalDocumentType;
            return faxNumberToDelete.id !== this.selectedPrimaryFaxNumbers[currentType]?.id;
        },
        close(): void {
            // Reset state
            this.faxNumbersClinicals.splice(0, this.faxNumbersClinicals.length);
            this.faxNumbersAppeals.splice(0, this.faxNumbersAppeals.length);
            this.autofocus = false;
            this.isDialogOpen = false;
            this.selectedTabIndex = 0; // Reset to default tab

            // Reset selectedPrimaryFaxNumbers for all types
            Object.keys(this.selectedPrimaryFaxNumbers).forEach((typeKey) => {
                const type = typeKey as ClinicalDocumentType;
                this.$set(this.selectedPrimaryFaxNumbers, type, undefined);
            });
        },
        async removeFaxNumberAssignment() {
            const removalPromises = groupMapOnCompoundDivisionCode(this.payerCodes, (division, payerCodes) => {
                return this.$apollo.mutate<Mutation>({
                    mutation: removeFaxNumber,
                    variables: {
                        input: {
                            division,
                            faxNumbers: this.faxNumbersToDelete,
                            payerCodes,
                            type: this.getClinicalDocumentType,
                        },
                    },
                });
            });

            await Promise.all(removalPromises);
        },
        async saveAssignment() {
            // Validate all fax numbers
            const faxNumbersAreValid = this.currentFaxNumbers.every((item) => phoneNumberValidator(item.faxNumber) === true);
            if (!faxNumbersAreValid) {
                this.autofocus = true; // Autofocus on invalid field
                this.$toast.error('Oops! Something is not right');
                return;
            }

            // Remove any fax numbers marked for deletion
            if (this.faxNumbersToDelete.length > 0) {
                await this.removeFaxNumberAssignment(); // Await the asynchronous operation
            }

            const currentType = this.currentClinicalDocumentType;
            const selectedPrimary = this.selectedPrimaryFaxNumbers[currentType];

            // Ensure a primary fax number is selected
            if (!selectedPrimary) {
                this.$toast.error('Please select a primary fax number.');
                return;
            }

            // Construct the orders array as a parallel array to faxNumbers
            const orders = this.currentFaxNumbers.map((val, index) => {
                if (val.id === selectedPrimary.id) {
                    val.order = 1; // Primary fax number
                    return 1;
                } else {
                    val.order = index + 2; // Subsequent fax numbers
                    return index + 2;
                }
            });

            // Extract fax numbers to save
            const faxNumbersToSave = this.currentFaxNumbers.map((number) => number.faxNumber);
            const type = currentType;

            // Debugging: Log faxNumbers and orders
            // console.log('Fax Numbers to Save:', faxNumbersToSave);
            // console.log('Orders:', orders);

            // Perform the mutation for each division and payer code
            const faxNumberAssignmentsByDivision: Promise<any>[] = groupMapOnCompoundDivisionCode(
                this.payerCodes,
                (division, payerCodes) => {
                    return this.$apollo.mutate<Mutation>({
                        mutation: assignFaxNumber,
                        variables: {
                            input: {
                                faxNumbers: faxNumbersToSave,
                                division,
                                payerCodes,
                                orders, // Ensure orders array matches faxNumbers array
                                type,
                            },
                        },
                    });
                }
            );

            try {
                // Wait for all mutations to complete
                await Promise.all(faxNumberAssignmentsByDivision);
                this.$emit('mutate', this.currentFaxNumbers, type);
                this.$toast.success('Send Method successfully saved!');
                this.close();
            } catch (error) {
                this.$toast.error('Failed to save Send Method. Please try again.');
                console.error('Error saving fax numbers:', error);
            }
        },
        populateFaxNumbers(type: ClinicalDocumentType = ClinicalDocumentType.CLINICAL_SUMMARY) {
            const isAppealFax = type === ClinicalDocumentType.APPEAL;
            const fax = isAppealFax ? this.selectedAppealFaxNumbers : this.selectedClinicalSummaryFaxNumbers;
            const targetFaxNumbers = isAppealFax ? this.faxNumbersAppeals : this.faxNumbersClinicals;
            // Clear the target array
            targetFaxNumbers.splice(0, targetFaxNumbers.length);

            if (!fax || fax.length === 0) {
                this.newFaxNumberCount--;
                const newFax: FaxNumber = { id: this.newFaxNumberCount.toString(), faxNumber: this.newFaxNumber } as FaxNumber;
                targetFaxNumbers.push(newFax);
                // Set the newly added fax number as primary
                this.$set(this.selectedPrimaryFaxNumbers, type, newFax);
            } else {
                fax.forEach((faxNumber) => {
                    const faxNumberClone = _.clone(faxNumber);
                    targetFaxNumbers.push(faxNumberClone);
                    // Only set the primary if it hasn't been set by the user
                    if (faxNumber.order === 1 && !this.selectedPrimaryFaxNumbers[type]) {
                        this.$set(this.selectedPrimaryFaxNumbers, type, faxNumberClone);
                    }
                });
            }
        },
        populatePayerCodes() {
            this.payerCodes = [];
            this.selectedPayerCodes.forEach((payerCode) => this.payerCodes.push(payerCode));
        },
        async deleteFaxNumber(faxNumber: FaxNumber) {
            const currentType = this.currentClinicalDocumentType;

            if (
                (currentType === ClinicalDocumentType.CLINICAL_SUMMARY &&
                    this.selectedClinicalSummaryFaxNumbers.some((number) => number.faxNumber === faxNumber.faxNumber)) ||
                (currentType === ClinicalDocumentType.APPEAL &&
                    this.selectedAppealFaxNumbers.some((number) => number.faxNumber === faxNumber.faxNumber))
            ) {
                // Only perform delete mutation on numbers that were previously saved
                this.faxNumbersToDelete.push(faxNumber.faxNumber);
            }

            const targetFaxNumbers = currentType === ClinicalDocumentType.APPEAL ? this.faxNumbersAppeals : this.faxNumbersClinicals;
            const delIndex = targetFaxNumbers.findIndex((number) => number.id === faxNumber.id);
            if (delIndex !== -1) {
                targetFaxNumbers.splice(delIndex, 1);
            }

            // If the deleted fax number was the primary, unset the primary selection
            const selectedPrimary = this.selectedPrimaryFaxNumbers[currentType];
            if (selectedPrimary && selectedPrimary.id === faxNumber.id) {
                this.$set(this.selectedPrimaryFaxNumbers, currentType, undefined);
            }
        },
        addNew() {
            const currentType = this.currentClinicalDocumentType;
            const targetFaxNumbers = currentType === ClinicalDocumentType.APPEAL ? this.faxNumbersAppeals : this.faxNumbersClinicals;

            // All existing fields must be populated and valid to add new
            const faxNumbersAreValid = this.currentFaxNumbers.every((item) => phoneNumberValidator(item.faxNumber) === true);
            if (faxNumbersAreValid) {
                this.newFaxNumberCount--;
                const newFax: FaxNumber = { id: this.newFaxNumberCount.toString(), faxNumber: this.newFaxNumber } as FaxNumber;
                targetFaxNumbers.push(newFax);

                // Set the new fax number as primary
                this.$set(this.selectedPrimaryFaxNumbers, currentType, newFax);
            }
        },
    },
});
