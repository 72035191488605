
import Vue from 'vue';

export default Vue.extend({
    name: 'PDFBlobDisplay',
    props: {
        blob: Blob,
    },
    data: () => ({
        blobURL: undefined as string | undefined,
    }),
    created() {
        this.blobURL = URL.createObjectURL(this.blob);
    },
    destroyed() {
        // prevent memory leak
        if (this.blobURL) {
            URL.revokeObjectURL(this.blobURL);
        }
    },
});
