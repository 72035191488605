
import PayerConfigurations from '@/components/admin/payers/PayerConfigurations.vue';
import ScopingConfiguration from '@/components/admin/scoping/ScopingConfiguration.vue';
import TabbedPage from '@/components/shared/TabbedPage.vue';
import { AdminTab } from '@/models';
import { useFeatureStore } from '@/stores/FeatureStore';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AdminView',
    components: { PayerConfigurations, TabbedPage, ScopingConfiguration },
    data: () => ({
        AdminTab,
        tabs: [AdminTab.PAYER_CONFIGURATIONS],
        selectedTab: AdminTab.PAYER_CONFIGURATIONS,
        featureStore: useFeatureStore(),
    }),
    created() {
        this.addTabsForFeatureFlags();
        this.featureStore.$subscribe(() => {
            this.addTabsForFeatureFlags();
        });
    },
    methods: {
        addTabsForFeatureFlags() {
            const tabs = [AdminTab.PAYER_CONFIGURATIONS];
            if (this.featureStore.isEnabled('SCOPING_ADMIN')) {
                this.tabs = [...tabs, AdminTab.SCOPING];
            }
        },
    },
});
