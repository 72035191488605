
import { createReviewFeedback } from '@/shared/mutations';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useVisitStore } from '@/stores/VisitStore';
import { Mutation } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'ReviewFeedbackCard',
    props: {
        headerText: String,
        reviewId: String,
    },
    data: () => ({
        visitStore: useVisitStore(),
        featureStore: useFeatureStore(),
        agrees: undefined as boolean | undefined,
        expectedAdmitStatus: undefined as string | undefined,
        additionalThoughts: undefined as string | undefined,
    }),
    computed: {
        admitStatuses(): Array<string> {
            return ['Inpatient', 'Observation', 'Discharge', 'Other'].filter((status) => status !== this.predictedAdmitStatus);
        },
        predictedAdmitStatus(): string {
            return this.visitStore.visit?.predictedAdmitStatus ?? 'Not Available';
        },
        isFeatureEnabled(): boolean {
            return this.featureStore.isEnabled('REVIEW_FEEDBACK');
        },
    },
    created() {
        if (!this.isFeatureEnabled) {
            this.closeDialog();
        }
    },
    methods: {
        reset(): void {
            this.expectedAdmitStatus = undefined;
        },
        closeDialog(): void {
            this.$emit('close');
        },
        submit(): void {
            this.$apollo
                .mutate<Mutation>({
                    mutation: createReviewFeedback,
                    variables: {
                        input: {
                            reviewId: parseInt(this.reviewId),
                            agreed: this.agrees,
                            expectedAdmitStatus: this.expectedAdmitStatus,
                            additionalFeedback: this.additionalThoughts,
                        },
                    },
                    fetchPolicy: 'no-cache',
                })
                .then(() => {
                    this.closeDialog();
                    this.$toast.success('Thanks! We appreciate the feedback.');
                });
        },
    },
});
