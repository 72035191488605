
import { formatDate, formatTime } from '@/components/shared/utils';
import { DescribedItem, DescribedItemType } from '@/models';
import { VisitSummaryItem } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitSummaryMedicalConceptItemGroup',
    props: {
        item: {
            type: Object as PropType<VisitSummaryItem>,
            required: true,
        },
    },
    data: () => ({
        DescribedItemType,

        // This controls how many documents to display
        itemLimit: 3,

        // This controls how many text snippets to display
        textLimit: 2,
    }),
    methods: {
        formatDate,
        formatTime,
        openItem(item: VisitSummaryItem, child: DescribedItem) {
            this.$emit('openItem', item, child.id);
        },
    },
});
