import { SummaryTypeCode } from '@iodine/tgs-api-client-node';

export enum ClinicalDocumentStatusType {
    DRAFT = 'DRAFT',
    SENT = 'SENT',
    SAVED = 'SAVED',
}

export enum ClinicalDocumentType {
    CLINICAL_SUMMARY = 'CLINICAL_SUMMARY',
    APPEAL = 'APPEAL',
}

export enum ClinicalDocumentItemType {
    LAB_RESULT = 'lab_result',
    MEDICATION_ADMINISTRATION = 'medication_administration',
    MEDICATION_ORDER = 'medication_order',
    DOCUMENT_QUOTE = 'document_quote',
    DOCUMENT = 'document',
    MICROBIOLOGY = 'microbiology',
}

export interface DocumentQuoteInput {
    documentId: number;
    quote: string;
    startRange: number;
    endRange: number;
}

export interface MutateClinicalDocumentItem {
    documentId?: number;
    medicationAdministrationId?: number;
    medicationOrderId?: number;
    microbiologyResultId?: number;
    labVitalsResultIds?: [number];
    documentQuote?: DocumentQuoteInput;
}

export interface AddToClinicalDocumentInput {
    documentQuote?: DocumentQuoteInput;
    type: ClinicalDocumentItemType;
    ids: string | string[];
}

/**
 * This enum is necessary because GQL does not allow enums to have spaces in their names.
 * We need to pass to the backend "CLINICAL_SUMMARY" rather than "Clinical Summary" or GQL will complain loudly.
 * Using the GQL enum everywhere except where we display is advantageous over the alternative b/c
 * if we were to forget to map to the display type it's a visual bug that we're very likely to catch, won't harm functionality, and occurs less frequently overall.
 * If we were to forget to map the display type to the GQL type in every query it would break functionality, and we use the query more than we use the display.
 */
export enum ClinicalDocumentTypeDisplay {
    CLINICAL_SUMMARY = 'Clinical Summary',
    APPEAL = 'Appeal Letter',
}

/**
 * The ordering of the enum values below correspond to the ClinicalDocumentBuilder tab order and shoud not be changed unless the tab order change is intentional
 */
export enum DocumentBuilderTab {
    PAYER_NOTE,
    DOCUMENTATION,
    LAB_RESULTS,
    MEDICATIONS,
    MICROBIOLOGY,
}

export const clinicalSummaryTabs = [
    DocumentBuilderTab.PAYER_NOTE,
    DocumentBuilderTab.DOCUMENTATION,
    DocumentBuilderTab.LAB_RESULTS,
    DocumentBuilderTab.MEDICATIONS,
    DocumentBuilderTab.MICROBIOLOGY,
];

export const DocumentBuilderTabNames = new Map([
    [DocumentBuilderTab.PAYER_NOTE, 'Payer Note'],
    [DocumentBuilderTab.DOCUMENTATION, 'Documentation'],
    [DocumentBuilderTab.LAB_RESULTS, 'Lab Results'],
    [DocumentBuilderTab.MEDICATIONS, 'Medications'],
    [DocumentBuilderTab.MICROBIOLOGY, 'Microbiology'],
]);

export const appealLetterTabs = [DocumentBuilderTab.PAYER_NOTE, DocumentBuilderTab.DOCUMENTATION];

export const genAiSummaryTypeCodes: Record<ClinicalDocumentType, SummaryTypeCode> = {
    [ClinicalDocumentType.CLINICAL_SUMMARY]: SummaryTypeCode.PAYER_AUTHORIZATION,
    [ClinicalDocumentType.APPEAL]: SummaryTypeCode.DENIAL_APPEAL,
};

export const AdminSendMethodTab: ClinicalDocumentType[] = [ClinicalDocumentType.CLINICAL_SUMMARY, ClinicalDocumentType.APPEAL];

/**
 * The ordering of the enum values below correspond to the ClinicalSummaryHistory tab order and should not be changed unless the tab order change is intentional
 */
export enum ClinicalSummaryHistoryTab {
    SENT_TAB,
    SAVED_TAB,
}
