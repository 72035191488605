import {
    Facility,
    FacilityScopeInput,
    HospitalService,
    HospitalServiceScopeInput,
    Payer,
    PayerScopeInput,
} from 'generated/graphql/graphql';

export type Scopable = Payer | Facility | HospitalService;
export enum ScopingItem {
    FACILITY = 'Facility',
    SERVICE = 'Service',
    PAYER = 'Payer',
}
export type ScopingMap = Record<ScopingItem, Scopable[]>;

export type ScopingChange = FacilityScopeInput & PayerScopeInput & HospitalServiceScopeInput;
export type ScopingChangeMap = Record<ScopingItem, Record<string, boolean>>;
