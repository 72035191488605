
import moment from 'moment';
import Vue from 'vue';

export default Vue.extend({
    name: 'DatePicker',
    props: {
        value: String,
        label: String,
        textFieldClass: String,
        enforceFutureDate: Boolean,
    },
    data: () => ({
        menu: false,
    }),
    computed: {
        date: {
            get(): unknown {
                return this.value;
            },
            set(value: unknown) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        save(date: string): void {
            //@ts-ignore
            this.$refs.menu.save(date);
        },
        isFutureDateOrNull(): string | boolean {
            if (this.enforceFutureDate !== true) {
                return true;
            }
            if (moment().isAfter(moment(this.value), 'day')) {
                return 'Please select a date in the future';
            }
            return true;
        },
        allowedDates(val: string): boolean {
            if (!this.enforceFutureDate) {
                return true;
            }
            return moment(val, 'YYYY-MM-DD').isAfter(moment());
        },
    },
});
