
import ReadmissionButton from '@/components/shared/ReadmissionButton.vue';
import ReadmissionCard from '@/components/shared/ReadmissionCard.vue';
import { Visit } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'WorklistReadmissionButton',
    components: {
        ReadmissionButton,
        ReadmissionCard,
    },
    props: {
        visitId: String,
    },
    data: () => ({
        showCard: false,
        readmissions: undefined as Visit[] | undefined,
    }),
    methods: {
        close(): void {
            this.showCard = false;
        },
        show(visits: Visit[]): void {
            this.readmissions = visits;
        },
    },
});
