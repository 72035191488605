import { ConfidenceLevels } from 'generated/graphql/graphql';

export const getConditionConfidenceLevel = (value: number, levels: ConfidenceLevels): string => {
    if (value >= levels.high) {
        return 'High';
    } else if (value >= levels.medium) {
        return 'Medium';
    } else {
        return 'Low';
    }
};

export const getConditionConfidenceColor = (confidence: string): string => {
    switch (confidence) {
        case 'High':
            return 'success-500';
        case 'Medium':
            return 'warning-900';
        case 'Low':
            return 'information-700';
        default:
            return 'information-700';
    }
};
