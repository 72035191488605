
import { formatDate, pluralize } from '@/components/shared/utils';
import { Visit } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'AuthExpIcon',
    props: {
        visit: {
            type: Object as PropType<Visit>,
            required: true,
        },
    },
    computed: {
        getAuthDaysText() {
            return formatDate(this.visit.payerAuthorization?.startDate) + ' - ' + formatDate(this.visit.payerAuthorization?.endDate);
        },
        showDaysLeft() {
            if (this.visit.payerAuthorization == null) return '';
            const daysLeft = Number(this.visit.payerAuthorization?.authStatus);
            if (this.checkIfExpired) return '';
            return `: ${daysLeft} ` + pluralize('Day', daysLeft);
        },
        checkIfExpired() {
            const daysLeft = Number(this.visit.payerAuthorization?.authStatus);
            return daysLeft <= 0;
        },
    },
    methods: {
        formatDate,
    },
});
