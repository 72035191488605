
import PDFBlobDisplay from '@/components/shared/PDFBlobDisplay.vue';
import DeleteClinicalSummaryDialog from '@/components/visit/clinical-summary/DeleteClinicalSummaryDialog.vue';
import DrawerHeader from '@/components/visit/drawer/DrawerHeader.vue';
import { ClinicalDocumentType, DrawerType } from '@/models';
import { sendClinicalDocument } from '@/shared/mutations';
import { getClinicalDocumentDraftWithPDF } from '@/shared/queries';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import { FaxNumber, Maybe, Mutation, Query } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ClinicalSummaryConfirmationView',
    components: {
        DrawerHeader,
        PDFBlobDisplay,
        DeleteClinicalSummaryDialog,
    },
    props: {
        faxNumber: { type: Object as PropType<FaxNumber>, required: true },
        payerName: { type: String, required: true },
        visitId: { type: String, required: true },
        clinicalSummaryId: { type: String, required: true },
    },
    data: () => ({
        DrawerType,
        pendingMutation: false,
        visitDrawerStore: useVisitDrawerStore(),
        visitStore: useVisitStore(),
        blob: undefined as Blob | undefined,
        hash: null as Maybe<string> | undefined,
    }),
    computed: {
        enableSend(): boolean {
            return !!this.hash;
        },
    },
    created() {
        this.getPdf();
    },
    methods: {
        async sendSummary() {
            this.pendingMutation = true;
            try {
                await this.$apollo.mutate<Mutation>({
                    mutation: sendClinicalDocument,
                    variables: {
                        input: {
                            visitId: +this.visitId,
                            clinicalDocumentId: +this.clinicalSummaryId,
                            faxNumberId: +this.faxNumber.id,
                            hash: this.hash,
                        },
                    },
                });
                this.visitDrawerStore.closeDrawer();
                this.$toast.success('Your Clinical Summary has been sent!');
                this.visitStore.clinicalSummary = undefined;
            } catch (e) {
                this.$toast.error('Could not send clinical summary, please try again', { timeout: -1 });
            } finally {
                this.pendingMutation = false;
            }
        },
        async getPdf() {
            try {
                const response = await this.$apollo.query<Query>({
                    query: getClinicalDocumentDraftWithPDF,
                    variables: {
                        filter: {
                            visitId: +this.visitId,
                            type: ClinicalDocumentType.CLINICAL_SUMMARY,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                const pdfBytes = response.data.clinicalDocumentDraft.pdf.data;
                this.blob = new Blob([new Uint8Array(pdfBytes)], { type: 'application/pdf' });
                this.hash = response.data.clinicalDocumentDraft.hash;
            } catch (e) {
                this.$toast.error('Could not fetch clinical summary data, please refresh your browser', { timeout: -1 });
            }
        },
    },
});
