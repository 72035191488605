import { Routes } from '@/router/routes.enum';
import { useUserStore } from '@/stores/UserStore';
import { NavigationGuard } from 'vue-router';

export const adminAuthGuard: NavigationGuard = async (to, from, next) => {
    const userStore = useUserStore();
    if (!userStore.isInitialized) {
        await userStore.initialize();
    }

    if (userStore.isAdmin || userStore.isManager) {
        next();
    } else {
        next(Routes.WORKLIST);
    }
};

export const analyticsAuthGuard: NavigationGuard = async (to, from, next) => {
    const userStore = useUserStore();
    if (!userStore.isInitialized) {
        await userStore.initialize();
    }

    if (userStore.canViewAnalytics) {
        next();
    } else {
        next(Routes.WORKLIST);
    }
};
