/* eslint-disable  @typescript-eslint/no-explicit-any */
import { NULLABLE } from '@/components/shared/utils';
import { DescribedItem, DescribedItemType, DocumentItem, LabResultItem, MedicationOrderItem, MicrobiologyItem } from '@/models';

const isString = (s: any) => typeof s === 'string';
const isArray = (a: any) => Array.isArray(a);
const all = (items: any[], condition: Condition) => items.reduce((acc: boolean, curr) => acc && condition(curr), true);

type Condition = (item: any) => boolean;
const hasId: Condition = (item) => isString(item?.id);
const hasDate: Condition = (item) => isString(item?.date);
const hasText: Condition = (item) => isArray(item?.text) && all(item?.text, isString);
const hasName: Condition = (item) => isString(item?.name);
const hasValue: Condition = (item) => isString(item?.value);
const hasType: Condition = (item) => isString(item?.type);
const isType =
    (type: DescribedItemType): Condition =>
    (item) =>
        item.type === type;

const allConditionsMet = (conditions: Condition[], item: any) => conditions.reduce((acc, curr) => acc && curr(item), true);

const baseConditions = [hasId, hasType, hasDate];
export const checkCastDescribedItem = (item: any): NULLABLE<DescribedItem> => {
    const conditions = [...baseConditions];
    if (allConditionsMet(conditions, item)) return item as DescribedItem;

    return null;
};

export const checkCastDocumentItem = (item: any): NULLABLE<DocumentItem> => {
    const conditions = baseConditions.concat([hasName, hasText, isType(DescribedItemType.DOCUMENT)]);
    if (checkCastDescribedItem(item) && allConditionsMet(conditions, item)) return item as DocumentItem;

    return null;
};

export const checkCastLabResultItem = (item: any): NULLABLE<LabResultItem> => {
    const conditions = baseConditions.concat([hasValue, isType(DescribedItemType.LAB_RESULT)]);
    if (checkCastDescribedItem(item) && allConditionsMet(conditions, item)) return item as LabResultItem;

    return null;
};

export const checkCastMedicationOrderItem = (item: any): NULLABLE<MedicationOrderItem> => {
    const conditions = baseConditions.concat([isType(DescribedItemType.MEDICATION_ORDER)]);
    if (checkCastDescribedItem(item) && allConditionsMet(conditions, item)) return item as MedicationOrderItem;

    return null;
};

export const checkCastMicrobiologyItem = (item: any): NULLABLE<MicrobiologyItem> => {
    const conditions = baseConditions.concat([isType(DescribedItemType.MICROBIOLOGY)]);
    if (checkCastDescribedItem(item) && allConditionsMet(conditions, item)) return item as MicrobiologyItem;

    return null;
};
