
import Vue from 'vue';

export default Vue.extend({
    name: 'VerticalField',
    props: {
        className: {
            type: String,
            required: false,
            default: 'max-width-140p',
        },
    },
});
