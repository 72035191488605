import { render, staticRenderFns } from "./ResolveDenialDialog.vue?vue&type=template&id=736b7d0f&scoped=true&"
import script from "./ResolveDenialDialog.vue?vue&type=script&lang=ts&"
export * from "./ResolveDenialDialog.vue?vue&type=script&lang=ts&"
import style0 from "./ResolveDenialDialog.vue?vue&type=style&index=0&id=736b7d0f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "736b7d0f",
  null
  
)

export default component.exports