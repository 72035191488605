export class UserFunction {
    public static readonly UM_ANALYTICS_VIEWER = new UserFunction('UM_ANALYTICS_VIEWER', 'UM Analytics Viewer', 'umAnalyticsViewer');
    public static readonly UM_ANALYTICS_BUILDER = new UserFunction('UM_ANALYTICS_BUILDER', 'UM Analytics Builder', 'umAnalyticsBuilder');
    public static readonly TEXT_GENERATION = new UserFunction('TEXT_GENERATION', 'Text Generation', 'textGeneration');
    public static readonly UM_LOT_CONDITION = new UserFunction('UM_LOT_CONDITION', 'Lot Condition', 'umLotCondition');
    public static readonly UM_PROMINENT_AI = new UserFunction('UM_PROMINENT_AI', 'Prominent AI', 'umProminentAI');
    public static readonly UM_TIMELINE_DAILY_SUMMARIES = new UserFunction(
        'UM_TIMELINE_DAILY_SUMMARIES',
        'Timeline Daily Summaries',
        'umTimelineDailySummaries'
    );
    public static readonly UM_DEEP_LINKS = new UserFunction('UM_DEEP_LINKS', 'Deep Links', 'umDeepLinks');
    public static readonly UM_APPEALS_LETTERS = new UserFunction('UM_APPEALS_LETTERS', 'Appeals Letters', 'umAppealsLetters');

    private constructor(public readonly key: string, public readonly displayName: string, public readonly iogroup: string) {}

    // maps the keys from the enums above to a map to be easily extracted from the JWT Claim, refer to the constructor above
    private static IOGROUP_TO_USER_FUNCTION_MAP = new Map<string, UserFunction>(
        Object.entries(UserFunction)
            .filter((o) => o[1] instanceof UserFunction)
            .map((o) => [o[1].iogroup, o[1]])
    );

    static fromJwtClaim(iogroup: string): UserFunction | undefined {
        return UserFunction.IOGROUP_TO_USER_FUNCTION_MAP.get(iogroup);
    }
}
