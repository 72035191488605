
import { noAdultPredictedAdmitStatusDisclaimer, noChildPredictedAdmitStatusDisclaimer } from '@/components/shared/utils/model/Disclaimers';
import { ADULT_AGE } from '@/models/visit/visit-summary/VisitPatientAge.model';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NoPredictionIcon',
    props: {
        patientAge: {
            type: Number,
            required: false,
            default: undefined,
            validator: (value) => value === undefined || value === null || typeof value === 'number',
        },
    },
    computed: {
        effectivePatientAge(): number {
            return !this.patientAge ? ADULT_AGE : this.patientAge;
        },
        tooltipText(): string {
            return this.effectivePatientAge < ADULT_AGE ? noChildPredictedAdmitStatusDisclaimer : noAdultPredictedAdmitStatusDisclaimer;
        },
        iconName(): string {
            return this.effectivePatientAge < ADULT_AGE ? 'fa-light fa-hands-holding-child' : 'fa-light fa-hourglass-clock';
        },
        iconTestId(): string {
            return this.effectivePatientAge < ADULT_AGE ? 'no-prediction-icon-child' : 'no-prediction-icon-adult';
        },
    },
});
