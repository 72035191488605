
import { formatDate, formatPersonName, withDefault } from '@/components/shared/utils';
import { getVisitsBySearchText } from '@/shared/queries';
import { Query, Visit } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'SearchField',
    data: () => ({
        showField: false,
        searchText: '',
        results: [] as Visit[],
        timeout: undefined as NodeJS.Timeout | undefined,
        isLoading: false,
    }),
    methods: {
        withDefault,
        formatPersonName,
        formatDate,
        toggleField(): void {
            this.showField = !this.showField;
            if (!this.showField) this.reset();
        },
        debounceSearch(): void {
            this.isLoading = true;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(async () => {
                await this.search();
                this.isLoading = false;
            }, 500);
        },
        async search() {
            if (this.searchText.trim().length < 2) {
                this.results = [];
                return;
            }

            const response = await this.$apollo.query<Query>({
                query: getVisitsBySearchText,
                variables: {
                    searchText: this.searchText,
                },
                fetchPolicy: 'no-cache',
            });
            this.results = response.data.visitsBySearchText;
        },
        reset(): void {
            this.searchText = '';
            this.results = [];
        },
        openVisit(id: string): void {
            this.$router.push({ name: 'patientsummary', params: { id: id } });
            this.toggleField();
        },
        includedElements() {
            return [document.querySelector('.no-close-on-click')];
        },
    },
});
