
import { formatDate, formatDateTime, getAdmitStatusConfidenceColor, getRoundedPercentage } from '@/components/shared/utils';
import { CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement, Tooltip } from 'chart.js';
import { AdmitStatusPrediction } from 'generated/graphql/graphql';
import Vue from 'vue';
import { Line as LineChart } from 'vue-chartjs/legacy';
const getActiveConfidence = (pred: AdmitStatusPrediction): number => {
    let confidence = null;
    if (pred.inpatientConfidence) confidence = pred.inpatientConfidence;
    if (pred.observationConfidence) confidence = pred.observationConfidence;
    if (pred.admitConfidence) confidence = pred.admitConfidence;
    return confidence || 0;
};

Chart.register(CategoryScale, LinearScale, LineController, LineElement, PointElement, Tooltip);

export default Vue.extend({
    name: 'VisitPredictedStatusHistoryChart',
    components: {
        LineChart,
    },
    props: {
        predictions: {
            type: Array<AdmitStatusPrediction>,
            required: true,
        },
    },
    computed: {
        chartOptions() {
            const dates = this.predictions.map((prediction) => prediction.datePredicted);
            const dataPoints = this.predictions.map((pred) => getRoundedPercentage(getActiveConfidence(pred)));
            return {
                responsive: false,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            drawOnChartArea: false,
                        },
                        gridLines: {
                            display: true,
                        },
                        ticks: {
                            callback: (index: any) => {
                                return formatDate(dates[index]);
                            },
                            maxRotation: 0,
                            minRotation: 0,
                            color: '#3E4C59',
                            font: {
                                size: 10,
                                family: 'Helvetica Neue',
                            },
                        },
                    },
                    y: {
                        display: true,
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                        ticks: {
                            display: false,
                            stepSize: 25,
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        display: true,
                        xAlign: 'right',
                        caretSize: 0,
                        displayColors: false,
                        backgroundColor: '#3e4c59',
                        titleAlign: 'center',
                        titleFont: {
                            font: 'Helvetica Neue',
                            weight: 'normal',
                        },
                        titleColor: '#CBD2D9',
                        bodyFont: {
                            font: 'Helvetica Neue',
                            weight: 'bold',
                        },
                        bodyAlign: 'center',
                        callbacks: {
                            label: (context: any) => {
                                return dataPoints[context.dataIndex] + '% Confidence';
                            },
                            title: (context: any) => {
                                return formatDateTime(dates[context[0].dataIndex]);
                            },
                        },
                    },
                },
                elements: {
                    line: {
                        borderWidth: 1,
                        tension: 0.4,
                    },
                    point: {
                        pointRadius: 2,
                    },
                },
            };
        },
        chartData() {
            const labels = this.predictions.map((prediction) => formatDate(prediction.datePredicted));
            const dataPoints = this.predictions.map((pred) => getRoundedPercentage(getActiveConfidence(pred)));
            const colors = this.predictions.map((pred) => {
                return getAdmitStatusConfidenceColor(getActiveConfidence(pred), true);
            });
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: 'Confidence Levels',
                        data: dataPoints,
                        fill: false,
                        pointBackgroundColor: colors,
                        tension: 0.1,
                    },
                ],
            };
            return data;
        },
    },
    watch: {
        predictions() {
            this.$forceUpdate();
        },
    },
    methods: {
        formatDate,
        formatDateTime,
        getActiveConfidence,
        getRoundedPercentage,
    },
});
