
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'TabbedPage',
    props: {
        tabs: {
            type: Array as PropType<string[]>,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return { selectedTab: this.value };
    },
});
