
import Loadable from '@/components/shared/Loadable.vue';
import { addClinicalDocumentItem } from '@/shared/mutations';
import { getMetaDataForTimelineDocuments } from '@/shared/queries';
import { useVisitStore } from '@/stores/VisitStore';
import { ClinicalDocument, Document, Mutation, Query } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';
import { formatDate, formatTime, pluralize, withDefault } from '../../shared/utils';

export default Vue.extend({
    name: 'AddDocumentsToAppealDialog',
    components: { Loadable },
    props: {
        documents: Array<Document>,
        clinicalDocument: {
            type: Object as PropType<ClinicalDocument>,
            required: true,
        },
    },
    data: () => ({
        showCard: false,
        selected: [] as number[],
        allRemainingDocuments: [] as Document[],
        isLoading: true,
        visitStore: useVisitStore(),
        isAddDocumentEnabled: false,
    }),
    methods: {
        formatTime,
        formatDate,
        withDefault,
        enableAddDocument() {
            if (this.selected.length === 0) this.isAddDocumentEnabled = false;
            else this.isAddDocumentEnabled = true;
        },
        close(): void {
            this.showCard = false;
        },
        async addDocument() {
            this.isAddDocumentEnabled = false;
            const selectedDocuments = this.allRemainingDocuments.filter((doc) => this.selected.includes(parseInt(doc.id)));
            if (!this.visitStore.visit) {
                console.error('No visit in store');
                return;
            }
            await this.$apollo.mutate<Mutation>({
                mutation: addClinicalDocumentItem,
                variables: {
                    input: {
                        visitId: +this.visitStore.visit.id,
                        clinicalDocumentId: +this.clinicalDocument.id,
                        documentIds: this.selected,
                    },
                },
            });
            const docMsg = pluralize('Document', this.selected.length);
            this.$toast.success(`${docMsg} Added to Appeal Letter`);

            this.$emit('addDocuments', selectedDocuments);
            selectedDocuments.forEach((e) => this.documents?.push(e));
            this.allRemainingDocuments = this.allRemainingDocuments.filter((document) => !this.selected.includes(parseInt(document.id)));
            this.selected = [];
            this.showCard = false;
        },
        async loadAllDocuments() {
            const response = await this.$apollo.query<Query>({
                query: getMetaDataForTimelineDocuments,
                variables: {
                    filter: {
                        visitId: parseInt(this.$route.params.id),
                    },
                },
                fetchPolicy: 'no-cache',
            });
            const allDocuments = response.data.timelineDocuments?.items || [];
            const selectedDocumentIds = this.documents.map((document) => document.id);
            this.allRemainingDocuments = allDocuments.filter((document) => !selectedDocumentIds.includes(document.id));
            this.selected = [];
            this.isLoading = false;
        },
    },
});
