
import { properCase } from '@/components/shared/utils';
import { FaxRequestStatus } from '@/models';
import Vue from 'vue';

export default Vue.extend({
    name: 'FaxRequestStatusIcon',
    props: {
        status: String,
    },
    data: () => ({
        FaxRequestStatus,
    }),
    methods: {
        properCase,
    },
});
