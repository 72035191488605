import { Location, VisitLocation } from 'generated/graphql/graphql';
import moment from 'moment';
import { NULLABLE } from '.';
export const properCase = (str: NULLABLE<string>): string => {
    return (
        str
            ?.split('_')
            ?.map((frag) => frag.charAt(0).toUpperCase() + frag.slice(1).toLowerCase())
            ?.join(' ') ?? ''
    );
};

export const withDefault = (value: string | number | boolean | undefined | null): string => {
    return value?.toString() || '--';
};

export const formatPersonName = (person: any): string => {
    let name = '';
    if (person) {
        if (person.lastName) name += person.lastName + ', ';
        if (person.firstName) name += person.firstName;
        if (person.middleName) name += ' ' + person.middleName.charAt(0);
    }
    return withDefault(name);
};

export const formatFacility = (location: VisitLocation): string => {
    return withDefault(location.facility?.name);
};

export const formatLocation = (location: VisitLocation): string => {
    let result = '';
    if (location.poc?.name) result += location.poc.name;
    if (result && location.room) result += '-' + location.room;
    if (result && location.bed) result += ', Bed ' + location.bed;
    return withDefault(result);
};

export const formatFacilityLocation = (location: Location): string => `${location.facility.name}: ${location.name}`;

export const formatDate = (date: NULLABLE<string | Date>): string | undefined => {
    return date ? moment(date).format('MM/DD/YY') : undefined;
};
export const formatDateWithFullYear = (date: NULLABLE<string | Date>, format = 'MM/DD/YYYY'): string | undefined => {
    return date ? moment(date).format(format) : undefined;
};

export const formatISODate = (date: NULLABLE<string | Date>): string | undefined => {
    return date ? moment(date).format('YYYY-MM-DDTHH:mm:ssZ') : undefined;
};
export const formatISODateUTC = (date: NULLABLE<string | Date>): string | undefined => {
    return date ? moment(date, 'MM/DD/YY').utc().startOf('day').toISOString() : undefined;
};

export const formatTime = (date: NULLABLE<string | Date>): string | undefined => {
    return date ? moment(date).format('HH:mm') : undefined;
};

export const formatDateTime = (
    date: NULLABLE<string | Date>,
    withComma = true,
    withDOW = false,
    nullDefault: string | undefined = undefined
): string | undefined => {
    const prefix = withDOW ? 'ddd, ' : '';
    const delimiter = withComma ? ',' : '';
    return date ? moment(date).format(`${prefix}MM/DD/YY${delimiter} HH:mm`) : nullDefault;
};

export const pluralize = (word: string, count: number): string => {
    // we're either gonna need to use a library for this or think more in depth about how this should work
    /*
        words this won't pluralize correctly (darn english!):
            donkey -> donkies
            key -> keys
            guy -> guys
    */
    const wordsThatEndWithYPluralized = new Set(['day', 'Day']);
    const isPlural = count !== 1;
    if (!isPlural) return word;

    if (word.endsWith('y') && !wordsThatEndWithYPluralized.has(word)) {
        return word.slice(0, word.length - 1) + 'ies';
    } else {
        return word + 's';
    }
};

export const formatPhoneNumber = (faxNumber: NULLABLE<string>): string => faxNumber ?? '';

export const abbreviateAdmitStatus = (value: NULLABLE<string>): string => {
    switch (value) {
        case 'Inpatient':
            return 'Inpt';
        case 'Observation':
            return 'Obs';
        case 'Emergency':
            return 'Emerg';
        case 'Discharge':
        case 'Discharged':
            return 'Disch';
        case 'Preadmit':
            return 'Pre';
        case 'Day Surgery':
            return 'Day';
        case 'Outpatient':
            return 'Out';
        case 'Admit':
            return 'Admit';
        case null:
        case undefined:
            return '--';
        default:
            console.log('Unknown admit status: ' + value);
            return value.substring(0, 3);
    }
};
