
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import { useVisitStore } from '@/stores/VisitStore';
import Vue from 'vue';

export default Vue.extend({
    name: 'ClinicalSummaryHistoryButton',
    components: {
        SideBarButton,
    },
    props: {
        expanded: Boolean,
        selected: Boolean,
    },
    data: () => ({
        showFailedFaxBadge: false,
        visitStore: useVisitStore(),
    }),
    created() {
        this.visitStore.$subscribe(() => {
            this.showFailedFaxBadge = this.visitStore.visit?.lastFaxRequestFailed ?? false;
        });
    },
    methods: {
        displayList() {
            this.$router.push({ name: 'clinicalsummarylist', params: { id: this.$route.params.id } });
        },
    },
});
