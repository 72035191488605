
import { useFeatureStore } from '@/stores/FeatureStore';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FeatureToggle',
    props: {
        flag: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        featureStore: useFeatureStore(),
    }),
    computed: {
        isFeatureEnabled(): boolean {
            return this.featureStore.isEnabled(this.flag);
        },
    },
});
