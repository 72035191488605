import { AdmitStatusConfidenceCategory, admitStatusConfidenceLevels } from '@/models';
import IodineColors from '@/styles/colors';
import { ConfidenceLevels } from 'generated/graphql/graphql';

export const getAdmitStatusConfidenceLevel = (value: number, levels: ConfidenceLevels): AdmitStatusConfidenceCategory => {
    if (value >= levels.high) {
        return AdmitStatusConfidenceCategory.HIGH;
    } else if (value >= levels.medium) {
        return AdmitStatusConfidenceCategory.MEDIUM;
    } else if (value < levels.medium && value >= 0.001) {
        return AdmitStatusConfidenceCategory.LOW;
    } else {
        return AdmitStatusConfidenceCategory.NONE;
    }
};

export const getAdmitStatusConfidenceColor = (confidence: number, isHex = false): string => {
    const level = getAdmitStatusConfidenceLevel(confidence, admitStatusConfidenceLevels);
    switch (level) {
        case AdmitStatusConfidenceCategory.HIGH:
            return isHex ? IodineColors['success-500'] : 'success-500';
        case AdmitStatusConfidenceCategory.MEDIUM:
            return isHex ? IodineColors['warning-900'] : 'warning-900';
        case AdmitStatusConfidenceCategory.LOW:
            return isHex ? IodineColors['information-700'] : 'information-700';
        default:
            return '';
    }
};
