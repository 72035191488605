
import { formatDateTime } from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import { Visit } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'AdmitDates',
    components: {
        VerticalField,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            default: () => ({} as Visit),
            required: true,
        },
    },
    methods: {
        formatDateTime,
    },
});
