
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ExplainableTextInput',
    props: {
        propertyName: String,
        placeholder: String,
        name: String,
        explanation: String,
        unit: String,
        rules: Array,
        value: String,
        autofocus: {
            type: Boolean,
            required: false,
            default: false,
        },
        validateOnBlur: {
            type: Boolean,
            required: false,
            default: false,
        },
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(val: string) {
                this.$emit('input', val);
            },
        },
    },
});
