
import DatePicker from '@/components/shared/DatePicker.vue';
import { AvoidableDayType, getAvoidableDayTypeKeyByValue } from '@/models';
import { addAvoidableDay } from '@/shared/mutations';
import { useVisitStore } from '@/stores/VisitStore';
import { Mutation } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'AddAvoidableDayCard',
    components: {
        DatePicker,
    },
    data: () => ({
        AvoidableDayType,
        category: null as AvoidableDayType | null,
        avoidableDate: null as string | null,
        reason: null,
        placeholderText: 'Select Category*',
        visitStore: useVisitStore(),
    }),
    computed: {
        isCompleted() {
            return this.category !== null && this.avoidableDate !== null;
        },
    },
    methods: {
        clearPlaceholderText(): void {
            this.placeholderText = '';
        },
        close() {
            this.$emit('close');
        },
        goBack() {
            this.$emit('goBack');
        },
        async addNewAvoidableDay() {
            const response = await this.$apollo.mutate<Mutation>({
                mutation: addAvoidableDay,
                variables: {
                    input: {
                        visitId: +this.$route.params.id,
                        visitSnapshotId: +(await this.visitStore.visitSnapshotId),
                        category: getAvoidableDayTypeKeyByValue(this.category ?? ''),
                        reason: this.reason,
                        avoidableDate: this.avoidableDate,
                    },
                },
            });
            this.$toast.success('Avoidable Day Added');
            this.$emit('addedDay', response.data?.addAvoidableDay);
            this.goBack();
        },
    },
});
