
import { properCase } from '@/components/shared/utils';
import { useVisitTimelineFiltersStore } from '@/stores/VisitTimelineFiltersStore';
import Vue from 'vue';

export default Vue.extend({
    name: 'VisitTimelineFilters',
    data: () => ({
        visitTimelineFilterStore: useVisitTimelineFiltersStore(),
        show: false,
        disableResetButton: true,
    }),
    created() {
        this.visitTimelineFilterStore.$subscribe(() => {
            const values = Object.values(this.visitTimelineFilterStore.filters);
            for (let i = 0; i < values.length; i++) {
                if (values.at(i)?.length) {
                    this.disableResetButton = false;
                    break;
                }
                this.disableResetButton = true;
            }
        });
    },
    methods: {
        properCase,
        close(): void {
            this.show = false;
        },
        toggleMenu(): void {
            this.show = !this.show;
        },
        toggleItem(selection: string, value: string) {
            const valueIndex = this.visitTimelineFilterStore.filters[selection].indexOf(value);
            const arrCopy = [...this.visitTimelineFilterStore.filters[selection]];

            if (valueIndex > -1) {
                arrCopy.splice(valueIndex, 1);
            } else {
                arrCopy.push(value);
            }
            this.visitTimelineFilterStore.$patch({
                filters: {
                    ...this.visitTimelineFilterStore.filters,
                    [selection]: arrCopy,
                },
            });

            this.visitTimelineFilterStore.potentialFilters = { ...this.visitTimelineFilterStore.potentialFilters };
        },
        toggleCategory(selection: string) {
            const existingSelections = this.visitTimelineFilterStore.filters[selection];
            let newSelection = [] as string[];
            if (existingSelections?.length < this.visitTimelineFilterStore.potentialFilters[selection]?.length) {
                newSelection = [...this.visitTimelineFilterStore.potentialFilters[selection]];
            }
            this.visitTimelineFilterStore.$patch({
                filters: {
                    ...this.visitTimelineFilterStore.filters,
                    [selection]: newSelection,
                },
            });
            this.visitTimelineFilterStore.potentialFilters = { ...this.visitTimelineFilterStore.potentialFilters };
        },
    },
});
