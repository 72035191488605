import { deletePayerAuth, upsertPayerAuth } from '@/shared/mutations';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { Mutation } from 'generated/graphql/graphql';
import Vue from 'vue';
import { formatDateWithFullYear } from '../shared/utils';

export class AuthorizationDetailsManager {
    visitId = '';
    authNumber: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;

    initialAuthNumber: string | undefined;
    initialStartDate: string | undefined;
    initialEndDate: string | undefined;

    public initialize(visitId: string, authNumber: string | undefined, startDate: string | undefined, endDate: string | undefined): void {
        this.visitId = visitId;
        this.authNumber = authNumber;
        this.startDate = startDate;
        this.endDate = endDate;
        this.reset();
    }

    reset(): void {
        this.initialAuthNumber = this.authNumber;
        this.initialStartDate = this.startDate;
        this.initialEndDate = this.endDate;
    }
    public updateAuthNumber(authNumber: string): void {
        this.authNumber = authNumber;
    }
    public async saveAuthNumber(): Promise<void> {
        await this.saveAuthDetails();
    }
    public updateDates(startDate: string | undefined, endDate: string | undefined): void {
        this.startDate = formatDateWithFullYear(startDate) ?? '';
        this.endDate = formatDateWithFullYear(endDate) ?? '';
    }
    public async saveDates(startDate: string | undefined, endDate: string | undefined): Promise<void> {
        this.startDate = formatDateWithFullYear(startDate) ?? '';
        this.endDate = formatDateWithFullYear(endDate) ?? '';
        await this.saveAuthDetails();
    }

    async saveAuthDetails(): Promise<void> {
        if (!this.authNumber) {
            if (this.initialAuthNumber) {
                await this.deleteAuth();
            }
            this.initialize(this.visitId, undefined, undefined, undefined);
        } else {
            await this.updateAuthDetails();
            this.reset();
        }
    }

    async deleteAuth(): Promise<void> {
        const apolloClient: ApolloClient<NormalizedCacheObject> = Vue.prototype.$apolloClient;
        await apolloClient.mutate<Mutation>({
            mutation: deletePayerAuth,
            variables: {
                input: {
                    visitId: +this.visitId,
                    authNumber: this.initialAuthNumber,
                },
            },
        });
    }

    async updateAuthDetails(): Promise<void> {
        const apolloClient: ApolloClient<NormalizedCacheObject> = Vue.prototype.$apolloClient;
        await apolloClient.mutate<Mutation>({
            mutation: upsertPayerAuth,
            variables: {
                input: {
                    visitId: +this.visitId,
                    authNumber: this.authNumber,
                    startDate: this.startDate,
                    endDate: this.endDate,
                },
            },
        });
    }
}
