export enum ReviewType {
    FIRST_LEVEL = 'FIRST_LEVEL',
    SECOND_LEVEL = 'SECOND_LEVEL',
    INTERACT_SLR = 'INTERACT_SLR',
    PEER_TO_PEER = 'PEER_TO_PEER',
}

export enum ReviewOutcome {
    COMPLETE = 'COMPLETE',
    ESCALATE = 'ESCALATE',
}
