export class Role {
    public static readonly ADMIN = new Role('ADMIN', 'Admin', 'umAdmin');
    public static readonly UM_MANAGER = new Role('UM_MANAGER', 'UM Manager', 'umManager');
    public static readonly UM_NURSE = new Role('UM_NURSE', 'UM Nurse', 'umNurse');
    public static readonly PHYSICIAN_ADVISOR = new Role('PHYSICIAN_ADVISOR', 'Physician Advisor', 'physicianAdvisor');

    private constructor(public readonly key: string, public readonly displayName: string, public readonly iogroup: string) {}

    private static IOGROUP_TO_ROLE_MAP = new Map<string, Role>(
        Object.entries(Role)
            .filter((o) => o[1] instanceof Role)
            .map((o) => [o[1].iogroup, o[1]])
    );

    static fromJwtClaim(iogroup: string): Role | undefined {
        return Role.IOGROUP_TO_ROLE_MAP.get(iogroup);
    }
}
