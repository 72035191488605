
import { formatDate, withDefault } from '@/components/shared/utils';
import { MedicationOrder } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'MedicationAdministrationDetailsButton',
    props: {
        medicationOrder: Object as PropType<MedicationOrder>,
    },
    data: () => ({
        showTable: false,
    }),
    methods: {
        withDefault,
        formatDate,
    },
});
