
import PDFBlobDisplay from '@/components/shared/PDFBlobDisplay.vue';
import { formatDate, formatDateTime, formatTime, withDefault } from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import DrawerHeader from '@/components/visit/drawer/DrawerHeader.vue';
import { getClinicalDocument } from '@/shared/queries';
import { useVisitStore } from '@/stores/VisitStore';
import { ClinicalDocument, Denial, FaxRequest, Query } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'ViewAppealLetter',
    components: {
        PDFBlobDisplay,
        VerticalField,
        DrawerHeader,
    },
    props: {
        clinicalDocumentId: { type: String, required: true },
    },
    data: () => ({
        clinicalSummary: {} as ClinicalDocument,
        blob: undefined as Blob | undefined,
        denial: undefined as Denial | undefined,
        visitStore: useVisitStore(),
    }),
    computed: {
        fax() {
            return this.clinicalSummary.fax ?? ({} as FaxRequest);
        },
        faxNumber() {
            return this.clinicalSummary.fax?.faxNumber;
        },
        createdDate() {
            return this.clinicalSummary?.createdDate;
        },
        primaryPayerName() {
            return this.visitStore?.visit?.primaryPayer?.name;
        },
    },
    async created() {
        await this.loadClinicalSummary();
    },
    methods: {
        formatDate,
        formatTime,
        withDefault,
        formatDateTime,
        async loadClinicalSummary() {
            const response = await this.$apollo.query<Query>({
                query: getClinicalDocument,
                variables: {
                    filter: {
                        visitId: +this.$route.params.id,
                        clinicalDocumentId: +this.clinicalDocumentId,
                    },
                },
                fetchPolicy: 'no-cache',
            });

            this.clinicalSummary = response.data.clinicalDocument;
            const pdfBytes = response.data.clinicalDocument.fax?.pdf.data;
            this.blob = new Blob([new Uint8Array(pdfBytes)], { type: 'application/pdf' });
        },
    },
});
