
import DrawerBackButton from '@/components/visit/drawer/DrawerBackButton.vue';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import Vue from 'vue';

export default Vue.extend({
    name: 'DrawerHeader',
    components: { DrawerBackButton },
    props: {
        chipText: String,
        additionalText: String,
        dark: Boolean,
        large: Boolean,
        shrink: Boolean,
    },
    data: () => ({
        visitDrawerStore: useVisitDrawerStore(),
    }),
    computed: {
        darkMode(): boolean {
            return this.dark || !!this.visitDrawerStore?.drawerProps?.dark;
        },
        largeMode(): boolean {
            return this.large || !!this.visitDrawerStore?.drawerProps?.large;
        },
        title(): string {
            return this.visitDrawerStore?.drawerProps?.header?.title ?? this.visitDrawerStore.drawer?.toString() ?? '';
        },
    },
});
