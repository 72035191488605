
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import Loadable from '@/components/shared/Loadable.vue';
import { formatDate, formatDateTime, optionalChain, withDefault } from '@/components/shared/utils';
import VisitConditionConfidence from '@/components/visit/conditions/VisitConditionConfidence.vue';
import VisitConditionDetailsSection from '@/components/visit/conditions/VisitConditionDetailsSection.vue';
import { VisitConditionDataSection, VisitConditionDetails } from '@/models';
import VisitLengthOfTreatmentStatus from '@/models/visit/length-of-treatment/VisitLengthOfTreatmentStatus.model';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { TreatmentDetails } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'VisitConditionDetailsView',
    components: {
        VisitConditionConfidence,
        VisitConditionDetailsSection,
        FeatureToggle,
        Loadable,
    },
    props: {
        condition: {
            type: Object as PropType<VisitConditionDetails>,
            default: () => ({} as VisitConditionDetails),
            required: true,
        },
        tab: String,
        section: {
            type: Object as PropType<VisitConditionDataSection>,
            default: () => ({} as VisitConditionDataSection),
            required: true,
        },
    },
    data: () => ({
        selectedTab: '',
        selectedSection: {} as VisitConditionDataSection,
        showTreatmentDetails: false,
        featureStore: useFeatureStore(),
        userStore: useUserStore(),
        completedText: VisitLengthOfTreatmentStatus.COMPLETED,
        activeText: VisitLengthOfTreatmentStatus.ACTIVE,
    }),
    computed: {
        conditionStart() {
            return withDefault(formatDate(this.condition.lengthOfTreatment?.startDate));
        },
        conditionEnd() {
            return withDefault(formatDate(this.condition.lengthOfTreatment?.endDate));
        },
        showLengthOfTreatment() {
            return this.featureStore.isEnabled('LENGTH_OF_TREATMENT') && this.userStore.canViewLotCondition;
        },
        expectedTreatmentDays() {
            if (
                this.condition.lengthOfTreatment?.expectedTreatmentMax == null ||
                this.condition.lengthOfTreatment.expectedTreatmentMax === -1 ||
                this.condition.lengthOfTreatment?.expectedTreatmentMin == null ||
                this.condition.lengthOfTreatment?.expectedTreatmentMin === -1
            ) {
                return 'N/A';
            }

            if (
                this.condition.lengthOfTreatment?.expectedTreatmentMax === 0 &&
                this.condition.lengthOfTreatment.expectedTreatmentMin === 0
            ) {
                return '< 24 Hrs';
            }

            return `${this.condition.lengthOfTreatment?.expectedTreatmentMin} - ${this.condition.lengthOfTreatment?.expectedTreatmentMax}`;
        },
    },
    watch: {
        section(updatedSection: VisitConditionDataSection) {
            this.selectedSection = updatedSection;
        },
    },
    created() {
        this.selectedTab = this.tab || '';
        this.selectedSection = this.section;
    },
    methods: {
        optionalChain,
        close() {
            this.$emit('close');
        },
        showSection(section: VisitConditionDataSection): void {
            this.selectedTab = section.header;
            this.selectedSection = section;
        },
        tooltipDate(item: TreatmentDetails) {
            const date = item.administeredDate || item.orderedDate;
            return formatDateTime(date, false, false, '');
        },
        tooltipIcon(item: TreatmentDetails) {
            if (item.administeredDate) {
                return 'fa-light fa-circle-check';
            }

            if (item.orderedDate) {
                return 'fa-light fa-calendar-clock';
            }

            return '';
        },
        tooltipClass(item: TreatmentDetails) {
            if (item.administeredDate) {
                return 'success-500--text';
            }

            return '';
        },
    },
});
