
import Vue from 'vue';

export default Vue.extend({
    name: 'PercentageLineBar',
    props: {
        percentage: { type: Number, default: 0 },
        color: String,
        text: String,
    },
    data: () => ({
        widthCss: {},
    }),
    created() {
        this.widthCss = {
            width: this.percentage + '%',
        };
    },
});
