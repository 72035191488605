
import { propertyExists } from '@/components/shared/utils';
import { setLocationScope } from '@/shared/mutations';
import { Facility, LocationScopeInput, Maybe } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'ScopingTable',
    props: {
        facility: { type: Object as PropType<Facility>, required: true },
    },
    data: () => ({
        isDialogOpen: false,
        locationScopes: undefined as Maybe<boolean[]> | undefined,
        changeCount: 0,
    }),
    created() {
        this.locationScopes = this.getInitialScopes();
    },
    methods: {
        propertyExists,
        close() {
            this.isDialogOpen = false;
            this.locationScopes = this.getInitialScopes();
            this.changeCount = 0;
        },
        async saveChanges() {
            if (!this.locationScopes || !this.facility.locations?.length) return;

            const input: LocationScopeInput[] = [];
            for (let i = 0; i < this.locationScopes.length; i++) {
                const inScope = this.locationScopes[i];
                if (inScope !== this.facility.locations[i].inScope) {
                    input.push({
                        division: this.facility.division,
                        inScope,
                        // locationCode is resolved to be facilityCode:locationCode, must remove 'facilityCode:'
                        locationCode: this.facility.locations[i].code.slice(this.facility.code.length + 1),
                        facilityCode: this.facility.code,
                    });
                }
            }

            await this.$apollo.mutate({
                mutation: setLocationScope,
                variables: {
                    input,
                },
            });
            this.$emit('updateLocations', this.locationScopes);
            this.$toast.success('Location Scoping Changes Saved');
            this.changeCount = 0;
            this.isDialogOpen = false;
        },
        getInitialScopes() {
            return this.facility.locations ? this.facility.locations.map((loc) => loc.inScope) : this.facility.locations;
        },
        trackChange(index: number) {
            let delta = 1;
            if (this.locationScopes?.[index] === this.facility.locations?.[index].inScope) delta = -1;
            this.changeCount += delta;
        },
    },
});
