
import Vue from 'vue';

export default Vue.extend({
    name: 'VisitConditionDisclaimerButton',
    props: {
        hover: Boolean,
    },
    data: () => ({
        active: false,
    }),
    computed: {
        buttonClass(): string {
            if (this.active) {
                return 'button--active';
            } else if (this.hover) {
                return 'button--hover';
            } else {
                return 'button--hidden';
            }
        },
    },
});
