
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Loadable',
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
    },
});
