
import { VisitSummaryItem } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitSummaryItem',
    props: {
        item: {
            type: Object as PropType<VisitSummaryItem>,
            required: true,
        },
    },

    methods: {
        openItem(item: VisitSummaryItem) {
            this.$emit('openItem', item);
        },
    },
});
