
import Vue from 'vue';

export default Vue.extend({
    name: 'ScoreDots',
    props: {
        score: Number,
    },
    methods: {
        formatScoreDots(score: number): string {
            const filledScoreDot = '\u25cf';
            const emptyScoreDot = '\u25cb';
            const scoreDots = Math.ceil(score / 25.0);

            let result = '';
            for (let i = 0; i < Math.min(scoreDots, 4); i++) {
                result += filledScoreDot;
            }
            for (let j = 0; j < 4 - Math.max(scoreDots, 0); j++) {
                result += emptyScoreDot;
            }
            return result;
        },
    },
});
