import { defineStore } from 'pinia';

export const useVisitTimelineStore = defineStore('visitTimeline', {
    state: () => ({
        selectedKey: undefined as string | undefined,
    }),
    actions: {
        openItem(key: string) {
            this.$patch({
                selectedKey: key,
            });
        },
        reset() {
            this.$patch({
                selectedKey: undefined,
            });
        },
    },
});
