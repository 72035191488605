import { InteractQuery } from '@/models/interact/interact.model';
import { defineStore } from 'pinia';

export const useInteractQueryStore = defineStore('interactQueryStore', {
    state: () => ({
        mostRecentQuery: undefined as InteractQuery | undefined,
    }),
    actions: {
        updateMostRecentQuery(newQuery: InteractQuery): void {
            this.mostRecentQuery = newQuery;
        },
    },
    getters: {
        mostRecentQueryStatus: (state) => state.mostRecentQuery?.status,
    },
});
