
import { formatDateTime } from '@/components/shared/utils';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ClinicalsDueIcon',
    props: {
        lastClinicalsSentDate: { type: String, required: false, default: undefined },
    },
    methods: {
        formatDateTime,
    },
});
