
import AdditiveSelector from '@/components/shared/AdditiveSelector.vue';
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import Loadable from '@/components/shared/Loadable.vue';
import { phoneNumberValidator, uniqueCurriedValidator, withDefault } from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import ClinicalSummaryMicrobiologyTable from '@/components/visit/clinical-summary/ClinicalSummaryMicrobiologyTable.vue';
import DrawerHeader from '@/components/visit/drawer/DrawerHeader.vue';
import GenAiTextArea from '@/components/visit/gen-ai/GenAiTextArea.vue';
import VisitInfoBar from '@/components/visit/info-bar/VisitInfoBar.vue';
import LabsTable from '@/components/visit/labs/LabsTable.vue';
import MedicationAdministrationsTable from '@/components/visit/medications/MedicationAdministrationsTable.vue';
import MedicationOrdersTable from '@/components/visit/medications/MedicationOrdersTable.vue';
import { ClinicalDocumentItemType, ClinicalDocumentType, DrawerType, MutateClinicalDocumentItem, VisitInfoBarField } from '@/models';
import { DocumentBuilderTab, DocumentBuilderTabNames, genAiSummaryTypeCodes } from '@/models/clinical-document/ClinicalDocument.model';
import { assignFaxNumber, saveClinicalDocument, saveClinicalDocumentNote } from '@/shared/mutations';
import {
    getClinicalDocumentDocuments,
    getClinicalDocumentLabVitalsResults,
    getClinicalDocumentMedicationAdministrations,
    getClinicalDocumentMedicationOrders,
    getClinicalDocumentMicrobiologyResults,
} from '@/shared/queries';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import {
    ClinicalDocument,
    ClinicalDocumentStatusType,
    DocumentUnion,
    FaxNumber,
    LabVitalsResultsTable,
    MedicationAdministration,
    MedicationOrder,
    MicrobiologyResult,
    Mutation,
    Query,
    Visit,
} from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';
import ClinicalDocumentDocumentationTable from './ClinicalDocumentDocumentationTable.vue';
import DeleteDialog from './DeleteDialog.vue';

export default Vue.extend({
    name: 'ClinicalDocumentBuilderView',
    components: {
        FeatureToggle,
        LabsTable,
        DeleteDialog,
        MedicationOrdersTable,
        MedicationAdministrationsTable,
        ClinicalDocumentDocumentationTable,
        VisitInfoBar,
        DrawerHeader,
        ClinicalSummaryMicrobiologyTable,
        VerticalField,
        AdditiveSelector,
        Loadable,
        GenAiTextArea,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            default: () => ({} as Visit),
            required: true,
        },
        clinicalDocument: {
            type: Object as PropType<ClinicalDocument>,
            required: true,
        },
        documentType: { type: String as PropType<ClinicalDocumentType>, required: true },
        tabsToDisplay: { type: Array<DocumentBuilderTab>, default: () => [] as DocumentBuilderTab[], required: true },
        initialSelectedTab: { type: Number as PropType<DocumentBuilderTab>, required: false },
    },
    data: () => ({
        DrawerType,
        selectedTab: undefined as DocumentBuilderTab | undefined,
        DocumentBuilderTab,
        microbiologyResults: undefined as MicrobiologyResult[] | undefined,
        medicationOrders: undefined as MedicationOrder[] | undefined,
        medicationAdministrations: undefined as MedicationAdministration[] | undefined,
        labVitalsResults: undefined as LabVitalsResultsTable | undefined,
        documents: undefined as DocumentUnion[] | undefined,
        faxNumbers: [] as FaxNumber[],
        selectedFaxNumber: {} as FaxNumber | undefined,
        visitDrawerStore: useVisitDrawerStore(),
        visitStore: useVisitStore(),
        visitInfoBarFields: new Set<VisitInfoBarField>([
            VisitInfoBarField.PATIENT_INFO,
            VisitInfoBarField.ADMIT_STATUS,
            VisitInfoBarField.ADMIT_DATE,
            VisitInfoBarField.ADMIT_TIME,
            VisitInfoBarField.FACILITY,
            VisitInfoBarField.LOCATION,
            VisitInfoBarField.LOS,
            VisitInfoBarField.INLINE_DOB,
        ]),
        supportedTypesToDelete: new Set([
            ClinicalDocumentItemType.MEDICATION_ADMINISTRATION,
            ClinicalDocumentItemType.MEDICATION_ORDER,
            ClinicalDocumentItemType.DOCUMENT,
            ClinicalDocumentItemType.DOCUMENT_QUOTE,
            ClinicalDocumentItemType.LAB_RESULT,
            ClinicalDocumentItemType.MICROBIOLOGY,
        ]),
        genAiSummaryTypeCodes,
        DocumentBuilderTabNames,
    }),
    computed: {
        labVitalsResultsExist(): boolean {
            return (this.labVitalsResults?.columns?.length ?? 0) > 0;
        },
        faxNumbersDisplay(): { value: FaxNumber; text: string }[] {
            return this.faxNumbers.map((fn) => ({ value: fn, text: fn.faxNumber }));
        },
        noFaxNumbers(): boolean {
            return this.faxNumbers.length === 0;
        },
        clinicalSummaryId(): string | undefined {
            return this.clinicalDocument?.id;
        },
        clinicalSummaryNote(): string | undefined {
            return this.clinicalDocument?.note ?? undefined;
        },
        isInPayerGatewayScope(): boolean {
            return Boolean(this.visit.isInPayerGatewayScope);
        },
    },
    async created() {
        await this.populateData();
    },
    mounted() {
        if (this.initialSelectedTab) {
            this.selectedTab = this.initialSelectedTab;
        }
    },
    async destroyed() {
        await this.saveClinicalDocument();
    },
    methods: {
        withDefault,
        phoneNumberValidator,
        uniqueCurriedValidator,
        async saveClinicalDocument(): Promise<void> {
            if (this.selectedFaxNumber) {
                const clinicalDocument =
                    this.documentType === ClinicalDocumentType.APPEAL
                        ? this.visitStore.appealLetterSummary
                        : this.visitStore.clinicalSummary;

                const status: ClinicalDocumentStatusType | undefined = clinicalDocument?.status;
                const isReadyToSend: boolean | undefined = clinicalDocument?.isReadyToSend;
                if (this.documentType === ClinicalDocumentType.CLINICAL_SUMMARY && this.visitStore.clinicalSummary) {
                    this.visitStore.clinicalSummary.faxNumberId = parseInt(this.selectedFaxNumber.id);
                }
                await this.$apollo.mutate<Mutation>({
                    mutation: saveClinicalDocument,
                    variables: {
                        input: {
                            visitId: parseInt(this.visit.id),
                            clinicalDocumentId: parseInt(this.clinicalDocument.id),
                            faxNumberId: parseInt(this.selectedFaxNumber.id),
                            status: status,
                            isReadyToSend: isReadyToSend,
                        },
                    },
                });
            }
        },
        async populateData(): Promise<void> {
            this.getFaxNumbers();
            this.tabsToDisplay.forEach(async (tab) => {
                switch (tab) {
                    case DocumentBuilderTab.MICROBIOLOGY:
                        await this.getMicrobiologyResults();
                        break;
                    case DocumentBuilderTab.MEDICATIONS:
                        await this.getMedicationOrders();
                        await this.getMedicationAdministrations();
                        break;
                    case DocumentBuilderTab.LAB_RESULTS:
                        await this.getLabVitalsResults();
                        break;
                    case DocumentBuilderTab.DOCUMENTATION:
                        await this.getClinicalDocuments();
                        break;
                    case DocumentBuilderTab.PAYER_NOTE:
                        break;
                    default:
                        console.error('Unknown Clinical Document Tab.');
                }
            });
        },
        isTabVisible(tab: DocumentBuilderTab): boolean {
            return this.tabsToDisplay?.includes(tab) ?? false;
        },
        async deleteClinicalItem(item: MutateClinicalDocumentItem) {
            this.$emit('deleteClinicalItem', item);
        },
        async removeItem(type: ClinicalDocumentItemType, id: string) {
            if (this.supportedTypesToDelete.has(type)) {
                switch (type) {
                    case ClinicalDocumentItemType.MEDICATION_ORDER:
                        this.medicationOrders = this.medicationOrders?.filter((item) => item.id != id);
                        break;
                    case ClinicalDocumentItemType.MEDICATION_ADMINISTRATION:
                        this.medicationAdministrations = this.medicationAdministrations?.filter((item) => item.id != id);
                        break;
                    case ClinicalDocumentItemType.MICROBIOLOGY:
                        this.microbiologyResults = this.microbiologyResults?.filter((item) => item.id != id);
                        break;
                }
            }
        },
        removeLabResultItem(type: ClinicalDocumentItemType, id: string) {
            let colIndex = -1;
            let rowIndex = -1;
            if (this.labVitalsResults?.rows) {
                for (let i = 0; i < this.labVitalsResults?.rows.length; i++) {
                    let currentRow = this.labVitalsResults.rows[i];
                    let currentColIndex = currentRow.results.findIndex((result) => result?.id === id);
                    if (currentColIndex !== -1) {
                        colIndex = currentColIndex;
                        rowIndex = i;
                        break;
                    }
                }
            }

            let resultExistsInCol = false;
            let resultExistsInRow = false;
            //check if other lab result exists in column
            if (colIndex !== -1 && this.labVitalsResults?.rows) {
                for (let i = 0; i < this.labVitalsResults?.rows.length; i++) {
                    let currentResult = this.labVitalsResults.rows.at(i)?.results.at(colIndex);
                    if (currentResult && currentResult.id !== id) {
                        resultExistsInCol = true;
                        break;
                    }
                }
            }
            //check if other lab result exists in row
            if (rowIndex !== -1 && this.labVitalsResults?.rows) {
                resultExistsInRow = this.labVitalsResults?.rows[rowIndex].results.filter((result) => result !== null).length > 1;
            }

            //if no other results in column
            if (!resultExistsInCol && this.labVitalsResults?.columns) {
                this.labVitalsResults.rows.forEach((row) => {
                    //delete column from rows
                    this.$delete(row.results, colIndex);
                });
                this.$delete(this.labVitalsResults.columns, colIndex); //delete column from columns
                //if no other results in row as well
                if (!resultExistsInRow && this.labVitalsResults?.rows) {
                    //delete whole row
                    this.$delete(this.labVitalsResults.rows, rowIndex);
                }
                return;
            }

            //if other results are in column and no results in row
            if (resultExistsInCol && !resultExistsInRow && this.labVitalsResults?.rows) {
                //delete whole row
                this.$delete(this.labVitalsResults.rows, rowIndex);
                return;
            }

            //if other results exist in row and in column
            if (resultExistsInCol && resultExistsInRow && this.labVitalsResults?.rows) {
                // set that entry to null, do not delete
                this.$set(this.labVitalsResults.rows[rowIndex].results, colIndex, null);
                return;
            }
        },
        async removeDocumentUnionItem(item: MutateClinicalDocumentItem, type: ClinicalDocumentItemType) {
            const id =
                type === ClinicalDocumentItemType.DOCUMENT ? item.documentId?.toString() : item.documentQuote?.documentId?.toString();
            const quote = item.documentQuote?.quote;
            if (this.documents) {
                const removeIndex = this.documents.findIndex(function (item) {
                    if (
                        item.__typename === 'Document' &&
                        type === ClinicalDocumentItemType.DOCUMENT &&
                        item.id === id &&
                        quote === undefined
                    ) {
                        return item;
                    } else if (
                        item.__typename === 'DocumentQuote' &&
                        type === ClinicalDocumentItemType.DOCUMENT_QUOTE &&
                        item.documentId.toString() === id &&
                        item.quote === quote
                    ) {
                        return item;
                    }
                });
                if (removeIndex !== -1) {
                    this.$delete(this.documents, removeIndex);
                }
                this.deleteClinicalItem(item);
            }
        },
        async saveNote(note: string) {
            if (!this.clinicalDocument) return;
            this.clinicalDocument.note = note;
            const mutation = this.$apollo.mutate<Mutation>({
                mutation: saveClinicalDocumentNote,
                variables: {
                    input: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: parseInt(this.clinicalDocument.id),
                        note: this.clinicalDocument.note,
                    },
                },
            });
            this.visitStore.handlePendingDocumentMutation(mutation);
        },
        async addFaxNumber(faxNumberString: string) {
            const mutation = this.$apollo.mutate<Mutation>({
                mutation: assignFaxNumber,
                variables: {
                    input: {
                        division: this.visit.division,
                        faxNumbers: [faxNumberString],
                        payerCodes: [this.visit.primaryPayer?.code],
                        type: this.documentType,
                    },
                },
            });
            this.visitStore.handlePendingDocumentMutation(mutation);
            const faxNumber = (await mutation).data?.assignFaxNumber?.at(0);
            if (faxNumber === undefined) throw new Error('Unable to add fax number');
            this.faxNumbers.push(faxNumber);
            this.selectedFaxNumber = this.faxNumbers.at(-1);
        },
        getFaxNumbers(): void {
            this.faxNumbers = this.visit.primaryPayer?.faxNumbers?.filter((fax) => fax.type === this.documentType) ?? [];
            const initialSelectedFax = this.visitStore.clinicalSummary?.faxNumberId
                ? this.visitStore.getFaxFromId(this.visitStore.clinicalSummary.faxNumberId)
                : undefined;
            this.selectedFaxNumber =
                initialSelectedFax ??
                this.visitDrawerStore.params?.faxNumber ??
                this.faxNumbers.find((fn) => fn.order === 1) ??
                this.faxNumbers[0];
        },
        async completeDocument(): Promise<void> {
            await this.visitStore.settleAllDocumentMutations();
            this.$emit('completeDocument', this.selectedFaxNumber);
        },

        async getMicrobiologyResults() {
            const response = await this.$apollo.query<Query>({
                query: getClinicalDocumentMicrobiologyResults,
                variables: {
                    filter: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: parseInt(this.clinicalDocument!.id),
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.microbiologyResults = response.data.clinicalDocumentMicrobiologyResults;
        },
        async getMedicationOrders() {
            const response = await this.$apollo.query<Query>({
                query: getClinicalDocumentMedicationOrders,
                variables: {
                    filter: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: parseInt(this.clinicalDocument!.id),
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.medicationOrders = response.data.clinicalDocumentMedicationOrders.items;
        },
        async getMedicationAdministrations() {
            const response = await this.$apollo.query<Query>({
                query: getClinicalDocumentMedicationAdministrations,
                variables: {
                    filter: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: parseInt(this.clinicalDocument!.id),
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.medicationAdministrations = response.data.clinicalDocumentMedicationAdministrations.items;
        },
        async getLabVitalsResults() {
            const response = await this.$apollo.query<Query>({
                query: getClinicalDocumentLabVitalsResults,
                variables: {
                    filter: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: parseInt(this.clinicalDocument!.id),
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.labVitalsResults = response.data.clinicalDocumentLabVitalsResultsTable;
        },
        async getClinicalDocuments() {
            const response = await this.$apollo.query<Query>({
                query: getClinicalDocumentDocuments,
                variables: {
                    filter: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: parseInt(this.clinicalDocument!.id),
                    },
                },
                fetchPolicy: 'no-cache',
            });
            this.documents = response.data.clinicalDocumentDocuments;
        },
        deleteDocument() {
            this.$emit('deleteDocument', true);
        },
    },
});
