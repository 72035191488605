import { render, staticRenderFns } from "./MicrobiologyDrawerDetailView.vue?vue&type=template&id=684ca361&scoped=true&"
import script from "./MicrobiologyDrawerDetailView.vue?vue&type=script&lang=ts&"
export * from "./MicrobiologyDrawerDetailView.vue?vue&type=script&lang=ts&"
import style0 from "./MicrobiologyDrawerDetailView.vue?vue&type=style&index=0&id=684ca361&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684ca361",
  null
  
)

export default component.exports