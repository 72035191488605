
import Vue from 'vue';

export default Vue.extend({
    name: 'InfoButton',
    props: {
        text: {
            type: String,
            default: '',
        },
        menuProps: {
            type: Set<string>,
            required: false,
        },
    },
    data: () => ({
        active: false,
    }),
});
