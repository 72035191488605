export class Config {
    readonly baseUrl: string;
    readonly oidc: OidcConfig;
    readonly graphql: GraphqlConfig;

    constructor(rootJson: any) {
        this.baseUrl = rootJson.baseUrl;
        this.oidc = new OidcConfig(rootJson.oidc, this.baseUrl);
        this.graphql = new GraphqlConfig(rootJson.graphql);
    }
}

class OidcConfig {
    readonly clientId: string;
    readonly issuer: string;
    readonly redirectUri: string;
    readonly postLogoutRedirectUri: string;
    readonly scopes: string[];
    readonly pkce: boolean;

    constructor(oidcJson: any, baseUrl: string) {
        this.clientId = oidcJson.clientId;
        this.issuer = oidcJson.issuer;
        this.redirectUri = baseUrl + '/login/callback';
        this.postLogoutRedirectUri = baseUrl + '/login';
        this.scopes = ['openid', 'profile', 'email'];
        this.pkce = true;
    }
}

class GraphqlConfig {
    readonly url: string;

    constructor(graphqlJson: any) {
        this.url = graphqlJson.url;
    }
}
