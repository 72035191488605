
import { Role } from '@/auth/role.enum';
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import AdminButton from '@/components/sidebar/AdminButton.vue';
import AnalyticsButton from '@/components/sidebar/AnalyticsButton.vue';
import AvoidableDaysDialog from '@/components/sidebar/AvoidableDaysDialog.vue';
import CaseNotesButton from '@/components/sidebar/CaseNotesButton.vue';
import ClinicalSummaryButton from '@/components/sidebar/ClinicalSummaryButton.vue';
import ClinicalSummaryHistoryButton from '@/components/sidebar/ClinicalSummaryHistoryButton.vue';
import CompleteReviewDialog from '@/components/sidebar/CompleteReviewDialog.vue';
import CreateQueryButton from '@/components/sidebar/CreateQueryButton.vue';
import EscalateToPADialog from '@/components/sidebar/EscalateToPADialog.vue';
import GenAiButton from '@/components/sidebar/GenAiButton.vue';
import PatientSummaryButton from '@/components/sidebar/PatientSummaryButton.vue';
import QueriesButton from '@/components/sidebar/QueriesButton.vue';
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import WorklistButton from '@/components/sidebar/WorklistButton.vue';
import { InteractQueryTemplate } from '@/models/interact/interact.model';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import { useVisitStore } from '@/stores/VisitStore';
import Vue from 'vue';
import AppealsDenialsButton from './AppealsDenialsButton.vue';

export default Vue.extend({
    name: 'SideBar',
    components: {
        AdminButton,
        AnalyticsButton,
        AppealsDenialsButton,
        AvoidableDaysDialog,
        CaseNotesButton,
        ClinicalSummaryButton,
        ClinicalSummaryHistoryButton,
        CompleteReviewDialog,
        CreateQueryButton,
        EscalateToPADialog,
        GenAiButton,
        PatientSummaryButton,
        QueriesButton,
        SideBarButton,
        WorklistButton,
        FeatureToggle,
    },
    data: () => ({
        userStore: useUserStore(),
        featureStore: useFeatureStore(),
        visitStore: useVisitStore(),
        expanded: false,
        navButtonRoutes: new Set<string>([
            'visit',
            'patientsummary',
            'clinicalsummarylist',
            'clinicalsummarylistopen',
            'queries',
            'denials',
        ]),
        toolEnabledRoutes: new Set<string>([
            'visit',
            'patientsummary',
            'clinicalsummarylist',
            'clinicalsummarylistopen',
            'queries',
            'denials',
        ]),
        adminRoutes: new Set<string>(['worklist', 'admin', 'analytics']),
        worklistRoutes: new Set<string>(['worklist', 'admin', 'analytics']),
        analyticsEnabledRoutes: new Set<string>(['worklist', 'admin', 'analytics']),
        InteractQueryTemplate,
    }),
    computed: {
        displayTools(): boolean {
            return this.toolEnabledRoutes.has(this.$route.name ?? '');
        },
        isUmNurse(): boolean {
            return this.userStore.currentRole === Role.UM_NURSE;
        },
        isInteractEnabled(): boolean {
            return this.featureStore.isEnabled('INTERACT');
        },
        displayAdminButton(): boolean {
            return this.adminRoutes.has(this.$route.name ?? '') && (this.userStore.isAdmin || this.userStore.isManager);
        },
        displayNavButtons(): boolean {
            return this.navButtonRoutes.has(this.$route.name ?? '');
        },
        displayAnalyticsButton(): boolean {
            return this.userStore.canViewAnalytics && this.analyticsEnabledRoutes.has(this.$route.name ?? '');
        },
        displayBackToWorklistButton(): boolean {
            return !this.worklistRoutes.has(this.$route.name ?? '');
        },
        displayGenAiButton() {
            return (
                this.featureStore.isEnabled('GEN_AI') &&
                this.userStore.canViewTextGen &&
                !(this.featureStore.isEnabled('PROMINENT_GEN_AI') && this.userStore.canViewProminentAI)
            );
        },
        displayQueriesButton(): boolean {
            return this.displayNavButtons && this.isInteractEnabled;
        },
    },
});
