
import { formatDate, pluralize } from '@/components/shared/utils';
import { Denial } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'DenialIcon',
    props: {
        latestUnresolvedDenial: {
            type: Object as PropType<Denial>,
            required: true,
            default: undefined,
        },
        count: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        pluralize,
        formatDate,
        getDaysDeniedText() {
            return formatDate(this.latestUnresolvedDenial.denialStartDate) + ' - ' + formatDate(this.latestUnresolvedDenial.denialEndDate);
        },
    },
});
