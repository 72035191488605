
import Vue from 'vue';

export default Vue.extend({
    name: 'DeleteSavedClinicalSummaryDialog',
    props: {},
    data: () => ({
        isDialogOpen: false,
    }),
    methods: {
        cancel(): void {
            this.isDialogOpen = false;
        },
        async deleteClinicalSummary() {
            this.isDialogOpen = false;
            this.$emit('delete');
        },
    },
});
