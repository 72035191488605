
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import {
    currencyFormatter,
    formatDate,
    formatDateTime,
    formatPhoneNumber,
    NULLABLE,
    nullishCoalesce,
    optionalChain,
    withDefault,
} from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import ResolveDenialDialog from '@/components/visit/denials/ResolveDenialDialog.vue';
import { DrawerType } from '@/models';
import { ClinicalDocumentTypeDisplay } from '@/models/clinical-document/ClinicalDocument.model';
import { InteractQueryStatus, InteractQueryTemplate } from '@/models/interact/interact.model';
import { DenialStatus, DenialStatusDisplayName } from '@/models/visit/denials/Denials.model';
import { resolveDenial, setDenialEscalationQueryId, setDenialStatusToEscalated } from '@/shared/mutations';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useInteractQueryStore } from '@/stores/InteractQueryStore';
import { useInteractServiceStore } from '@/stores/InteractServiceStore';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import { Denial, Mutation } from 'generated/graphql/graphql';
import { mapState } from 'pinia';
import Vue, { PropType } from 'vue';
export default Vue.extend({
    name: 'DenialsListItem',
    components: {
        VerticalField,
        ResolveDenialDialog,
        FeatureToggle,
    },
    props: {
        denial: Object as PropType<Denial>,
    },
    data: () => ({
        visitDrawerStore: useVisitDrawerStore(),
        expanded: false,
        isResolveDenialDialogOpen: false,
        payerCommunications: 'some comm',
        hasAppealLetter: false,
        DenialStatus,
        DenialStatusDisplayName,
        interactServiceStore: useInteractServiceStore(),
        interactQueryStore: useInteractQueryStore(),
        featureStore: useFeatureStore(),
        InteractQueryTemplate,
        visitStore: useVisitStore(),
    }),
    computed: {
        ...mapState(useVisitStore, ['appealLetterId']),
        daysDenied(): string {
            if (this.denial.denialStartDate && this.denial.denialEndDate) {
                return formatDate(this.denial.denialStartDate) + ' - ' + formatDate(this.denial.denialEndDate);
            }
            return '--';
        },
        denialStatus(): string {
            if (this.denial.status) {
                return withDefault(DenialStatusDisplayName.get(this.denial.status));
            }
            return withDefault('');
        },
        dollarAmountOverturn(): string {
            if (!this.denial.overturnAmount) {
                return '--';
            }
            return currencyFormatter.format(this.denial.overturnAmount);
        },
        createdBy(): string {
            if (!this.denial.creator?.firstName && !this.denial.creator?.lastName) {
                return withDefault(null);
            }
            return withDefault(this.denial.creator?.lastName) + ', ' + withDefault(this.denial.creator?.firstName);
        },
        interactEnabled(): boolean {
            return this.featureStore.isEnabled('INTERACT');
        },
        ...mapState(useInteractQueryStore, ['mostRecentQueryStatus']),
    },
    watch: {
        async appealLetterId() {
            if (this.visitStore.appealLetterId) {
                this.hasAppealLetter = true;
            }
        },
        async mostRecentQueryStatus() {
            if (this.denial.escalationQueryId !== this.interactQueryStore.mostRecentQuery?.id) {
                return;
            }
            if (this.interactQueryStore.mostRecentQuery?.status === InteractQueryStatus.PENDING.toString() && this.denial.id) {
                await this.$apollo.mutate<Mutation>({
                    mutation: setDenialStatusToEscalated,
                    variables: {
                        input: {
                            denialId: +this.denial.id,
                            visitId: +this.$route.params.id,
                        },
                    },
                });
                this.$emit('updateDenialStatus', this.denial.id, DenialStatus.ESCALATE);
            }
        },
    },
    mounted() {
        this.hasAppealLetter = !!this.denial?.appealLetter?.id;
    },
    methods: {
        formatDate,
        formatDateTime,
        formatPhoneNumber,
        nullishCoalesce,
        withDefault,
        optionalChain,
        async escalate() {
            const queryId = await this.interactServiceStore?.interactService?.createInteractQueryForTemplate(
                InteractQueryTemplate.PEER_TO_PEER
            );
            if (this.denial.id && queryId) {
                await this.$apollo.mutate<Mutation>({
                    mutation: setDenialEscalationQueryId,
                    variables: {
                        input: {
                            denialId: +this.denial.id,
                            visitId: +this.$route.params.id,
                            interactEscalationId: queryId,
                        },
                    },
                });
            }
            this.$emit('updateEscalationQueryId', this.denial.id, queryId);
        },
        async resolve(appealMethod: string, outcome: string, overturnAmount: number) {
            if (this.denial.id) {
                const response = await this.$apollo.mutate<Mutation>({
                    mutation: resolveDenial,
                    variables: {
                        input: {
                            denialId: +this.denial.id,
                            visitId: +this.$route.params.id,
                            appealMethod,
                            outcome,
                            overturnAmount: overturnAmount ? +overturnAmount : undefined,
                        },
                    },
                });
                const resolvedDenial: NULLABLE<Denial> = response?.data?.resolveDenial;
                if (resolvedDenial) {
                    this.$toast.success('Denial Resolved');
                    this.$emit('resolveDenial', this.denial.id, resolvedDenial);
                    this.closeResolveDialog();
                } else {
                    this.$toast.error('Oops! Something is not right');
                }
            }
        },
        openDrawer(denialId: NULLABLE<number>) {
            if (denialId) {
                this.visitStore.selectedDenialId = denialId;
                this.visitDrawerStore.openDrawer({ drawer: DrawerType.APPEAL_LETTER, drawerProps: { dark: true } });
            }
        },
        openViewAppealDrawer() {
            const clinicalDocumentId = this.denial?.appealLetter?.id ?? this.visitStore.appealLetterId;
            if (clinicalDocumentId) {
                this.visitDrawerStore.openDrawer({
                    drawer: DrawerType.VIEW_APPEAL_LETTER,
                    params: {
                        clinicalDocumentId,
                    },
                    drawerProps: { dark: true, header: { title: ClinicalDocumentTypeDisplay.APPEAL } },
                });
            }
        },
        closeResolveDialog() {
            this.isResolveDenialDialogOpen = false;
        },
        openResolveDenialDialog() {
            this.isResolveDenialDialogOpen = true;
        },
    },
});
