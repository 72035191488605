
import Loadable from '@/components/shared/Loadable.vue';
import { withDefault } from '@/components/shared/utils';
import ClinicalDocumentBuilderView from '@/components/visit/clinical-document/ClinicalDocumentBuilderView.vue';
import { ClinicalDocumentType, DrawerType, MutateClinicalDocumentItem } from '@/models';
import { ClinicalDocumentTypeDisplay, clinicalSummaryTabs, DocumentBuilderTab } from '@/models/clinical-document/ClinicalDocument.model';
import { deleteClinicalDocumentItem } from '@/shared/mutations';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import { FaxNumber, Mutation, Visit } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ClinicalSummaryController',
    components: {
        ClinicalDocumentBuilderView,
        Loadable,
    },
    provide: {
        showAddToClinicalSummaryButton: false,
        showDeleteFromClinicalSummaryButton: true,
        enableLabSelection: false,
        documentType: ClinicalDocumentType.CLINICAL_SUMMARY,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            default: () => ({} as Visit),
            required: true,
        },
        documentBuilderSelectedTab: { type: Number as PropType<DocumentBuilderTab>, required: false },
    },
    data: () => ({
        visitStore: useVisitStore(),
        visitDrawerStore: useVisitDrawerStore(),
        DocumentBuilderTab,
        clinicalSummaryTabs,
        ClinicalDocumentType,
    }),
    computed: {
        isLoading(): boolean {
            return !this.visitStore.clinicalSummary?.id;
        },
        initialSelectedTab(): DocumentBuilderTab {
            return this.documentBuilderSelectedTab ?? DocumentBuilderTab.PAYER_NOTE;
        },
    },
    async created() {
        await this.visitStore.getClinicalSummary();
    },
    methods: {
        withDefault,
        async deleteClinicalSummaryItem(item: MutateClinicalDocumentItem) {
            if (!this.visitStore.clinicalSummary) {
                return;
            }
            const mutation = this.$apollo.mutate<Mutation>({
                mutation: deleteClinicalDocumentItem,
                variables: {
                    input: {
                        visitId: parseInt(this.visit.id),
                        clinicalDocumentId: +this.visitStore.clinicalSummary?.id ?? '',
                        ...item,
                    },
                },
            });
            this.visitStore.handlePendingDocumentMutation(mutation);
            await mutation;
            this.$toast.success('Item Deleted from Clinical Summary');
        },
        async completeClinicalSummary(faxNumber: FaxNumber): Promise<void> {
            this.visitDrawerStore.openDrawer({
                drawer: DrawerType.CLINICAL_DOCUMENT_CONFIRMATION,
                params: {
                    faxNumber: faxNumber,
                    payerName: withDefault(this.visit.primaryPayer && this.visit.primaryPayer.name),
                    visitId: this.visit.id,
                    clinicalDocumentId: this.visitStore.clinicalSummary?.id,
                    documentType: ClinicalDocumentType.CLINICAL_SUMMARY,
                },
                drawerProps: { dark: true },
                breadcrumbs: [
                    {
                        drawer: DrawerType.CLINICAL_SUMMARY,
                        params: this.visitDrawerStore.params,
                        drawerProps: { dark: true },
                    },
                ],
            });
        },
        async deleteDocument() {
            this.visitStore.deleteDocument(ClinicalDocumentType.CLINICAL_SUMMARY);
            this.visitDrawerStore.closeDrawer();
            this.$toast.success(`Your ${ClinicalDocumentTypeDisplay[ClinicalDocumentType.CLINICAL_SUMMARY]} has been deleted!`);
        },
    },
});
