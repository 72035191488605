import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { Resource } from '@opentelemetry/resources';
import { ConsoleSpanExporter, SimpleSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';

function setupOpentelemetry(graphqlUrl: string) {
    const provider = new WebTracerProvider({
        resource: new Resource({
            'service.name': 'UM-webapp-Frontend',
        }),
    });
    const url = `${graphqlUrl}/otel-server`;
    const collectorExporter = new OTLPTraceExporter({
        url, // proxy server URL
    });

    provider.addSpanProcessor(new SimpleSpanProcessor(collectorExporter));

    //will be cleaned up after changes are shown in kibana
    provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
    provider.register();

    registerInstrumentations({
        instrumentations: [getWebAutoInstrumentations()],
    });
}

export default setupOpentelemetry;
