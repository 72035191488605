
import { formatDateTime, withDefault } from '@/components/shared/utils';
import AddToClinicalSummaryButton from '@/components/visit/clinical-summary/AddToClinicalSummaryButton.vue';
import DeleteFromClinicalSummaryButton from '@/components/visit/clinical-summary/DeleteFromClinicalSummaryButton.vue';
import MedicationAdministrationDetailsButton from '@/components/visit/medications/MedicationAdministrationDetailsButton.vue';
import { ClinicalDocumentItemType } from '@/models';
import { MedicationAdministration } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'MedicationAdministrationsTable',
    components: {
        MedicationAdministrationDetailsButton,
        AddToClinicalSummaryButton,
        DeleteFromClinicalSummaryButton,
    },
    inject: {
        showAddToClinicalSummaryButton: { default: false },
        showDeleteFromClinicalSummaryButton: { default: false },
    },
    props: {
        medicationAdministrations: [] as PropType<MedicationAdministration[]>,
        height: String,
        displayDetailsButton: { type: Boolean, default: true },
    },
    data: () => ({
        ClinicalSummaryItemType: ClinicalDocumentItemType,
        hover: false,
    }),
    methods: {
        formatDateTime,
        withDefault,
    },
});
