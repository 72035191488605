
import { formatDateTime, withDefault } from '@/components/shared/utils';
import AddToClinicalSummaryButton from '@/components/visit/clinical-summary/AddToClinicalSummaryButton.vue';
import DeleteFromClinicalSummaryButton from '@/components/visit/clinical-summary/DeleteFromClinicalSummaryButton.vue';
import AbnormalFlagIcon from '@/components/visit/labs/AbnormalFlagIcon.vue';
import { ClinicalDocumentItemType } from '@/models';
import { LabVitalsResult } from 'generated/graphql/graphql';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'LabResultItem',
    components: {
        AddToClinicalSummaryButton,
        DeleteFromClinicalSummaryButton,
        AbnormalFlagIcon,
    },
    inject: {
        showAddToClinicalSummaryButton: { default: false },
        showDeleteFromClinicalSummaryButton: { default: false },
        enableLabSelection: { default: false },
    },
    props: {
        labResult: {
            type: Object as PropType<LabVitalsResult>,
            required: false,
        },
        columnDateString: {
            type: String,
            required: false,
        },
        abnormalLabsFilter: Boolean,
        highlighted: Boolean,
        selectedIdsProp: Array as PropType<string[]>,
    },
    data: () => ({
        ClinicalSummaryItemType: ClinicalDocumentItemType,
        hover: false,
        selected: false,
        selectedIds: [] as string[],
    }),
    computed: {
        displayHoverMenu() {
            return this.selectedIds.length === 0;
        },
    },
    watch: {
        selectedIdsProp() {
            if (!this.labResult || this.selectedIdsProp === undefined) {
                return;
            }
            this.selectedIds = this.selectedIdsProp;
            if (!this.selectedIds.some((id) => id === this.labResult?.id)) {
                this.selected = false;
            }
        },
    },
    methods: {
        formatDateTime,
        withDefault,
        toggleMultiSelectItem() {
            if (this.selected) {
                this.$emit('removedMultiSelectedLab', this.labResult);
            } else {
                this.$emit('addMultiSelectedLab', this.labResult);
            }
            this.selected = !this.selected;
        },
        toggleSingleSelectItem() {
            if (this.selected && this.selectedIds.length === 1) {
                this.$emit('clearSelectedLabs');
                this.selected = !this.selected;
                return;
            }
            this.$emit('addSingleSelectedLab', this.labResult);
            this.selected = true;
        },
    },
});
