
import ScopingTable from '@/components/admin/scoping/ScopingTable.vue';
import Loadable from '@/components/shared/Loadable.vue';
import SearchField from '@/components/shared/SearchField.vue';
import ToggleButtons from '@/components/shared/ToggleButtons.vue';
import { convertFromCompoundDivisionCode, convertToCompoundDivisionCode, filterOnProperties, pluralize } from '@/components/shared/utils';
import { Scopable, ScopingChange, ScopingChangeMap, ScopingItem, ScopingMap } from '@/models';
import { setFacilityScope, setHospitalServiceScope, setPayerScope } from '@/shared/mutations';
import { getScopedFacilities, getScopedHospitalServices, getScopedPayers } from '@/shared/queries';
import { Facility, FacilityScopeInput, HospitalServiceScopeInput, PayerScopeInput, Query } from 'generated/graphql/graphql';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ScopingConfiguration',
    components: {
        Loadable,
        ScopingTable,
        SearchField,
        ToggleButtons,
    },
    data: () => ({
        items: {} as ScopingMap,
        changes: {} as ScopingChangeMap,
        changeCount: 0,
        displayItems: [] as Scopable[],
        itemTypes: Object.values(ScopingItem),
        disabledItemTypes: [] as ScopingItem[],
        selectedItemType: ScopingItem.FACILITY,
        loading: true,
    }),
    created() {
        this.searchFieldHandler();
    },
    methods: {
        pluralize,
        filterOnProperties,
        convertFromCompoundDivisionCode,
        convertToCompoundDivisionCode,
        loadingSearchResults(loading: boolean) {
            this.loading = loading;
        },
        async searchFieldHandler(searchText = '', done?: () => void) {
            this.loading = true;
            if (!this.items[this.selectedItemType]) {
                this.items[this.selectedItemType] = await this.getItems();
            }
            const items = this.items[this.selectedItemType];
            this.displayItems = searchText
                ? this.items[this.selectedItemType].filter(filterOnProperties(searchText, ['name', 'code']))
                : items;
            this.loading = false;
            done?.();
        },
        async getItems() {
            let items: Scopable[];
            if (this.selectedItemType === ScopingItem.FACILITY) {
                items = await this.searchFacilities();
            } else if (this.selectedItemType === ScopingItem.SERVICE) {
                items = await this.searchServices();
            } else if (this.selectedItemType === ScopingItem.PAYER) {
                items = await this.searchPayers();
            } else {
                throw new Error(
                    `Selected Item type: ${this.selectedItemType} is not a member of supported item types: ${Object.keys(ScopingItem)}`
                );
            }
            return items;
        },
        async searchFacilities() {
            const response = await this.$apollo.query<Query>({
                query: getScopedFacilities,
                variables: {
                    includeOutOfScope: true,
                },
                fetchPolicy: 'no-cache',
            });
            return response.data.facilities;
        },
        async searchServices() {
            const response = await this.$apollo.query<Query>({
                query: getScopedHospitalServices,
                variables: {
                    includeOutOfScope: true,
                },
                fetchPolicy: 'no-cache',
            });
            return response.data.hospitalServices;
        },
        async searchPayers() {
            const response = await this.$apollo.query<Query>({
                query: getScopedPayers,
                variables: {
                    filter: {
                        includeOutOfScope: true,
                    },
                },
                fetchPolicy: 'no-cache',
            });
            return response.data.payers;
        },
        switchScopingItem(itemType: ScopingItem) {
            this.selectedItemType = itemType;
            this.searchFieldHandler();
        },
        async saveChanges() {
            const savePromises: Promise<void>[] = [];
            for (const [itemTypeKey, value] of Object.entries(this.changes)) {
                let codeKey: keyof ScopingChange;
                let saveMethod;
                switch (itemTypeKey) {
                    case ScopingItem.FACILITY:
                        codeKey = 'facilityCode';
                        saveMethod = this.saveFacilityChanges;
                        break;
                    case ScopingItem.PAYER:
                        codeKey = 'payerCode';
                        saveMethod = this.savePayerChanges;
                        break;
                    case ScopingItem.SERVICE:
                        codeKey = 'hospitalServiceCode';
                        saveMethod = this.saveServiceChanges;
                        break;
                    default:
                        throw new Error(
                            `Cannot save item of type ${itemTypeKey}, it is not a member of supported item types: ${Object.keys(
                                ScopingItem
                            )}`
                        );
                }
                const input: ScopingChange[] = Object.entries(value).map(([compoundCode, inScope]) => {
                    const { division, code } = convertFromCompoundDivisionCode(compoundCode);
                    return {
                        [codeKey]: code,
                        inScope,
                        division,
                    } as ScopingChange;
                });
                savePromises.push(saveMethod(input));
                this.changeCount = 0;
            }
            await Promise.all(savePromises);
            this.changes = {} as ScopingChangeMap;
            this.$toast.success('Scoping Changes Saved');
            this.disabledItemTypes = [];
        },
        async saveFacilityChanges(input: FacilityScopeInput[]) {
            await this.$apollo.mutate({
                mutation: setFacilityScope,
                variables: {
                    input,
                },
            });
        },
        async savePayerChanges(input: PayerScopeInput[]) {
            await this.$apollo.mutate({
                mutation: setPayerScope,
                variables: {
                    input,
                },
            });
        },
        async saveServiceChanges(input: HospitalServiceScopeInput[]) {
            await this.$apollo.mutate({
                mutation: setHospitalServiceScope,
                variables: {
                    input,
                },
            });
        },
        disableItems(itemType: ScopingItem) {
            if (this.changeCount) {
                this.disabledItemTypes = this.itemTypes.filter((type) => type !== itemType);
            } else {
                this.disabledItemTypes = [];
            }
        },
        trackChange(itemType: ScopingItem, division: string, code: string, value: boolean) {
            const compoundCode = convertToCompoundDivisionCode({ division, code });
            if (!this.changes[itemType]) {
                this.changes[itemType] = { [compoundCode]: value };
                this.changeCount += 1;
                this.disableItems(itemType);
                return;
            }

            if (this.changes[itemType][compoundCode] !== undefined) {
                delete this.changes[itemType][compoundCode];
                this.changeCount -= 1;
            } else {
                this.changes[itemType][compoundCode] = value;
                this.changeCount += 1;
            }
            this.disableItems(itemType);
        },
        updateLocations(index: number, inScope: boolean[]) {
            const facility: Facility = this.items[ScopingItem.FACILITY][index] as Facility;
            this.items[ScopingItem.FACILITY][index] = {
                ...facility,
                locations: facility.locations?.map((loc, index) => ({
                    ...loc,
                    inScope: inScope[index],
                })),
            };
            this.displayItems = [...this.displayItems];
        },
    },
});
