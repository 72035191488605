
import { formatDate, formatPersonName, formatPhoneNumber, formatTime, properCase } from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import { ClinicalDocument } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ClinicalSummarySavedListItem',
    components: {
        VerticalField,
    },
    props: {
        clinicalSummary: {
            type: Object as PropType<ClinicalDocument>,
            required: true,
        },
    },
    computed: {
        isReadyToSend() {
            return this.clinicalSummary.isReadyToSend ? 'Ready to send' : '--';
        },
    },
    methods: {
        formatDate,
        formatTime,
        formatPersonName,
        formatPhoneNumber,
        properCase,
        select() {
            this.$router.push({
                name: 'clinicalsummarylistopen',
                params: { id: this.$route.params.id, clinicalSummaryId: this.clinicalSummary.id },
            });
        },
    },
});
