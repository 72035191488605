
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import { InteractQueryTemplate } from '@/models/interact/interact.model';
import { useInteractServiceStore } from '@/stores/InteractServiceStore';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'CreateQueryButton',
    components: {
        SideBarButton,
    },
    props: {
        expanded: Boolean,
        queryTemplateName: { type: String as PropType<InteractQueryTemplate>, required: true },
    },
    data: () => ({
        interactStore: useInteractServiceStore(),
        InteractQueryTemplate,
    }),
    methods: {
        createQueryForTemplate() {
            return this.interactStore?.interactService?.createInteractQueryForTemplate(this.queryTemplateName);
        },
    },
});
