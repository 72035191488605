
import Vue from 'vue';
import VClamp from 'vue-clamp';

export default Vue.extend({
    name: 'VisitConditionDetailsSectionHeader',
    components: {
        VClamp,
    },
    props: {
        headerText: String,
    },
});
