import { Query } from 'generated/graphql/graphql';
import gql from 'graphql-tag';
import { defineStore } from 'pinia';

export const useFeatureStore = () => {
    const featureStore = defineStore('feature', {
        state: () => ({
            isInitialized: undefined as boolean | undefined,
            enabledFeatures: undefined as string[] | undefined,
        }),
        actions: {
            initialize() {
                this.$patch({
                    isInitialized: false,
                });
                this.apolloClient
                    .query<Query>({
                        query: gql`
                            query FeatureFlags {
                                features {
                                    name
                                }
                            }
                        `,
                        fetchPolicy: 'no-cache',
                    })
                    .then((response) => {
                        this.$patch({
                            isInitialized: true,
                            enabledFeatures: response.data.features.map((f) => f.name),
                        });
                    });
            },
            isEnabled(name: string): boolean {
                return this.enabledFeatures?.includes(name) ?? false;
            },
        },
    });
    const store = featureStore();
    if (store.isInitialized === undefined) {
        store.initialize();
    }
    return store;
};
