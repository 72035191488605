import { render, staticRenderFns } from "./ClinicalDocumentConfirmationView.vue?vue&type=template&id=a8e65d12&scoped=true&"
import script from "./ClinicalDocumentConfirmationView.vue?vue&type=script&lang=ts&"
export * from "./ClinicalDocumentConfirmationView.vue?vue&type=script&lang=ts&"
import style0 from "./ClinicalDocumentConfirmationView.vue?vue&type=style&index=0&id=a8e65d12&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8e65d12",
  null
  
)

export default component.exports