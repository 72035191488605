
import MultiDatePicker from '@/components/shared/MultiDatePicker.vue';
import Selection from '@/components/shared/Selection.vue';
import { DateSelection, formatISODate } from '@/components/shared/utils';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useVisitStore } from '@/stores/VisitStore';
import { Visit } from 'generated/graphql/graphql';
import moment from 'moment';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'GlobalDateTimeFilter',
    components: {
        MultiDatePicker,
        Selection,
    },
    props: {
        visit: {
            type: Object as PropType<Visit>,
            default: () => ({} as Visit),
            required: true,
        },
        disableGlobalDateFilter: { type: Boolean, required: false, default: false },
    },
    data: () => ({
        DateSelection,
        visitStore: useVisitStore(),
        featureStore: useFeatureStore(),
        dateSelection: undefined as DateSelection | undefined,
    }),
    computed: {
        preSelectedDates() {
            const startDate = this.getStartDate(this.dateSelection);
            return startDate ? { startDate, endDate: formatISODate(new Date()) } : {};
        },
        isLast24HoursDisabled() {
            return moment.duration(moment().diff(this.visit.admitDate)).asHours() < 24;
        },
        isLast48HoursDisabled() {
            return moment.duration(moment().diff(this.visit.admitDate)).asHours() < 48;
        },
        isNewSinceLastReviewDisabled() {
            return !this.visit.lastReviewDate;
        },
    },
    methods: {
        formatISODate,
        setDates(startDate: string | undefined, endDate: string | undefined) {
            if (moment(startDate, 'YYYY-MM-DD HH').isSame(moment(this.getStartDate(this.dateSelection), 'YYYY-MM-DD HH'))) {
                this.visitStore.$patch({
                    dateSelection: this.dateSelection,
                    startDate,
                    endDate,
                });
            } else {
                this.dateSelection = undefined;
                this.visitStore.$patch({
                    dateSelection: undefined,
                    startDate,
                    endDate,
                });
            }
        },
        getStartDate(selection: DateSelection | undefined) {
            let startDate: string | undefined;
            switch (selection) {
                case DateSelection.NEW_SINCE_LAST_REVIEW:
                    startDate = formatISODate(this.visit.lastReviewDate!);
                    break;
                case DateSelection.LAST_24_HOURS:
                    startDate = formatISODate(moment().subtract(24, 'hours').toDate());
                    break;
                case DateSelection.LAST_48_HOURS:
                    startDate = formatISODate(moment().subtract(48, 'hours').toDate());
                    break;
            }
            return startDate;
        },
        selectDate(selection: DateSelection) {
            this.dateSelection = selection;
        },
    },
});
