
import { useInteractServiceStore } from '@/stores/InteractServiceStore';
import { NavigationGuardMixin } from '@okta/okta-vue';

export default NavigationGuardMixin.extend({
    name: 'QueriesView',
    data: () => ({
        interactStore: useInteractServiceStore(),
    }),
    computed: {
        isAuthor() {
            return this.interactStore.interactService?.isAuthorMode();
        },
        showQuery() {
            return this.interactStore.interactService?.showQuery;
        },
    },
    async mounted() {
        await this.interactStore?.interactService?.setCurrentInteractEncounter();
        this.interactStore?.interactService?.loadEncounterView();
    },
    destroyed() {
        this.interactStore?.interactService?.setIsQueriesView(false);
    },
});
