
import { pluralize } from '@/components/shared/utils';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SearchField',
    props: {
        searchPlaceholder: {
            type: String,
            required: false,
            default: '',
        },
        searchResultCount: {
            type: Number,
            required: false,
            default: undefined,
        },
        alwaysExpanded: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        showField: false,
        searchText: '',
        timeout: undefined as NodeJS.Timeout | undefined,
    }),
    computed: {
        noResults(): boolean {
            return this.searchText.length > 1 && this.searchResultCount === 0;
        },
    },
    created() {
        this.showField = this.alwaysExpanded;
    },
    methods: {
        pluralize,
        toggleField(): void {
            if (this.alwaysExpanded) return;
            this.showField = !this.showField;
            this.$emit('expanded', this.showField);
        },
        debounceSearch(): void {
            if (this.searchText === '') {
                this.$emit('clearSearch');
                return;
            }
            if (this.searchText.length === 1) {
                return;
            }
            this.$emit('loading', true);
            clearTimeout(this.timeout);
            this.timeout = setTimeout(async () => {
                const searchPromise = new Promise<void>((res) => {
                    this.$emit('search', this.searchText, () => res());
                });
                await searchPromise;
                this.$emit('loading', false);
            }, 500);
        },
        clearSearch() {
            this.searchText = '';
            this.$emit('clearSearch');
        },
    },
});
