
import Loadable from '@/components/shared/Loadable.vue';
import { getIpoProcedure } from '@/shared/queries';
import { Query, VisitSummaryItem } from 'generated/graphql/graphql';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'InpatientOnlyIcon',
    components: {
        Loadable,
    },
    props: {
        visitId: { type: String, required: true },
    },
    data: () => ({
        loading: true,
        ipoProcedures: [] as VisitSummaryItem[],
    }),
    methods: {
        async getProcedure() {
            if (!this.ipoProcedures.length) {
                const response = await this.$apollo.query<Query>({
                    query: getIpoProcedure,
                    variables: {
                        id: +this.visitId,
                    },
                    fetchPolicy: 'no-cache',
                });
                this.ipoProcedures = response.data.inpatientOnlyProcedures || [];
            }
            this.loading = false;
        },
    },
});
