
import { DrawerType } from '@/models';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import Vue from 'vue';

export default Vue.extend({
    name: 'DrawerBackButton',
    data: () => ({
        DrawerType,
        visitDrawerStore: useVisitDrawerStore(),
    }),
    methods: {
        backtrack() {
            this.visitDrawerStore.backtrack();
        },
    },
});
