
import InfoButton from '@/components/shared/InfoButton.vue';
import Loadable from '@/components/shared/Loadable.vue';
import ToggleButtons from '@/components/shared/ToggleButtons.vue';
import { visitPredictedStatusHistoryDisclaimer } from '@/components/shared/utils';
import VisitPredictedStatusHistoryChart from '@/components/visit/patient-summary/predicted-status/VisitPredictedStatusHistoryChart.vue';
import { ToggleButtonName } from '@/models';
import { getPredictedAdmitStatusHistory } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useVisitStore } from '@/stores/VisitStore';
import { AdmitStatusPrediction, Query } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'VisitPredictedStatusHistory',
    components: {
        InfoButton,
        ToggleButtons,
        Loadable,
        VisitPredictedStatusHistoryChart,
    },
    data: () => ({
        ToggleButtonName,
        isLoading: true,
        visitStore: useVisitStore(),
        visitPredictedStatusHistoryDisclaimer,
        selectedButton: undefined as ToggleButtonName | undefined,
        inpatientPredictions: [] as AdmitStatusPrediction[],
        observationPredictions: [] as AdmitStatusPrediction[],
        admitPredictions: [] as AdmitStatusPrediction[],
        tempStartDate: undefined as Date | undefined,
        tempEndDate: undefined as Date | undefined,
        featureStore: useFeatureStore(),
    }),
    computed: {
        getToggleButtons(): ToggleButtonName[] {
            // Don't show ADMIT button if FF is off
            if (this.featureStore.isEnabled('ED_MODEL_FE')) {
                return [ToggleButtonName.INPATIENT, ToggleButtonName.OBSERVATION, ToggleButtonName.ADMIT];
            } else {
                return [ToggleButtonName.INPATIENT, ToggleButtonName.OBSERVATION];
            }
        },
        predictions() {
            if (this.selectedButton === ToggleButtonName.INPATIENT) {
                return this.inpatientPredictions;
            }
            if (this.selectedButton === ToggleButtonName.OBSERVATION) {
                return this.observationPredictions;
            }
            if (this.selectedButton === ToggleButtonName.ADMIT) {
                return this.admitPredictions;
            }
            return [];
        },
        disabledButtons() {
            let buttons: ToggleButtonName[] = [];
            if (!this.inpatientPredictions.length) {
                buttons.push(ToggleButtonName.INPATIENT);
            }
            if (!this.observationPredictions.length) {
                buttons.push(ToggleButtonName.OBSERVATION);
            }
            if (!this.admitPredictions.length) {
                buttons.push(ToggleButtonName.ADMIT);
            }
            return buttons;
        },
        defaultButton() {
            if (this.inpatientPredictions.length) {
                return ToggleButtonName.INPATIENT;
            } else if (this.observationPredictions.length) {
                return ToggleButtonName.OBSERVATION;
            } else if (this.admitPredictions.length) {
                return ToggleButtonName.ADMIT;
            }
            return undefined;
        },
        hasPredictions() {
            return this.inpatientPredictions.length || this.observationPredictions.length || this.admitPredictions.length;
        },
    },
    async created() {
        await this.loadHistory();

        this.visitStore.$subscribe(async () => {
            if (this.tempStartDate != this.visitStore.startDate || this.tempEndDate != this.visitStore.endDate) {
                this.tempStartDate = this.visitStore.startDate;
                this.tempEndDate = this.visitStore.endDate;
                await this.loadHistory();
            }
        });
    },
    methods: {
        async toggleView(selectedButton: ToggleButtonName): Promise<void> {
            this.selectedButton = selectedButton;
        },
        async loadHistory() {
            this.isLoading = true;
            if (this.$route.params.id) {
                const response = await this.$apollo.query<Query>({
                    query: getPredictedAdmitStatusHistory,
                    variables: {
                        id: +this.$route.params.id,
                        filter: {
                            startDate: this.visitStore.startDate,
                            endDate: this.visitStore.endDate,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });

                this.inpatientPredictions = [];
                this.observationPredictions = [];
                this.admitPredictions = [];
                response.data?.admitStatusPredictionHistory?.forEach((pred: AdmitStatusPrediction) => {
                    if (pred.inpatientConfidence) {
                        this.inpatientPredictions?.push(pred);
                    } else if (pred.observationConfidence) {
                        this.observationPredictions?.push(pred);
                    } else if (pred.admitConfidence && this.featureStore.isEnabled('ED_MODEL_FE')) {
                        // even if predictions are made this FF will stop the chart seeing them
                        this.admitPredictions?.push(pred);
                    }
                });
                this.selectedButton = this.defaultButton;
                this.isLoading = false;
            }
        },
    },
});
