
import Vue from 'vue';

export default Vue.extend({
    name: 'ExpandableSection',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        expanded: true,
    }),
});
