
import DocumentDrawerButton from '@/components/shared/DocumentDrawerButton.vue';
import { pluralize } from '@/components/shared/utils';
import VisitConditionDetailsSectionHeader from '@/components/visit/conditions/VisitConditionDetailsSectionHeader.vue';
import { DrawerType } from '@/models';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { Mention } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'DocumentConditionDetailView',
    components: {
        VisitConditionDetailsSectionHeader,
        DocumentDrawerButton,
    },
    props: {
        supportingDocumentation: { type: Array<Mention>, required: true },
        conditionName: { type: String, required: true },
    },
    data: () => ({
        visitDrawerStore: useVisitDrawerStore(),
    }),
    computed: {
        headerText(): string {
            return (
                this.supportingDocumentation.length.toString() +
                ' Mention ' +
                pluralize('Category', this.supportingDocumentation.length) +
                ' for ' +
                this.conditionName
            );
        },
    },
    updated() {
        this.handleNoSupportingDocumentation();
    },
    mounted() {
        this.handleNoSupportingDocumentation();
    },
    methods: {
        pluralize,
        openDrawer(item: Mention) {
            this.visitDrawerStore.openDrawer({
                drawer: DrawerType.DOCUMENTATION,
                params: {
                    providedDocuments: item.documents,
                    mentionText: item.phrase,
                    medicalConceptId: item.medicalConceptId,
                },
            });
        },
        handleNoSupportingDocumentation() {
            if (this.supportingDocumentation.length === 0) {
                this.$emit('noData', true);
            }
        },
    },
});
