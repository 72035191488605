
import { pluralize } from '@/components/shared/utils';
import Vue from 'vue';

export default Vue.extend({
    name: 'DocumentDrawerButton',
    props: {
        count: {
            type: Number,
            required: false,
        },
    },
    methods: {
        pluralize,
    },
});
