
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import { Direction, DrawerType } from '@/models';
import { getVisitCommentsState } from '@/shared/queries';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import { useVisitStore } from '@/stores/VisitStore';
import { Query, VisitCommentsState } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'CaseNotesButton',
    components: {
        SideBarButton,
    },
    props: {
        expanded: Boolean,
        selected: Boolean,
    },
    data: () => ({
        visitDrawerStore: useVisitDrawerStore(),
        showBadge: false,
    }),
    async created() {
        this.showBadge = (await this.getVisitCommentState()).unreadCount > 0;
    },
    methods: {
        openDrawer() {
            this.visitDrawerStore.openDrawer({
                drawer: DrawerType.CASE_NOTES,
                drawerProps: { enterFrom: Direction.LEFT, height: 100, width: 30 },
            });
            this.showBadge = false;
        },
        async getVisitCommentState(): Promise<VisitCommentsState> {
            const response = await useVisitStore().onVisitLoad(() =>
                this.$apollo.query<Query>({
                    query: getVisitCommentsState,
                    variables: {
                        filter: {
                            visitId: +this.$route.params.id,
                        },
                    },
                    fetchPolicy: 'no-cache',
                })
            );
            return response.data.visitCommentsState;
        },
    },
});
