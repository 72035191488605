
import ToggleButtons from '@/components/shared/ToggleButtons.vue';
import DrawerHeader from '@/components/visit/drawer/DrawerHeader.vue';
import MedicationAdministrationsTable from '@/components/visit/medications/MedicationAdministrationsTable.vue';
import MedicationOrdersTable from '@/components/visit/medications/MedicationOrdersTable.vue';
import { ToggleButtonName, VisitSummaryItemType } from '@/models';
import {
    getMedicationDetailViewMedicationAdministration,
    getMedicationDetailViewMedicationOrder,
    getMedicationDetailViewMedicationOrders,
} from '@/shared/queries';
import { MedicationAdministration, MedicationOrder, Query } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'MedicationsDrawerDetailView',
    components: {
        MedicationAdministrationsTable,
        MedicationOrdersTable,
        DrawerHeader,
        ToggleButtons,
    },
    props: {
        type: String as PropType<VisitSummaryItemType>,
        referenceId: String,
        medicationName: String,
        medicationGenericName: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        medicationAdministrations: [] as MedicationAdministration[],
        medicationOrders: [] as MedicationOrder[],
        currentButton: undefined as ToggleButtonName | undefined,
        ToggleButtonName,
        VisitSummaryItemType,
    }),
    computed: {
        noMedicationAdministrations(): boolean {
            return this.medicationAdministrations.length === 0;
        },
        noMedicationOrders(): boolean {
            return this.medicationOrders?.length === 0;
        },
        chipText(): string {
            return this.medicationGenericName ? this.medicationGenericName.toLowerCase() : this.medicationName.toLowerCase();
        },
    },
    created() {
        this.getMedicationsFromVisitSummary();
    },
    methods: {
        toggleView(selectedTab: ToggleButtonName): void {
            this.currentButton = selectedTab;
        },
        async getMedicationsFromVisitSummary() {
            if (this.type === VisitSummaryItemType.MEDICATION_ADMINISTRATION) {
                await this.getSingleMedicationAdministration();
            } else if (this.type === VisitSummaryItemType.MEDICATION_ORDER) {
                this.medicationGenericName
                    ? await this.getMedicationOrders(this.medicationGenericName)
                    : await this.getSingleMedicationOrder();
            } else if (this.type === VisitSummaryItemType.MEDICATION) {
                await this.getMedicationOrders(this.referenceId);
            }
        },
        async getMedicationOrders(medicationName: string) {
            const response = await this.$apollo.query<Query>({
                query: getMedicationDetailViewMedicationOrders,
                variables: {
                    filter: {
                        visitId: parseInt(this.$route.params.id),
                        medicationName,
                    },
                },
                fetchPolicy: 'no-cache',
            });

            this.medicationOrders = response.data.medicationOrders.items;
            this.medicationAdministrations = response.data.medicationOrders.items.reduce((acc, medicationOrder) => {
                // give administrations a reference back to orders
                const medicationAdministrations = medicationOrder.medicationAdministrations.items.map((ma) => ({
                    ...ma,
                    medicationOrder,
                }));
                return acc.concat(medicationAdministrations);
            }, [] as MedicationAdministration[]);
            this.currentButton = this.medicationAdministrations.length
                ? ToggleButtonName.MEDICATION_ADMINISTRATIONS
                : ToggleButtonName.MEDICATION_ORDERS;
        },
        async getSingleMedicationOrder() {
            this.currentButton = ToggleButtonName.MEDICATION_ORDERS;
            const response = await this.$apollo.query<Query>({
                query: getMedicationDetailViewMedicationOrder,
                variables: {
                    id: parseInt(this.referenceId),
                },
                fetchPolicy: 'no-cache',
            });

            this.medicationOrders = response.data.medicationOrder ? [response.data.medicationOrder] : [];
            this.medicationAdministrations =
                response.data.medicationOrder?.medicationAdministrations?.items.map((ma) => ({
                    ...ma,
                    medicationOrder: response.data.medicationOrder!,
                })) ?? [];
        },
        async getSingleMedicationAdministration() {
            this.currentButton = ToggleButtonName.MEDICATION_ADMINISTRATIONS;
            const response = await this.$apollo.query<Query>({
                query: getMedicationDetailViewMedicationAdministration,
                variables: {
                    id: parseInt(this.referenceId),
                },
                fetchPolicy: 'no-cache',
            });

            this.medicationAdministrations = response.data.medicationAdministration ? [response.data.medicationAdministration] : [];
            this.medicationOrders = response.data.medicationAdministration?.medicationOrder
                ? [
                      {
                          ...response.data.medicationAdministration?.medicationOrder,
                          medicationAdministrations: {
                              items: this.medicationAdministrations,
                          },
                      },
                  ]
                : [];
        },
    },
});
