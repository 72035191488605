export default {
    'neutral-50': '#f5f7fa',
    'neutral-100': '#e4e7eb',
    'neutral-200': '#cbd2d9',
    'neutral-300': '#9aa5b1',
    'neutral-400': '#7b8994',
    'neutral-500': '#616e7c',
    'neutral-600': '#52606d',
    'neutral-700': '#3e4c59',
    'neutral-800': '#323f4b',
    'neutral-900': '#1f2933',

    'primary-50': '#e8eaf6',
    'primary-100': '#c5cae9',
    'primary-200': '#9fa8da',
    'primary-300': '#7986cb',
    'primary-400': '#5c6bc0',
    'primary-500': '#3f51b5',
    'primary-600': '#3949ab',
    'primary-700': '#303f9f',
    'primary-800': '#283593',
    'primary-900': '#1a237e',

    'primary-a100': '#8c9eff',
    'primary-a200': '#536dfe',
    'primary-a400': '#3d5afe',
    'primary-a700': '#304ffe',

    'interactive-50': '#e3f2fd',
    'interactive-300': '#64b5f6',
    'interactive-500': '#2196f3',
    'interactive-700': '#1976d2',
    'interactive-900': '#0d47a1',

    'interactive-a100': '#82b1ff',
    'interactive-a200': '#448aff',
    'interactive-a400': '#2979ff',
    'interactive-a700': '#2962ff',

    'accent-50': '#e0f7fa',
    'accent-300': '#4dd0e1',
    'accent-500': '#00bcd4',
    'accent-700': '#0097a7',
    'accent-900': '#006064',

    'success-50': '#e8f5e9',
    'success-300': '#88c484',
    'success-500': '#4caf50',
    'success-700': '#388e3c',
    'success-900': '#1b5e20',

    'warning-50': '#fff8e1',
    'warning-300': '#ffd54f',
    'warning-500': '#ffc107',
    'warning-700': '#ffa000',
    'warning-900': '#ff6f00',

    'error-50': '#ffebee',
    'error-300': '#e57373',
    'error-500': '#f44336',
    'error-700': '#d32f2f',
    'error-900': '#b71c1c',

    'information-50': '#e1f5fe',
    'information-300': '#4fc3f7',
    'information-500': '#03a9f4',
    'information-700': '#0288d1',
    'information-900': '#01579b',

    'categorical-1': '#1976D2',
    'categorical-2': '#4DD0E1',
    'categorical-3': '#E57373',
    'categorical-4': '#FFD54F',
    'categorical-5': '#FFA000',
    'categorical-6': '#673AB7',
    'categorical-7': '#FF6F00',
    'categorical-8': '#01579B',
    'categorical-9': '#388E3C',
    'categorical-10': '#64B5F6',

    'sequential-1': '#E8F5E9',
    'sequential-2': '#B2DFDB',
    'sequential-3': '#B2DFDB',
    'sequential-4': '#4DB6AC',
    'sequential-5': '#26C6DA',
    'sequential-6': '#03A9F4',
    'sequential-7': '#1976D2',
    'sequential-8': '#3F51B5',
    'sequential-9': '#303F9F',
    'sequential-10': '#1A237E',

    'polarize-1': '#B71C1C',
    'polarize-2': '#FF6F00',
    'polarize-3': '#FFC107',
    'polarize-4': '#FFF8E1',
    'polarize-5': '#E8F5E9',
    'polarize-6': '#81C784',
    'polarize-7': '#64B5F6',
    'polarize-8': '#2196F3',
    'polarize-9': '#1976D2',
    'polarize-10': '#0D47A1',
};
