
import { formatDate } from '@/components/shared/utils';
import moment from 'moment';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NextReviewIcon',
    props: {
        nextReviewDate: { type: String, required: true },
    },
    computed: {
        isReviewDueToday(): boolean {
            const givenDate = moment(this.nextReviewDate);
            return moment().isSame(givenDate, 'day');
        },
        getTooltipText(): string {
            const givenDate = moment(this.nextReviewDate);
            return moment().isAfter(givenDate, 'day') ? 'Review Was Due' : 'Future Review Date';
        },
    },
    methods: {
        formatDate,
    },
});
