
import Vue from 'vue';

export default Vue.extend({
    name: 'SideBarButton',
    props: {
        id: String,
        name: String,
        tooltipText: String,
        icon: String,
        small: Boolean,
        expanded: Boolean,
        disabled: Boolean,
        selected: Boolean,
        margin: {
            type: String,
            default: 'mb-4',
        },
    },
});
