
import { formatDateTime, pluralize, withDefault } from '@/components/shared/utils';
import { MedicationAdministration, MedicationOrder } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'MedicationOrderDetailsButton',
    props: {
        medicationAdministrations: Array as PropType<Array<MedicationAdministration>>,
        medicationOrder: Object as PropType<MedicationOrder>,
    },
    data: () => ({
        showTable: false,
    }),
    computed: {
        manyAdministrations() {
            return this.medicationAdministrations.length >= 14;
        },
    },
    methods: {
        withDefault,
        formatDateTime,
        pluralize,
    },
});
