
import { SelectableItem } from '@/models';
import { defineComponent, PropType } from 'vue';
import { InputValidationRule } from 'vuetify';
import { optionalChain, withDefault } from './utils';
export default defineComponent({
    name: 'AdditiveSelector',
    props: {
        items: { type: Array as PropType<SelectableItem[]>, required: true },
        value: Object as PropType<any>,
        title: String,
        placeholder: { type: String, required: false, default: 'Add New' },
        rules: { type: Array as PropType<InputValidationRule[]>, required: false },
    },
    data: () => ({
        open: false,
        addingItem: '',
        scrollTargetSelector: '',
        addFieldHover: false,
        addFieldFocused: false,
        hover: false,
        validForm: undefined as undefined | boolean,
        useRules: undefined as undefined | InputValidationRule[],
    }),
    computed: {
        addFieldState() {
            if (this.addingItem !== '') return 'edit';
            if (this.addFieldFocused) return 'focus';
            return '';
        },
        addButtonDisabled() {
            return this.validForm !== true || !this.addingItem.length;
        },
        selectedIndex() {
            return this.items.findIndex((item) => item.value === this.value);
        },
    },
    watch: {
        items(newItems, oldItems) {
            if (newItems.length !== oldItems.length) {
                this.scrollTargetSelector = '.selectable-item-' + (newItems.length - 1);
            }
        },
        open(isOpen) {
            if (!isOpen) {
                this.resetState();
            } else {
                this.scrollToClass('.selectable-item-' + this.items.findIndex((item) => item.value === this.value));
            }
        },
    },
    updated() {
        if (this.scrollTargetSelector) {
            this.scrollToClass('.selectable-item-' + (this.items.length - 1));
            this.resetState();
        }
    },
    methods: {
        withDefault,
        optionalChain,
        scrollToClass(querySelector: string) {
            setTimeout(() => document.querySelector(querySelector)?.scrollIntoView({ behavior: 'smooth' }), 100);
        },
        select(index: number) {
            this.open = false;
            this.$emit('input', this.items.at(index)?.value);
        },
        add() {
            if (this.rules?.length && !this.rules.reduce((acc, curr) => acc && curr(this.addingItem) === true, true)) {
                this.useRules = this.rules;
            } else {
                this.$emit('add', this.addingItem);
            }
        },
        resetState() {
            this.addingItem = '';
            this.scrollTargetSelector = '';
            this.validForm = undefined;
        },
        userInputHandler() {
            this.useRules = undefined;
            this.validForm = true;
        },
    },
});
