
import { currencyValidator } from '@/components/shared/utils';
import { AppealMethodType, DenialOutcomeType } from '@/models/visit/denials/Denials.model';
import Vue from 'vue';

export default Vue.extend({
    name: 'ResolveDenialDialog',
    data: () => ({
        isDialogOpen: true,
        appealMethod: AppealMethodType,
        outcomeType: DenialOutcomeType,
        selectedAppealMethod: '',
        selectedOutcome: '',
        overturnAmount: undefined as number | undefined,
        rules: [currencyValidator],
    }),
    computed: {
        isAllFieldsFilled() {
            const currencyValidaton = currencyValidator(this.overturnAmount);
            return this.selectedOutcome && this.selectedAppealMethod && currencyValidaton && typeof currencyValidaton === 'boolean';
        },
    },
    methods: {
        currencyValidator,
        close(): void {
            this.isDialogOpen = false;
            this.$emit('close', false);
        },
        resolveDenial() {
            this.$emit('resolve', this.selectedAppealMethod, this.selectedOutcome, this.overturnAmount);
        },
    },
});
