import { InteractQuery } from '@/models/interact/interact.model';
import { InteractService } from '@/services/InteractService';
import { defineStore } from 'pinia';

export const useInteractServiceStore = () => {
    const interactStore = defineStore('interact', {
        state: () => ({
            isInitialized: undefined as boolean | undefined,
            interactService: undefined as InteractService | undefined,
            mostRecentQuery: undefined as InteractQuery | undefined,
        }),
        actions: {
            async initialize() {
                this.interactService = new InteractService(this.apolloClient);
                this.isInitialized = await this.interactService.initialize();
            },
            updateMostRecentQuery(newQuery: InteractQuery): void {
                this.mostRecentQuery = newQuery;
            },
        },
        getters: {
            mostRecentQueryStatus: (state) => state.mostRecentQuery?.status,
        },
    });
    const store = interactStore();
    if (store.isInitialized === undefined) {
        store.initialize();
    }
    return store;
};
