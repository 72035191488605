
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import Vue from 'vue';

export default Vue.extend({
    name: 'WorklistButton',
    components: {
        SideBarButton,
    },
    props: {
        expanded: Boolean,
        selected: Boolean,
    },
    methods: {
        displayWorklist() {
            this.$router.push({ name: 'worklist' });
        },
    },
});
