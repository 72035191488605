
import { withDefault } from '@/components/shared/utils/stringFormatters';
import Vue from 'vue';

export default Vue.extend({
    name: 'EditableTextField',
    props: {
        value: String,
    },
    data: () => ({
        showEditIcon: false,
        input: withDefault(''),
    }),
    watch: {
        async value(newValue: string) {
            this.input = newValue || '';
        },
    },
    mounted() {
        this.input = withDefault(this.value);
    },
    methods: {
        handleInput(value: string): void {
            this.$emit('updated', value);
        },
        editInput(): void {
            this.showEditIcon = true;
            if (this.input == withDefault('')) {
                this.input = '';
            }
        },
        async delayedBlur() {
            // OnBlur allows the save icon to disappear when the calendar saves the auth
            // But it also makes the save button ineffective due to a race
            // Defer the blur action for 500ms to allow save click to work
            // empirically 50ms didn't work. Seemed to need at least 200, so went for 500
            // to play safe as this is really a visual thing. It looks odd if you've moved off the
            // control and it is still looking active
            await new Promise((resolve) => setTimeout(resolve, 500));
            this.showEditIcon = false;
        },
        clearInput() {
            this.input = '';
            this.$emit('updated', '');
        },
        save() {
            this.$emit('saved', this.input);
            this.showEditIcon = !this.showEditIcon;
            if (!this.input) {
                this.input = withDefault('');
            }
        },
    },
});
