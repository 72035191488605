
import { Direction } from '@/models';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import Vue from 'vue';

export default Vue.extend({
    name: 'DataDrawer',
    props: {
        show: Boolean,
        dark: Boolean,
        hideClose: Boolean,
    },
    data: () => ({
        Direction,
        visitDrawerStore: useVisitDrawerStore(),
    }),
    computed: {
        darkMode(): boolean {
            return this.dark || !!this.visitDrawerStore?.drawerProps?.dark;
        },
        height(): number {
            return this.visitDrawerStore.drawerProps?.height ?? 90;
        },
        width(): number {
            return this.visitDrawerStore.drawerProps?.width ?? 60;
        },
        maxWidth(): boolean {
            return !!this.visitDrawerStore?.drawerProps?.maxWidth;
        },
        dataDrawerName(): string {
            if (this.visitDrawerStore.drawerProps?.enterFrom === Direction.LEFT) {
                return 'data-drawer-left';
            }
            return 'data-drawer-right'; //default behavior
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
});
