
import { AnalyticsTabConfig, AnalyticsTabs } from '@/models';
import { getAnalytics } from '@/shared/queries';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useUserStore } from '@/stores/UserStore';
import type { Analytics, AnalyticsTitleIdPair } from 'generated/graphql/graphql';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AnalyticsContainer',
    data: () => ({
        userStore: useUserStore(),
        featureStore: useFeatureStore(),
        selectedTabIndex: 0,
        AnalyticsTabs,
        analytics: AnalyticsTabs.reduce<Analytics>((acc, curr) => ({ ...acc, [curr.propertyName]: [] }), {} as Analytics),
        embedId: null as null | string,
        iFrameURL: '',
        loading: true,
    }),
    computed: {
        displayTabs(): AnalyticsTabConfig[] {
            return AnalyticsTabs.filter((tab) => this.passUserFunctionGuard(tab.userFunctionGuard));
        },
        selectItems(): AnalyticsTitleIdPair[] {
            const { propertyName } = this.displayTabs[this.selectedTabIndex];
            return this.analytics[propertyName];
        },
    },
    watch: {
        async embedId(id) {
            // if the user hasn't made a selection don't query
            if (id === null) return;

            this.iFrameURL = ''; // unload the previous src so that the load event can re-trigger
            const { query, queryName } = this.displayTabs[this.selectedTabIndex];
            const response = await this.$apollo.query({
                query,
                fetchPolicy: 'no-cache',
                variables: {
                    id,
                },
            });
            this.iFrameURL = response.data[queryName].embedUrl;
            this.loading = true;
        },
        selectedTabIndex() {
            this.initEmbedId();
        },
    },
    async created() {
        const response = await this.$apollo.query({
            query: getAnalytics,
            fetchPolicy: 'no-cache',
        });
        this.analytics = response.data.analyticsItems;
        this.initEmbedId();
    },
    methods: {
        initEmbedId() {
            this.loading = true;
            this.embedId = this.selectItems.at(0)?.id ?? '';
        },
        passUserFunctionGuard(userFunction?: keyof typeof this.userStore) {
            return !userFunction || this.userStore[userFunction];
        },
    },
});
