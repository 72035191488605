import { EventBus, Events } from '@/components/shared/event-bus';
import { SummaryTypeCode, VisitTextDomain } from '@iodine/tgs-api-client-node';
import { retryAsync } from 'ts-retry';
export interface InitData {
    endpoint: string;
    visitId: number;
    token: string;
    divisionId: string;
    previousReview?: {
        reviewer: string;
        reviewDate: string;
    };
    showWarningDialog?: boolean;
    showNavBar?: boolean;
    appName?: string;
    transferContent?: {
        enabled: boolean;
        label: string;
    };
    enableDeepLinks?: boolean;
    showExpandAllDailySummariesButton: boolean;
}

export interface VisitSummaryServiceFunctionOverride {
    transferContent: (content: string) => Promise<void>;
    userAgreed: (accepted: boolean) => void;
    deepLinkClicked: (id: string, type: string, family: string) => void;
}
export interface Features {
    showDailyTimelineSummaries?: boolean;
}

export enum DeepLinkTypes {
    DOCUMENT = 'document',
    CULTURE = 'culture',
    OBSERVATION = 'observation',
    MED_ADMIN = 'med-admin',
    MED_ORDER = 'med-order',
}

export class VisitSummaryApp {
    static readonly visitSummaryAppName = '@iodine/visit-summary';
    static readonly newSinceLastReviewAppName = '@iodine/visit-summary-next-review';
    static readonly visitSummaryAppContainerId = 'summaries';
    static readonly newSinceLastReviewAppContainerId = 'new-since-last-review';
    static readonly appId = 'single-spa-application:';
    static readonly singleSpaAppName = 'single-spa';
    static readonly transferContentLabel = 'Add to Clinical Summary';
    static singleSpa: any;
    static acceptedSharedDialog = false;
    static visitSummaryApp: any;
    static visitSummaryNextReviewApp: any;

    static async init(initData: InitData, functions: VisitSummaryServiceFunctionOverride, features?: Features): Promise<void> {
        this.singleSpa = await this.importApp(this.singleSpaAppName);
        await Promise.all(
            [this.newSinceLastReviewAppName, this.visitSummaryAppName].map(async (appName) => {
                //do not start up new since last review app if no review has been completed yet
                if (appName == this.newSinceLastReviewAppName && !initData.previousReview?.reviewDate) {
                    return;
                }

                let visitSummariesService;

                // check if visit summary app is already registered
                if (this.singleSpa.getAppStatus(appName) === null) {
                    const app = await this.importApp(appName);
                    visitSummariesService = app.visitSummaryService;
                    visitSummariesService.userAgreed = functions.userAgreed;
                    visitSummariesService.transferContent = (content: string) => functions.transferContent(content);
                    visitSummariesService.deepLinkClicked = functions.deepLinkClicked;
                    try {
                        this.singleSpa.registerApplication({
                            name: appName,
                            app,
                            activeWhen: ['/visit'],
                        });
                    } catch (err) {
                        console.error(`Could not register app ${appName}`, err);
                    }
                }
                await this.singleSpa.start();

                //start generating summaries asynchronously
                try {
                    await visitSummariesService.generateSummaries({
                        visitId: initData.visitId,
                        divisionId: initData.divisionId,
                        domain: VisitTextDomain.UM,
                        token: initData.token,
                        endpoint: initData.endpoint,
                        transferContent: initData.transferContent,
                        showWarningDialog: initData.showWarningDialog,
                        enableDeepLinks: initData.enableDeepLinks,
                        showExpandAllDailySummariesButton: initData.showExpandAllDailySummariesButton,
                        appName: appName,
                        ...this.addFields(appName, initData, features),
                    });
                } catch (e) {
                    console.error('Could not generate summaries for visit ' + initData.visitId);
                }
            })
        );
        // we need to pause before activating to let all the MFE app registration code finish executing before we push this on the event loop
        setTimeout(() => {
            this.hideApp(VisitSummaryApp.newSinceLastReviewAppName);
            EventBus.$emit(Events.GENAI_MFE_REGISTRATION_COMPLETE);
        });
    }

    static addFields(appName: string, initData: InitData, features?: Features): Record<string, unknown> {
        switch (appName) {
            case this.visitSummaryAppName:
                return {
                    tabs: [
                        SummaryTypeCode.CHART_SUMMARY,
                        ...(features?.showDailyTimelineSummaries ? [SummaryTypeCode.DAILY_CHART_SUMMARY] : []),
                    ],
                    defaultTab: SummaryTypeCode.CHART_SUMMARY,
                    showNavBar: initData.showNavBar,
                };
            case this.newSinceLastReviewAppName:
                return {
                    tabs: [SummaryTypeCode.NEW_SINCE_LAST_REVIEW],
                    defaultTab: SummaryTypeCode.NEW_SINCE_LAST_REVIEW,
                    showNavBar: false,
                    previousReview: {
                        reviewer: initData.previousReview?.reviewer,
                        reviewDate: initData.previousReview?.reviewDate,
                    },
                };
            default:
                return {};
        }
    }

    static async isAppRunning(importMapUrl: string): Promise<boolean> {
        if (!document.getElementById('import-map')) {
            await this.setUpImportMap(importMapUrl);
        }
        this.singleSpa = await this.importApp(this.singleSpaAppName);

        return (
            this.singleSpa.getAppStatus(this.visitSummaryAppName) !== null ||
            this.singleSpa.getAppStatus(this.newSinceLastReviewAppName) !== null
        );
    }

    static async setUpImportMap(importMapUrl: string): Promise<void> {
        importMapUrl += `?cache_bust=${Date.now()}`;
        const importMapEl = document.createElement('script');
        importMapEl.id = 'import-map';
        importMapEl.type = 'systemjs-importmap';
        importMapEl.src = importMapUrl;
        document.head.append(importMapEl);
    }

    static async resetApp(appName: string): Promise<void> {
        try {
            await this.singleSpa.unregisterApplication(appName);
        } catch (e) {
            console.error(`Could not reset app ${appName}`);
            console.error(e);
        }
    }

    static async getVisitSummaryService(appName: string) {
        if (appName === this.visitSummaryAppName) {
            return this.importAppService(appName, this.visitSummaryApp);
        } else if (appName === this.newSinceLastReviewAppName) {
            return this.importAppService(appName, this.visitSummaryNextReviewApp);
        }
    }

    static async importAppService(appName: string, app: any) {
        if (!app) {
            app = await this.importApp(appName);
        }
        return app.visitSummaryService;
    }

    static async setFilterDates(startDate: Date, endDate: Date) {
        const service = await this.getVisitSummaryService(VisitSummaryApp.visitSummaryAppName);
        service.setFilterDates(startDate, endDate);
    }
    static async hideApp(appName: string): Promise<void> {
        try {
            const appContainer = document.getElementById(this.appId + appName)!;
            const summariesWrapper: HTMLElement = document.getElementById('summaries-wrapper')!;
            if (appContainer) {
                summariesWrapper.appendChild(appContainer);
            }
            const service = await this.getVisitSummaryService(appName);
            await service.sharedDialogAccept(this.acceptedSharedDialog);
            await service.isActive(false);
        } catch (e) {
            console.error(`Could not hide app ${appName}`);
            console.error(e);
        }
    }

    static async mountApp(appName: string, wrapperId: string, appId: string): Promise<void> {
        try {
            let appContainer = document.getElementById(appId);
            if (!appContainer) {
                appContainer = document.createElement('div');
                appContainer.id = appId;
            }
            const wrapper: HTMLElement = document.getElementById(wrapperId)!;
            wrapper.appendChild(appContainer);
            const service = await this.getVisitSummaryService(appName);
            await service.sharedDialogAccept(this.acceptedSharedDialog);
            await service.isActive(true);
        } catch (err) {
            console.error(`Could not mount app ${appName}`, err);
        }
    }

    static importApp(appName: string): any {
        try {
            //@ts-ignore
            return retryAsync(() => System.import(appName), { delay: 100, maxTry: 10 });
        } catch (err) {
            console.error(`Could not import app ${appName}`, err);
        }
    }
}
