
import Loadable from '@/components/shared/Loadable.vue';
import VisitLengthOfTreatmentCondition from '@/components/visit/length-of-treatment/VisitLengthOfTreatmentCondition.vue';
import { VisitLengthOfTreatmentBar, VisitLengthOfTreatmentPredictionStatus } from '@/models';
import { BarElement, Chart, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import { VisitCondition, VisitConditionLengthOfTreatment } from 'generated/graphql/graphql';
import moment from 'moment';
import { defineComponent } from 'vue';
import { Bar as BarChart } from 'vue-chartjs/legacy';

Chart.register(BarElement, TimeScale, Tooltip);

enum BarColors {
    COMPLETE = 'rgba(76, 175, 80, 1)',
    ACTIVE = 'rgba(63, 81, 181, 1)',
    OVER_PREDICTION = 'rgba(255, 236, 179, 1)',
}

export default defineComponent({
    name: 'VisitLengthOfTreatmentChart',
    components: {
        BarChart,
        VisitLengthOfTreatmentCondition,
        Loadable,
    },
    props: {
        conditions: { type: Array<VisitCondition>, required: true },
        start: moment,
        end: moment,
    },
    data: () => ({
        chartOffset: 0,
        chartHeight: 0,
        baseChartOptions: {
            hover: { mode: null },
            maintainAspectRatio: false,
            indexAxis: 'y',
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                },
            },
            scales: {
                y: {
                    stacked: true,
                    ticks: {
                        display: false,
                    },
                    grid: {
                        drawTicks: false,
                    },
                },
                x: {
                    type: 'time',
                    position: 'top',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            day: 'MM/DD/YY',
                        },
                    },
                    ticks: {
                        display: true,
                        align: 'end',
                        maxRotation: 0,
                        minRotation: 0,
                        color: (ctx: any) => {
                            // colors the label for today's date in blue, all the others dark grey
                            const tickDate = moment(ctx.tick.value);
                            const today = moment();
                            if (today.isSame(tickDate, 'd')) {
                                return 'rgba(61, 90, 254, 1)';
                            }

                            return 'rgba(97, 110, 124, 1)';
                        },
                    },
                    grid: {
                        drawTicks: false,
                    },
                    afterTickToLabelConversion: (ctx: any) => {
                        ctx.ticks[0].value = null;
                        ctx.ticks[0].label = null;
                    },
                },
            },
        },
    }),
    computed: {
        chartStyle() {
            const dateCount = this.end?.startOf('day').diff(this.start?.startOf('day'), 'days') || 0;

            return {
                height: this.conditions.length * 100 + 'px',
                width: dateCount * 95 + 'px',
                'min-height': '125px',
            };
        },
        plugins() {
            return [
                {
                    // gives the grid that represents today on the graph a background color
                    id: 'colorToday',
                    beforeDatasetsDraw(chart: any) {
                        const {
                            ctx,
                            scales: { x },
                            chartArea: { top, height },
                        } = chart;
                        ctx.save();

                        x.ticks.forEach((tick: any) => {
                            const tickDate = moment(tick.value);
                            const today = moment();

                            if (today.isSame(tickDate, 'd')) {
                                const startPoint = x.getPixelForValue(new Date(tick.value).setDate(new Date(tick.value).getDate() - 1));
                                const endPoint = x.getPixelForValue(new Date(tick.value)) - startPoint;

                                ctx.fillStyle = 'rgba(245, 247, 250, 0.5)';
                                ctx.fillRect(startPoint, top, endPoint, height);
                            }
                        });
                    },
                },
            ];
        },
        chartData() {
            const labels = this.conditions.map((x) => x.name);

            const barData = {
                firstBar: {
                    data: [] as any[],
                    color: [] as any[],
                    radius: [] as any[],
                },
                secondBar: {
                    data: [] as any[],
                    color: [] as any[],
                    radius: [] as any[],
                },
            };

            const functionalMap = {
                underPrediction: {
                    complete: this.buildCompletedUnderPredictionBar,
                    active: this.buildActiveUnderPredictionBar,
                },
                overPrediction: {
                    complete: this.buildCompletedOverPredictionBar,
                    active: this.buildActiveOverPredictionBar,
                },
            };

            this.conditions.forEach((x, index) => {
                const start = moment(x.lengthOfTreatment?.startDate).subtract(1, 'day');
                let end = moment(x.lengthOfTreatment?.endDate);
                const lengthOfTreatment = x.lengthOfTreatment;

                if (!lengthOfTreatment) return this.buildBlankBar({ barData, start, end, index, lengthOfTreatment: null });

                const builderFunction =
                    functionalMap[this.underPrediction(lengthOfTreatment) ? 'underPrediction' : 'overPrediction'][
                        lengthOfTreatment.endDate ? 'complete' : 'active'
                    ];
                builderFunction({ barData, start, end, lengthOfTreatment, index });
            });

            return {
                labels,
                datasets: [
                    {
                        data: barData.firstBar.data,
                        borderRadius: barData.firstBar.radius,
                        borderSkipped: false,
                        barPercentage: 0.2,
                        backgroundColor: barData.firstBar.color,
                    },
                    {
                        data: barData.secondBar.data,
                        borderRadius: barData.secondBar.radius,
                        borderSkipped: false,
                        barPercentage: 0.2,
                        backgroundColor: barData.secondBar.color,
                    },
                ],
            };
        },
        chartOptions() {
            const chartOptions = { ...this.baseChartOptions };

            (chartOptions as any).animation = {
                onComplete: (ctx: any) => {
                    this.chartHeight = ctx.chart.chartArea.height;
                    this.chartOffset = ctx.chart.chartArea.top;
                },
            };

            (chartOptions as any).scales.x.min = this.start?.format('MM/DD/YY');
            (chartOptions as any).scales.x.max = this.end?.format('MM/DD/YY');

            return chartOptions;
        },
    },
    methods: {
        async openDetails(condition: VisitCondition): Promise<void> {
            this.$emit('openDetails', condition);
        },
        underPrediction: (conditionLot: VisitConditionLengthOfTreatment) => {
            // if we don't have values, default to true so we don't display that we are over a prediction
            // -1 for the max indicates we could not generate a prediction
            if (
                conditionLot.observedTreatmentLength == null ||
                conditionLot.expectedTreatmentMax === VisitLengthOfTreatmentPredictionStatus.NO_MAX ||
                conditionLot.expectedTreatmentMax == null
            ) {
                return true;
            }

            // handling the '< 24 hour' case, both the min and max will be 0 so we want to allow one day on the chart before showing its over predicted
            if (
                conditionLot.expectedTreatmentMax === 0 &&
                conditionLot.expectedTreatmentMin === 0 &&
                conditionLot.observedTreatmentLength === 1
            ) {
                return true;
            }

            return conditionLot.observedTreatmentLength <= conditionLot.expectedTreatmentMax;
        },
        buildBlankBar: (barArgs: VisitLengthOfTreatmentBar) => {
            const { barData, index } = barArgs;
            barData.firstBar.data.push([]);
            barData.firstBar.color.push(null);
            barData.firstBar.radius.push(null);

            barData.secondBar.data.push([]);
            barData.secondBar.color.push(null);
            barData.secondBar.radius.push(null);
        },
        buildCompletedUnderPredictionBar: (barArgs: VisitLengthOfTreatmentBar) => {
            const { barData, start, end, index } = barArgs;
            barData.firstBar.data.push([start.format('MM/DD/YY'), end.format('MM/DD/YY')]);
            barData.firstBar.color.push(BarColors.COMPLETE);
            barData.firstBar.radius.push(Number.MAX_VALUE);

            barData.secondBar.data.push([]);
            barData.secondBar.color.push(null);
            barData.secondBar.radius.push(null);
        },
        buildActiveUnderPredictionBar: (barArgs: VisitLengthOfTreatmentBar) => {
            const { barData, start, index } = barArgs;
            barData.firstBar.data.push([start.format('MM/DD/YY'), moment().format('MM/DD/YY')]);
            barData.firstBar.color.push(BarColors.ACTIVE);
            barData.firstBar.radius.push(Number.MAX_VALUE);

            barData.secondBar.data.push([]);
            barData.secondBar.color.push(null);
            barData.secondBar.radius.push(null);
        },
        buildCompletedOverPredictionBar: (barArgs: VisitLengthOfTreatmentBar) => {
            const { barData, start, end, index, lengthOfTreatment } = barArgs;

            if (lengthOfTreatment && lengthOfTreatment.observedTreatmentLength != null && lengthOfTreatment.expectedTreatmentMax != null) {
                let daysOverPrediction = lengthOfTreatment.observedTreatmentLength - lengthOfTreatment.expectedTreatmentMax;

                if (lengthOfTreatment.expectedTreatmentMax === 0 && lengthOfTreatment.expectedTreatmentMin === 0) {
                    daysOverPrediction--;
                }

                barData.firstBar.data.push([start.format('MM/DD/YY'), end.subtract(daysOverPrediction, 'days').format('MM/DD/YY')]);
                barData.firstBar.color.push(BarColors.COMPLETE);
                barData.firstBar.radius.push({
                    topLeft: Number.MAX_VALUE,
                    bottomLeft: Number.MAX_VALUE,
                });

                barData.secondBar.data.push([end.format('MM/DD/YY'), end.add(daysOverPrediction, 'days').format('MM/DD/YY')]);
                barData.secondBar.color.push(BarColors.OVER_PREDICTION);
                barData.secondBar.radius.push({
                    topRight: Number.MAX_VALUE,
                    bottomRight: Number.MAX_VALUE,
                });
            }
        },
        buildActiveOverPredictionBar: (barArgs: VisitLengthOfTreatmentBar) => {
            const { barData, start, index, lengthOfTreatment } = barArgs;
            if (lengthOfTreatment && lengthOfTreatment.observedTreatmentLength != null && lengthOfTreatment.expectedTreatmentMax != null) {
                let daysOverPrediction = lengthOfTreatment.observedTreatmentLength - lengthOfTreatment.expectedTreatmentMax;

                if (lengthOfTreatment.expectedTreatmentMax === 0 && lengthOfTreatment.expectedTreatmentMin === 0) {
                    daysOverPrediction--;
                }

                barData.firstBar.data.push([start.format('MM/DD/YY'), moment().subtract(daysOverPrediction, 'days').format('MM/DD/YY')]);
                barData.firstBar.color.push(BarColors.ACTIVE);
                barData.firstBar.radius.push({
                    topLeft: Number.MAX_VALUE,
                    bottomLeft: Number.MAX_VALUE,
                });

                barData.secondBar.data.push([
                    moment().subtract(daysOverPrediction, 'days').format('MM/DD/YY'),
                    moment().format('MM/DD/YY'),
                ]);
                barData.secondBar.color.push(BarColors.OVER_PREDICTION);
                barData.secondBar.radius.push({
                    topRight: Number.MAX_VALUE,
                    bottomRight: Number.MAX_VALUE,
                });
            }
        },
    },
});
