
import PDFBlobDisplay from '@/components/shared/PDFBlobDisplay.vue';
import { formatDate, formatPersonName, formatPhoneNumber, formatTime } from '@/components/shared/utils';
import ClinicalSummarySavedViewCard from '@/components/visit/clinical-summary/ClinicalSummarySavedViewCard.vue';
import ClinicalSummarySentViewCard from '@/components/visit/clinical-summary/ClinicalSummarySentViewCard.vue';
import { ClinicalDocumentStatusType, FaxRequestStatus } from '@/models';
import { ClinicalSummaryHistoryTab } from '@/models/clinical-document/ClinicalDocument.model';
import { acknowledgeFailedFax } from '@/shared/mutations';
import { getClinicalDocument } from '@/shared/queries';
import { useVisitStore } from '@/stores/VisitStore';
import { ClinicalDocument, FaxRequest, Mutation, Query } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'ClinicalSummaryHistoryView',
    components: {
        ClinicalSummarySentViewCard,
        ClinicalSummarySavedViewCard,
        PDFBlobDisplay,
    },
    data: () => ({
        clinicalSummary: {} as ClinicalDocument,
        blob: undefined as Blob | undefined,
        visitStore: useVisitStore(),
        disableRetryFaxButton: true as boolean,
        ClinicalDocumentStatusType,
    }),
    computed: {
        fax() {
            return this.clinicalSummary.fax ?? ({} as FaxRequest);
        },
    },
    async created() {
        await this.loadClinicalSummary();
        if (this.clinicalSummary.fax?.status.toString() === FaxRequestStatus.FAILURE) {
            this.disableRetryFaxButton = false;
            await this.acknowledgeFailedFax();
        }
    },
    methods: {
        formatDate,
        formatTime,
        formatPersonName,
        formatPhoneNumber,
        async loadClinicalSummary() {
            const response = await this.$apollo.query<Query>({
                query: getClinicalDocument,
                variables: {
                    filter: {
                        visitId: +this.$route.params.id,
                        clinicalDocumentId: +this.$route.params.clinicalSummaryId,
                    },
                },
                fetchPolicy: 'no-cache',
            });
            let pdfBytes;
            this.clinicalSummary = response.data.clinicalDocument;
            if (this.clinicalSummary.status.toString() === this.ClinicalDocumentStatusType.SAVED) {
                pdfBytes = response.data.clinicalDocument.pdf.data;
            } else {
                pdfBytes = response.data.clinicalDocument.fax?.pdf.data;
            }
            this.blob = new Blob([new Uint8Array(pdfBytes)], { type: 'application/pdf' });
        },
        goToClinicalSummaryList() {
            const tabIndex =
                this.clinicalSummary.status.toString() === this.ClinicalDocumentStatusType.SAVED
                    ? ClinicalSummaryHistoryTab.SAVED_TAB
                    : ClinicalSummaryHistoryTab.SENT_TAB;
            this.$router.push({ name: 'clinicalsummarylist', params: { tabIndex: tabIndex.toString() } });
        },
        async acknowledgeFailedFax() {
            await this.$apollo.mutate<Mutation>({
                mutation: acknowledgeFailedFax,
                variables: {
                    visitId: +this.$route.params.id,
                },
            });

            this.visitStore.$patch({
                visit: {
                    lastFaxRequestFailed: false,
                },
            });
        },
        updateDisableRetryFaxButton(newValue: boolean) {
            this.disableRetryFaxButton = newValue;
        },
    },
});
