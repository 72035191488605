import { render, staticRenderFns } from "./DocumentDrawerDetailView.vue?vue&type=template&id=a80328d8&scoped=true&"
import script from "./DocumentDrawerDetailView.vue?vue&type=script&lang=ts&"
export * from "./DocumentDrawerDetailView.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentDrawerDetailView.vue?vue&type=style&index=0&id=a80328d8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a80328d8",
  null
  
)

export default component.exports