
import { formatDateTime } from '@/components/shared/utils';
import VisitTimelineDetailView from '@/components/visit/timeline/VisitTimelineDetailView.vue';
import { ClinicalDocumentItemType, VisitTimelineEventDetails } from '@/models';
import { Maybe, MicrobiologyResult, Query } from 'generated/graphql/graphql';
import gql from 'graphql-tag';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitTimelineMicrobiologyDetailView',
    components: {
        VisitTimelineDetailView,
    },
    props: {
        selectedEvent: {
            type: Object as PropType<VisitTimelineEventDetails>,
            required: true,
        },
    },
    data: () => ({
        ClinicalSummaryItemType: ClinicalDocumentItemType,
        microbiologyResult: undefined as Maybe<MicrobiologyResult> | undefined,
        isLoading: false,
    }),
    computed: {
        header(): string {
            return this.selectedEvent.header;
        },
        subheader(): string {
            return `${this.microbiologyResult?.orderedBy ? `by ${this.microbiologyResult.orderedBy} ` : ''}at ${formatDateTime(
                this.selectedEvent.timestamp
            )}`;
        },
    },
    watch: {
        $props: {
            immediate: true,
            deep: true,
            handler() {
                this.getMicrobiologyResult(this.selectedEvent);
            },
        },
    },
    methods: {
        getMicrobiologyResult(event: VisitTimelineEventDetails): Promise<void> {
            this.isLoading = true;
            return this.$apollo
                .query<Query>({
                    query: gql`
                        query TimelineMicrobiologyResult($id: Int!) {
                            microbiologyResult(id: $id) {
                                name
                                date
                                placerOrderNumber
                                orderedBy
                                specimenSource
                                status
                                report
                            }
                        }
                    `,
                    variables: {
                        id: parseInt(event.id ?? ''),
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    this.microbiologyResult = response.data.microbiologyResult;
                    this.isLoading = false;
                });
        },
    },
});
