import { defineStore } from 'pinia';

type FilterObject = { [index: string]: string[] };
export const useVisitTimelineFiltersStore = defineStore('visitTimelineFilters', {
    state: () => ({
        filters: {} as { [index: string]: string[] },
        potentialFilters: {} as { [index: string]: string[] },
    }),
    actions: {
        resetFilters(): void {
            const emptyFilterObject = Object.keys(this.potentialFilters).reduce((acc, curr) => {
                acc[curr] = [];
                return acc;
            }, {} as FilterObject);
            this.filters = emptyFilterObject;
        },
    },
});
