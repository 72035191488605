
import { formatDate, pluralize, withDefault } from '@/components/shared/utils';
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import AddAvoidableDayCard from '@/components/visit/avoidable-days/AddAvoidableDayCard.vue';
import DeleteAvoidableDayCard from '@/components/visit/avoidable-days/DeleteAvoidableDayCard.vue';
import EditAvoidableDayCard from '@/components/visit/avoidable-days/EditAvoidableDayCard.vue';
import { AvoidableDayType, AvoidableDayView, getAvoidableDayTypeValueByKey } from '@/models';
import { getAvoidableDays } from '@/shared/queries';
import { useVisitStore } from '@/stores/VisitStore';
import { AvoidableDay, Query } from 'generated/graphql/graphql';
import Vue from 'vue';

export default Vue.extend({
    name: 'AvoidableDaysDialog',
    components: {
        SideBarButton,
        AddAvoidableDayCard,
        EditAvoidableDayCard,
        DeleteAvoidableDayCard,
    },
    props: {
        expanded: Boolean,
    },
    data: () => ({
        AvoidableDayType,
        AvoidableDayView,
        hover: false,
        isDialogOpen: false,
        currentView: AvoidableDayView.VIEW_ALL,
        avoidableDays: [] as AvoidableDay[],
        selectedAvoidableDay: undefined as AvoidableDay | undefined,
    }),
    computed: {
        noAvoidableDays() {
            return this.avoidableDays.length === 0;
        },
    },
    created() {
        this.getAvoidableDays();
    },
    methods: {
        formatDate,
        withDefault,
        pluralize,
        getAvoidableDayTypeValueByKey,
        close(): void {
            this.currentView = AvoidableDayView.VIEW_ALL; //reset
            this.isDialogOpen = false;
        },
        addDay(avoidableDay: AvoidableDay) {
            this.avoidableDays.unshift(avoidableDay);
        },
        async getAvoidableDays() {
            useVisitStore().onVisitLoad(async () => {
                const response = await this.$apollo.query<Query>({
                    query: getAvoidableDays,
                    variables: {
                        filter: {
                            visitId: +this.$route.params.id,
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                this.avoidableDays = response.data.avoidableDays;
            });
        },
        openEditAvoidableDay(avoidableDay: AvoidableDay) {
            this.selectedAvoidableDay = avoidableDay;
            this.currentView = AvoidableDayView.EDIT;
        },
        editDay(avoidableDay: AvoidableDay) {
            const index = this.avoidableDays.findIndex((day) => day.id === avoidableDay.id);
            this.$set(this.avoidableDays, index, avoidableDay);
        },
        openDeleteAvoidableDay(avoidableDay: AvoidableDay) {
            this.selectedAvoidableDay = avoidableDay;
            this.currentView = AvoidableDayView.DELETE;
        },
        deleteDay(avoidableDay: AvoidableDay) {
            const index = this.avoidableDays.findIndex((day) => day.id === avoidableDay.id);
            this.$delete(this.avoidableDays, index);
        },
    },
});
