
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Selection',
    props: {
        label: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        selected: Boolean,
    },
    data: () => ({
        hover: false,
    }),
});
