import { getDashboardEmbedUrl, getExploreEmbedUrl, getReportEmbedUrl } from '@/shared/queries';
import { Analytics } from 'generated/graphql/graphql';
import { DocumentNode } from 'graphql';
import { Store } from 'pinia';

type UserStoreFunction = keyof Store<'user', { canBuildAnalytics: boolean; canViewAnalytics: boolean }>;
export interface AnalyticsTabConfig {
    name: string;
    queryName: string;
    query: DocumentNode;
    propertyName: keyof Omit<Analytics, '__typename'>;
    userFunctionGuard?: UserStoreFunction;
}

export const AnalyticsTabs: AnalyticsTabConfig[] = [
    {
        name: 'Report Builder',
        query: getExploreEmbedUrl,
        queryName: 'getExploreEmbedUrl',
        propertyName: 'explores',
        userFunctionGuard: 'canBuildAnalytics',
    },
    { name: 'Dashboards', query: getDashboardEmbedUrl, queryName: 'getDashboardEmbedUrl', propertyName: 'dashboards' },
    { name: 'Reports', query: getReportEmbedUrl, queryName: 'getReportEmbedUrl', propertyName: 'reports' },
];
