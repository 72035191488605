enum VisitSummaryItemType {
    LAB_RESULT = 'lab_result',
    MEDICATION = 'medication',
    MEDICATION_ADMINISTRATION = 'medication_administration',
    MEDICATION_ORDER = 'medication_order',
    DOCUMENTED_MENTION = 'documented_mention',
    MEDICAL_CONCEPT = 'medical_concept',
    MICROBIOLOGY = 'microbiology',
    OBSERVATION_ORDER = 'observation_order',
}

export default VisitSummaryItemType;
