
import DatePicker from '@/components/shared/DatePicker.vue';
import { formatDate } from '@/components/shared/utils';
import { AvoidableDayType, getAvoidableDayTypeKeyByValue, getAvoidableDayTypeValueByKey } from '@/models';
import { editAvoidableDay } from '@/shared/mutations';
import { AvoidableDay, Maybe, Mutation } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'EditAvoidableDayCard',
    components: {
        DatePicker,
    },
    props: {
        avoidableDay: {
            type: Object as PropType<AvoidableDay>,
            required: true,
        },
    },
    data: () => ({
        AvoidableDayType,
        category: undefined as string | undefined,
        avoidableDate: undefined as string | undefined,
        reason: undefined as Maybe<string> | undefined,
    }),
    computed: {
        isCompleted() {
            return (
                getAvoidableDayTypeKeyByValue(this.category) !== this.avoidableDay.category ||
                this.avoidableDate !== this.avoidableDay.avoidableDate ||
                this.reason !== this.avoidableDay.reason
            );
        },
    },
    created() {
        this.avoidableDate = this.avoidableDay.avoidableDate;
        this.category = getAvoidableDayTypeValueByKey(this.avoidableDay.category);
        this.reason = this.avoidableDay.reason;
    },
    methods: {
        formatDate,
        close() {
            this.$emit('close');
        },
        goBack() {
            this.$emit('goBack');
        },
        async saveAvoidableDay() {
            const response = await this.$apollo.mutate<Mutation>({
                mutation: editAvoidableDay,
                variables: {
                    input: {
                        id: +this.avoidableDay.id,
                        visitId: +this.$route.params.id,
                        category: getAvoidableDayTypeKeyByValue(this.category ?? ''),
                        reason: this.reason,
                        avoidableDate: this.avoidableDate,
                    },
                },
            });
            this.$emit('editedDay', response.data?.editAvoidableDay);
            this.goBack();
        },
    },
});
