
import SideBarButton from '@/components/sidebar/SideBarButton.vue';
import { Direction, DrawerType } from '@/models';
import { useVisitDrawerStore } from '@/stores/VisitDrawerStore';
import Vue from 'vue';

export default Vue.extend({
    name: 'GenAiButton',
    components: {
        SideBarButton,
    },
    props: {
        expanded: Boolean,
        disabled: Boolean,
    },
    data: () => ({
        visitDrawerStore: useVisitDrawerStore(),
    }),
    methods: {
        openDrawer() {
            this.visitDrawerStore.openDrawer({
                drawer: DrawerType.GEN_AI_SUMMARY,
                drawerProps: { enterFrom: Direction.LEFT, height: 100, width: 60 },
            });
        },
    },
});
