var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-list-item-content',[_c('v-menu',{attrs:{"open-on-click":"","close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({attrs:{"data-testid":"text-field-button"},on:{"click":function($event){_vm.open = !_vm.open}}},'div',attrs,false),on),[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","dense":"","readonly":"","label":_vm.title,"value":_vm.withDefault(_vm.optionalChain(_vm.items.at(_vm.selectedIndex), 'text')),"role":"button"},on:{"click":function($event){$event.preventDefault();}}},[_c('v-btn',{staticClass:"mt-n1",attrs:{"slot":"append","data-testid":"expansion-indicator","icon":""},slot:"append"},[_c('font-awesome-icon',{staticClass:"fa-lg neutral-500--text",attrs:{"icon":'fa-solid fa-caret-' + (_vm.open ? 'up' : 'down')}})],1)],1)],1)]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-layout',{staticClass:"d-flex flex-column overflow-y-hidden"},[_c('v-list',{staticClass:"py-0 scrollable h-200"},_vm._l((_vm.items),function(item,index){return _c('v-layout',{key:index,class:'selectable-item-' + index,on:{"click":function($event){return _vm.select(index)}}},[_c('v-hover',{attrs:{"value":_vm.hover},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{
                                'neutral-50': hover,
                                'interactive-a400--text': index === _vm.selectedIndex,
                                'interactive-50': index === _vm.selectedIndex,
                            }},[_vm._v(" "+_vm._s(item.text)+" ")])]}}],null,true)})],1)}),1),_c('v-form',{model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-text-field',{staticClass:"add-new-field",class:`add-new-field--${hover ? 'hover' : _vm.addFieldState}ing`,attrs:{"dense":"","solo":"","hide-details":"","placeholder":_vm.addFieldFocused ? '' : _vm.placeholder,"rules":_vm.useRules},on:{"focus":function($event){_vm.addFieldFocused = true},"blur":function($event){_vm.addFieldFocused = false},"input":_vm.userInputHandler},model:{value:(_vm.addingItem),callback:function ($$v) {_vm.addingItem=$$v},expression:"addingItem"}},[_c('v-btn',{staticClass:"mr-n2",attrs:{"slot":"append","icon":"","data-testid":"add-new-button","disabled":_vm.addButtonDisabled},on:{"click":_vm.add},slot:"append"},[(_vm.validForm === false)?_c('font-awesome-icon',{staticClass:"fa-lg error-700--text",attrs:{"data-testid":"warning-icon","icon":"fa-solid fa-circle-exclamation"}}):_c('font-awesome-icon',{staticClass:"fa-lg",class:{
                                    'neutral-300--text': _vm.addFieldState === 'focus',
                                    'interactive-a400--text': _vm.addFieldState === 'edit',
                                    'neutral-400--text': _vm.addFieldState !== 'focus' && _vm.addFieldState !== 'edit',
                                    'neutral-500--text': hover && _vm.addFieldState === '',
                                },attrs:{"data-testid":"add-icon","icon":"fa-solid fa-circle-plus"}})],1)],1)]}}]),model:{value:(_vm.addFieldHover),callback:function ($$v) {_vm.addFieldHover=$$v},expression:"addFieldHover"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }