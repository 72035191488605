
import { Role } from '@/auth/role.enum';
import { formatPersonName, withDefault } from '@/components/shared/utils';
import { assignVisitOwner } from '@/shared/mutations';
import { getUsersByRole } from '@/shared/queries';
import { Mutation, Query, User, Visit } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'VisitOwnerSelector',
    props: {
        visit: Object as PropType<Visit>,
        roleType: Object as PropType<Role>,
        worklist: Boolean,
        value: Number,
        isEscalateDialog: Boolean,
    },
    data: () => ({
        ownerItems: undefined as User[] | undefined,
        placeholderText: '',
    }),
    computed: {
        selectedOwnerId: {
            get(): unknown {
                return this.value;
            },
            set(value: unknown) {
                this.$emit('input', value);
            },
        },
        label(): string {
            return this.isEscalateDialog ? 'Physician Advisor' : '';
        },
    },
    mounted() {
        this.loadOwners();
        this.placeholderText = this.isEscalateDialog ? 'Physician Advisor' : withDefault('');
    },
    methods: {
        formatPersonName,
        withDefault,
        clearPlaceholderText(): void {
            this.placeholderText = '';
        },
        async loadOwners() {
            const response = await this.$apollo.query<Query>({
                query: getUsersByRole,
                variables: {
                    role: this.roleType.key,
                },
            });
            this.ownerItems = response.data.usersByRole;
            this.ownerItems?.unshift({ id: '0', firstName: '', lastName: '', username: '' });
        },
        async assignVisitOwner(userId: number) {
            await this.$apollo.mutate<Mutation>({
                mutation: assignVisitOwner,
                variables: {
                    userId: userId === 0 ? null : userId,
                    visitId: this.visit?.id ? parseInt(this.visit.id) : parseInt(this.$route.params.id),
                    role: this.roleType.key,
                },
            });
            if (this.isEscalateDialog === false) {
                this.$toast.success(
                    `The Case Owner has been changed${this.worklist ? ' for ' + formatPersonName(this.visit.patient) : ''}.`
                );
            }
            this.selectedOwnerId = userId;
        },
    },
});
