
import { formatDate, formatDateWithFullYear, formatISODate } from '@/components/shared/utils';
import { PreselectedDate } from '@/models';
import moment from 'moment';
import Vue, { PropType } from 'vue';
const fullYearDateFormat = 'YYYY-MM-DD';

export default Vue.extend({
    name: 'MultiDatePicker',
    props: {
        label: String,
        rangeStartDate: {
            type: String,
            required: true,
        },
        displayButtonBar: { type: Boolean, required: false, default: true },
        preSelectedDates: { type: Object as PropType<PreselectedDate>, required: true },
        allowAllDates: { type: Boolean, required: false, default: false },
        hidePreselectOptions: { type: Boolean, required: false, default: false },
        disableDatepicker: { type: Boolean, required: false, default: false },
        clearDates: { type: Boolean, required: false, default: false },
    },
    data: () => ({
        menu: false,
        startDate: '' as string,
        endDate: '' as string,
        disableResetButton: true,
        disableApplyButton: true,
    }),
    computed: {
        dates: {
            set(dates: string[]) {
                this.startDate = dates[0] ?? undefined;
                this.endDate = dates[1] ?? undefined;
            },
            get(): (string | undefined)[] {
                const formattedStartDate = formatDateWithFullYear(this.startDate, fullYearDateFormat);
                const formattedEndDate = formatDateWithFullYear(this.endDate, fullYearDateFormat);
                return [formattedStartDate, formattedEndDate].filter(Boolean);
            },
        },
        todaysDate(): string {
            return moment().format('LL').toString();
        },
        dateRange(): string {
            if (this.startDate && this.endDate) {
                return (formatDate(this.startDate) ?? '') + ' - ' + (formatDate(this.endDate) ?? '');
            }
            return '--';
        },
    },
    watch: {
        preSelectedDates(dates: PreselectedDate) {
            if (dates.startDate) {
                this.enableButtons();
                this.startDate = formatISODate(dates.startDate) ?? '';
                this.endDate = formatISODate(dates.endDate) ?? '';
            }
        },
        clearDates(flag: boolean) {
            if (flag) {
                this.resetDatePicker();
            }
        },
    },
    mounted() {
        this.startDate = formatISODate(this.preSelectedDates.startDate) ?? '';
        this.endDate = formatISODate(this.preSelectedDates.endDate) ?? '';
        this.disableResetButton = !(this.startDate && this.endDate);
    },
    methods: {
        formatDate,
        formatISODate,
        datesSelected() {
            // keep the model up to date
            this.$emit('selected', formatISODate(this.startDate), formatISODate(this.endDate));
            this.enableButtons();
            if (!this.displayButtonBar) {
                this.$emit('changed', formatISODate(this.startDate), formatISODate(this.endDate));
            }
        },
        enableButtons() {
            this.disableResetButton = false;
            this.disableApplyButton = false;
        },
        allowedDates(val: string): boolean {
            if (this.allowAllDates) {
                return true;
            }
            return (
                moment(val, 'YYYY-MM-DD').isSameOrAfter(moment(this.rangeStartDate, 'YYYY-MM-DD')) &&
                moment(val, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'))
            );
        },
        applyDates() {
            if (moment(this.startDate).valueOf() - moment(this.endDate).valueOf() > 0) {
                let tempDate = this.endDate;
                this.startDate = this.endDate;
                this.endDate = tempDate;
            }

            //@ts-ignore
            this.$refs.menu.save(this.dates);
            this.$emit('changed', formatISODate(this.startDate), formatISODate(this.endDate));
            this.disableApplyButton = true;
        },
        resetDatePickerAndModel() {
            // Only triggered by explicit Reset so trigger the db update
            this.resetDatePicker();
            this.$emit('changed', undefined, undefined);
        },
        resetDatePicker() {
            // Can be triggered by clearing the auth number field - just reset this component
            this.disableResetButton = true;
            this.disableApplyButton = true;
            this.startDate = this.endDate = '';
        },
    },
});
