
import { formatDate, formatTime } from '@/components/shared/utils';
import Vue from 'vue';

export default Vue.extend({
    name: 'DischargeIcon',
    props: {
        dischargeDate: {
            type: String,
            required: true,
        },
    },
    methods: {
        formatDate,
        formatTime,
    },
});
