
import { formatDate, pluralize } from '@/components/shared/utils';
import { Visit } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ReadmissionCard',
    props: {
        visits: {
            type: Array as PropType<Visit[]>,
            required: true,
        },
        close: Function,
        back: Function,
        height: Number,
    },
    methods: {
        formatDate,
        pluralize,
    },
});
